import * as React from 'react';
import { Panel, } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';

import {IPdmsUser} from './IPdmsUser';
import { addMonths, addYears, CheckboxBase, IDatePickerStyles, IStackProps, Label, Overlay, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { Checkbox} from 'office-ui-fabric-react/lib/Checkbox';
import axios from 'axios';

import AttachmentTQForTCR from './AttachmentTQForTCR';

const buttonStyles = { root: { marginRight: 8 } };
const stackTokens = { childrenGap: 50 };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } },
};
export interface ICheckboxInput{
  Id:number,
  Title?:string,
  Checked:boolean,
}
var options: ICheckboxInput[] = []
//const datePickerStyles: Partial<IDatePickerStyles> = { root: { maxWidth: 300, marginTop: 15 } };
export interface ICustomTCRPanelProps {
    //context?: any | null;
    isOpen: boolean;
    siteUrl:string;
    projectNo:string;
    onDissmiss:any;
    currentProjectUser:IPdmsUser;    
    context?: any | null;
    token?:string;
    handleAdd?:any;
    commentMode:string;
    dcCompany:string;
    dcEmails:string;
    refTcrId?:number;
  }

  export interface ICustomTCRPanelState {
    isAddingMode:boolean;
    targetListRefresh:boolean;
    added:boolean;
    //currentDate:string;
    //traID:string;
    isBusy?:boolean;
    checkboxes: CheckboxBase[];
    isChecked: boolean;
    TCRNumber?: string
    startDate:any;
    classifications?: ITCRClassification[];
    classificationSelectedVal: number[];
    editMode:boolean;
    refTcrId?:number;
    editDate:any;
    editSubject:string;
    editTcrNo:string;
    editTqId:number;
  }
  export interface ITCRClassification
  {  
    Id?: number; 
    Title?: string;    
  }

export default class TCRPanelEdit extends React.Component<ICustomTCRPanelProps, ICustomTCRPanelState> {
  private _options : CheckboxBase[] = [];
  
    constructor(props: ICustomTCRPanelProps | Readonly<ICustomTCRPanelProps>) {  
        super(props);  
        this.state={
            isAddingMode : false,
            targetListRefresh:false,
            added:false,
            checkboxes: this._options,
            isChecked: false,            
            startDate: new Date(),
            classificationSelectedVal: [],
            editMode:false,
            editDate:'',
            editSubject:'',
            editTcrNo:'',
            editTqId:0,
        };
        //tcrAddHandler
        this.tcrAddHandler = this.tcrAddHandler.bind(this);   
      } 
      handleChange(date: any) {
        this.setState({
          startDate: date
        })
      }
      private tcrAddHandler():void{
        this.setState({
          targetListRefresh:true,
          added:true,
          classificationSelectedVal:[],      
        });
      }
      componentDidMount() 
      {
        if ( this.props.refTcrId !== undefined && this.props.refTcrId !== null && this.props.refTcrId !== 0)
        {
          this.showTCRInfo();
        }
        else
        {
          console.log(this.state.isAddingMode);
          if (this.props.currentProjectUser !== undefined)
          {
            this._tcrClassification();
          }
        }
        
      }
      componentDidUpdate(previousProps:ICustomTCRPanelProps, previousState:ICustomTCRPanelState){
        if (this.props.currentProjectUser !== undefined && (previousProps.projectNo !== this.props.projectNo || previousProps.currentProjectUser !== this.props.currentProjectUser)) {

        }
        if (previousState.TCRNumber !== undefined && ( this.state.TCRNumber !== previousState.TCRNumber || this.state.added !== previousState.added))
        {
          //alert(112);
        }
        if (this.props.refTcrId !== previousProps.refTcrId && this.props.refTcrId !== 0)
        {
          //alert(`this.props.refTcrId = ${this.props.refTcrId} and previousProps.refTcrId = ${previousProps.refTcrId}`)
          this.showTCRInfo();
        }
      }

  public render() {    
    //let {checkboxes} =  this.state;
    //let userName = this.props.currentProjectUser ? this.props.currentProjectUser.name : "";    
    return (
      <div>
        <Panel
          isOpen={this.props.isOpen}
          onDismiss={this.props.onDissmiss}
          headerText="TCR"
          closeButtonAriaLabel="Close">
          <Stack horizontal tokens={stackTokens} >
            <Stack {...columnProps}>
            <Label>Edit TCR</Label>
            <TextField id="txtTcrNoEdit" className="clsText" value={this.state.editTcrNo} label="TCR No.: " required></TextField>
            <TextField
              id="txtDateEdit"
              contentEditable="true"
              onChange={this.setTcrDate} 
              label="Request Return Date :"
              type="date"
              value={this.state.editDate}
              placeholder="Select a date..."
            />
            <TextField contentEditable="true" id="txtSubEdit" className="clsText" value={this.state.editSubject} onChange={this.setTcrSubject} label="Subject : " required></TextField>            
            <Label id="txtClassification">Classification :  
            </Label>
            {             
              options.map((checkBoxItem: ICheckboxInput) => {
                return (
                //<div>
                  <Checkbox id={(checkBoxItem.Id).toString()} checked={checkBoxItem.Checked} label={checkBoxItem.Title} value={checkBoxItem.Id} onChange={e => this._onChange(e)} ></Checkbox>
                )
              })           
            }
            <AttachmentTQForTCR siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} tcrId={this.props.refTcrId ? this.props.refTcrId!.toString():''} tqId={this.state.editTqId.toString()} ></AttachmentTQForTCR>
            <Label>Select Files :</Label>
            <form encType="multipart/form-data" id="myForm">
            <input type="file" name="myFile" id="txtfileEdit" required></input>
        </form>
            <div>
            <PrimaryButton onClick={()=>this._updateTCR()} id="btnUpdate" styles={buttonStyles}>Update</PrimaryButton>
            { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
            </div>
            </Stack>      
          </Stack>           
        </Panel>
      </div>
    );
  }
  
  setTcrSubject = (event:any) =>
  {
    this.setState({
      editSubject: event.target.value,
    });
  }

  setTcrDate = (event:any) =>
  {
    this.setState({
      editDate: event.target.value,
    });
  }

  private _onChange(e:any){  
    console.log(`The option is changed.`);  
    for(var i =0; i<options.length; i++)
    {
      if (parseInt(e.target.id.toString()) === options[i].Id)
      {
        options[i].Checked = e.target.checked;
      }
    }
    let cla_list:number[] = [];
    cla_list = this.state.classificationSelectedVal;
    let isChecked = e.target.checked;
    var isCheckedId = e.target.id;
    if(isChecked === true){
      cla_list.push(isCheckedId);      
      this.setState({
        classificationSelectedVal: cla_list,
      })      
    }else{
      var index = cla_list.indexOf(isCheckedId);
      if (index >= -1) {
        cla_list.splice(index, 1);
        this.setState({
          classificationSelectedVal: cla_list
        })
      }      
    }
    //alert(isChecked);
  } 


  private async _updateTCR(){
    
    let request = {
      projectNo: this.props.projectNo,
      tcrId:this.props.refTcrId!,
      subject:this.state.editSubject,
      requestReturnDate: this.state.editDate,
      classifications: this.state.classificationSelectedVal,
    };
    //debugger;
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
      axios.post(`/api/v1/tcr/updateById`,request).then((d) => {
        console.log(d);
        //let tcrId = d.data.body;
        
        if ((document.querySelector("#txtfileEdit") as HTMLInputElement).files!.length > 0)
        {
          let myfile = (document.querySelector("#txtfileEdit") as HTMLInputElement).files![0];
          let fname = myfile.name.substring(0, myfile.name.lastIndexOf('.'));
          let fileType = myfile.name.replace(fname,"");
          let newFIleName = `${this.state.editTcrNo}${fileType}`; 
          //let newFIleName = `${fname}(comment-${tcrId})${fileType}`; 
          let myForm = document.getElementById('myForm') as HTMLFormElement;
          let formData = new FormData(myForm);

          axios.post(`/api/v1/fileUpload?filename=${encodeURIComponent(newFIleName)}&projectno=${this.props.projectNo}&libraryname=TQ`,formData).then((f) => {
            console.log(`f=${f}`);
            let DocId = f.data.fileId;
            //update the metadata for attachment
            let oRequest = {
              //commentId : commentId,
              tcrId: this.props.refTcrId,
              attachmentId : DocId,
              projectNo : this.props.projectNo,
            };
            axios.post(`/api/v1/tcr/updateTCRDoc`,oRequest).then(() => {          
              this.setState({
                isBusy:false,
              });
            });
          });
        }
        else
        {
          this.setState({
            isBusy:false,
          })
        }               
      }).catch((ex) =>{
        alert(JSON.stringify(ex));
      })
      

    //Update Docs and Running Number
    this.setState({
      isAddingMode:true,
    });   
  }

private async showTCRInfo()
{
  this.setState({
    isBusy:true,
  })
  axios.get(`/api/v1/tcr/getTcrById?userId=${this.props.currentProjectUser.key}&projectNo=${this.props.projectNo}&isIncoming=&tcrId=${this.props.refTcrId!}`).then((d) => {
    console.log(d);
    let selValues : number[] = [];
    for (var s = 0 ; s < d.data.body.Classification.length; s++)
    {
      selValues.push(d.data.body.Classification[s].Id);
      
    }
    this.setState({
      editTqId:d.data.body.DocId ? d.data.body.DocId.Id : 0,
      classificationSelectedVal:selValues,
    })
    axios.get(`/api/v1/tcr/getTcrClassification?projectNo=${this.props.projectNo}`).then((clsdata)=>{
      //clsdata
      options = [];
      let classificationData = clsdata.data.body;
      for (var i = 0; i < classificationData.length; i ++)
      {
        let tempChecked = false;
        for (var k = 0 ; k < selValues.length; k++)
        {
          if (parseInt(selValues[k].toString()) === classificationData[i].Id)
          {
            tempChecked = true;
          }
        }
          options.push({
            Id: classificationData[i].Id,
            Title: classificationData[i].Title,
            Checked:tempChecked
          });
      }
      //debugger;
    this.setState({
      isBusy:false,
      editDate: new Date(d.data.body.RequestReturnDate).toISOString().split('T')[0],
      editSubject: d.data.body.Subject,
      editTcrNo:d.data.body.Title,
    })
  });
});
}

  private triggerItemTcr() {
    //find the comment counting item hidden button and click
    //let element: HTMLElement = document.getElementById(`btnCC`) as HTMLElement;
    //element.click();
    this.props.onDissmiss();
  }
  
  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked // flip boolean value
    }, function() {
    }.bind(this));
  }

  private async _tcrClassification () {
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
    let clas : ITCRClassification = {
    };
    await axios.get(`/api/v1/tcr/getTcrClassification?projectNo=${this.props.projectNo}`).then((clsdata)=>{
      //clsdata
      options = [];
      let classificationData = clsdata.data.body;
      let _classifications = classificationData.map((xx:{Title: string, Id:number})=>{
        options.push({
          Id: xx.Id,
          Title:xx.Title,
          Checked:false
        });
    });
    this.setState({ 
      classifications:_classifications,
      isBusy:false,
    });
  });
    return clas;
  }  
}