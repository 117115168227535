import {ISendEmailProps, ISendEmailState} from "./ISendEmailProps"
import * as React from 'react'
//import { sp } from "@pnp/sp/presets/all";
//import styles from './CrudReact.module.scss';
import {MessageBar, Overlay, Spinner, SpinnerSize,} from 'office-ui-fabric-react'; 
//import { PrimaryButton, IButtonProps ,TextField,CompoundButton} from 'office-ui-fabric-react'; 
//import pnp from "sp-pnp-js";
//import { Web } from "@pnp/sp/webs";  
import axios from 'axios';






var dcEmails: any[] = [];

export default class SendEmail extends React.Component<ISendEmailProps, ISendEmailState, {}> {  
  
    constructor(props: ISendEmailProps, state: ISendEmailState) {  
      super(props);  
    //   sp.setup({
    //     spfxContext: this.props.context
    //   });
      //Set initial value for state  
      this.state = ({  
        emailId: "",  
        statusMessage: {  
          isShowMessage: false,  
          message: "",  
          messageType: 90000,
          isBigSizeDetected:props.isBigSizeDetected,  
        },  
        usersDC:[],
        callMethod:false,
        uploadedFileName:"",
        isBusy:false,
      });  
    
      //Set UP for PNP Operation  
      // sp.setup({  
      //   spfxContext : this.props.context 
      // }); 
    //   pnp.sp.utility.getCurrentUserEmailAddresses().then((addressString: string) => {
    //     console.log(addressString);
    // });      
    } 
    
    componentDidMount() {

      axios.get(`/api/v1/projectUser/getByRole?projectNo=${this.props.projectNo}&role=DC`).then((response) => {
          let _allItems: { key: any; name: any; value: any; email: any; }[] = [];
          let _temailId ="";
          dcEmails = [];
          response.data.body.forEach((item: { Email: string; }) =>{
            _temailId += item.Email + ";"
        });
        for (var j = 0; j < response.data.body.length; j++){dcEmails.push(response.data.body[j].Email);}
        response.data.body.forEach((item: { ID: { toString: () => any; }; Title: any; Email: any; }) =>{
              _allItems.push({
              key: item.ID.toString(),
              name: item.Title,
              value: item.ID,
              email:item.Email,
            });
          });
          this.setState({usersDC:_allItems});
          console.log(dcEmails);
          this.setState({
            emailId:_temailId
          });
          console.log(this.state.emailId);
        }
      ) 
  
  
  }

  
    
    public render(): React.ReactElement<ISendEmailProps> {  
      return (  
        // <div className={styles.sendEmail}>  
        <div>  
          { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null } 
          {/* Show Message bar for Notification*/}  
          {this.state.statusMessage.isShowMessage ?  
            <MessageBar  
              messageBarType={this.state.statusMessage.messageType}  
              isMultiline={false}  
              dismissButtonAriaLabel="Close"  
            >{this.state.statusMessage.message}</MessageBar>  
            : ''}          
    
            {/* Text field for entering Email id*/}  
          {/* <TextField required label="Enter Email Id" value={this.state.emailId} onChange={(e) => this.OnChangeTextBox(e)} /><br />   */}
    
          {/* Button for Send Email using PNP Js*/}  
          {/* <CompoundButton onClick={() => this.SendAnEmilUsingPnpJs()} primary secondaryText="Send an Email using Pnp js" >  
            Pnp js  
        </CompoundButton>         */}
    
        </div>  
      );  
    }
    
    public componentDidUpdate(previousProps: ISendEmailProps, previousState: ISendEmailState) {

  
          if (previousProps.projectNo !== this.props.projectNo) {
            //alert(previousProps.projectNo);
            this.componentDidMount();
          }
          if (previousProps.callMethod === false && this.props.callMethod === true)
          {
            //alert("call email sending here");
            this.SendEmailToDC().then(()=>{
              this.props.emailHandle();
            });
          }
  
    }

    // private OnChangeTextBox(e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void {  
    //     this.setState({  
    //       emailId: e.target.value  
    //     });  
    //   } 

    //Send Mail using PNP Js  
  // private async SendAnEmilUsingPnpJs()  
  // {  
  
  //   //var  recipients = ["baojiang.wang@sg.yokogawa.com","edms@sg.yokogawa.com"];
  //   var recipients = dcEmails;

  //   if (recipients.length > 0)
  //   {
  //       // pnp.sp.utility.sendEmail(emailProps).then(_ => {

  //       //     console.log("Email Sent!");
  //       // });
  
  //     //Send Email using SP Utility  
  //     pnp.sp.utility.sendEmail({  
  //       //Body of Email  
  //       Body: "[<b>" + this.props.projectNo + "</b>] file uploaded",  
  //       //Subject of Email  
  //       Subject: "File uploaded for : " + this.props.uploadedFileName + " in ProjectNo: " + this.props.projectNo,  
  //       //Array of string for To of Email  
  //       //To: [this.state.emailId],  
  //       To: recipients, 
  //     }).then((i) => {  
  
  //       //Set Success Message Bar after sending Email  
  //       this.setState({  
  //         statusMessage: { isShowMessage: true, message: "Email Sent using PNP Js", messageType: 4 },
  //         callMethod:false,
  //         uploadedFileName:"" 
  //       });  
  //     }).catch((i) => {  
  //       //Set Error Message Bar for any error  
  //       this.setState({  
  //         statusMessage: { isShowMessage: true, message: "Error:" + i.message , messageType: 1 }  
  //       });  
  //     }); 
  //   }
  //   // //Check if TextField value is empty or not  
  //   // if (this.state.emailId) {  

 
  //   // }  
  //   else {  
  //     this.setState({  
  //       statusMessage: { isShowMessage: true, message: "No members in role DC", messageType: 1 }  
  //     });  
  //   }  
  // }

  private async SendEmailToDC()
  {
    console.log('...start send email');
        //var  recipients = ["baojiang.wang@sg.yokogawa.com","edms@sg.yokogawa.com"];
        this.setState({  
          isBusy:true
        }); 
        var recipients = dcEmails;
        if (recipients.length > 0)
        {
          let appendBigSize = this.props.isBigSizeDetected ? "(big size file uploading detected, the file will be delayed of displaying)" : ""
          //Send Email using SP Utility 
          let request = {
            html: `<p>[<b>${this.props.projectNo}</b>] file uploaded ${appendBigSize} by <b> ${this.props.userInfo.name} </b></p><p><a  href='${this.props.siteUrl}/${this.props.projectNo}/Pages/Non-Project-Document-List.aspx'>PDMS</a></p>`,
            subject: "File uploaded for : " + this.props.uploadedFileName + " in ProjectNo: " + this.props.projectNo, 
            to: recipients, 
          }
          let config = {
            headers: {
              "x-access-token": this.props.token,
            }
          }
          axios.post (`/api/v1/sendEmailByOutLook365`,request,config).then((i) => {  
      
            //Set Success Message Bar after sending Email  
            this.setState({  
              statusMessage: { isShowMessage: true, message: "Email sent successfully to document controller.", messageType: 4,isBigSizeDetected:false },
              callMethod:false,
              uploadedFileName:"" ,
              isBusy:false,
              
            });  
          }).catch((i) => {  
            //Set Error Message Bar for any error  
            this.setState({  
              statusMessage: { isShowMessage: true, message: "Error:" + i.message , messageType: 1 },
              isBusy:false
            });  
          }); 
        }
        else {  
          this.setState({  
            statusMessage: { isShowMessage: true, message: "No members in role DC", messageType: 1 }  
          });  
        } 
  }

  }  