import axios from 'axios';
import { json } from 'body-parser';
import JSZip from 'jszip';
import { CommandBar, DefaultButton, DetailsList, IColumn, ICommandBarItemProps, 
  IDetailsList, ISearchBoxStyles, mergeStyles, 
  SearchBox, Spinner, SpinnerSize, Selection, IButtonProps } from 'office-ui-fabric-react';
import * as React from 'react';
import { DateHelper } from './DateHelper';
import { IDetailsListTCRProps } from './IDetailsListTCRProps';
import { ITCRItem } from './ITCRItem';
import TcrCommentsCounting from './TcrCommentsCounting';



const spinnerStyles= {
  circle: {
    height: 25,
    width: 25,
    borderWidth: 4
  }
}

const controlWrapperClass = mergeStyles({
    display: 'flex',
    flexWrap: 'wrap',
  });
  
  const searchWrapperClass = mergeStyles({
    float:'right',
    margin: '10px',
    display:'block',
  });
  
  const tabTableWrapper = mergeStyles({
    width:'100%',
    overflowY:'scroll',
    overflowX:'scroll',
  });
  
  const detailsListWrapper = mergeStyles({
    width: '100% !important',
    margin:'0px',
    overflowY:'auto',
  });
  const hideStyle = {display : 'none'};
  const searchBoxStyles: Partial<ISearchBoxStyles> = { 
    root: { width: 200,float:'right' } 
  };
  export interface IDocDownloaded {
    tqdocId:number; 
    tqDocName:string;
    fileType?: string;    
    ServerRelativeUrl:string;
  }
  export interface IDetailsListTCRExampleState {
    tcritems: ITCRItem[];
    showItemIndexInView: boolean;
    isCompactMode: boolean;
    targetListRefresh:boolean,
    isBusy:boolean;
    isHistoryTCR:boolean; // used to filter pending TCR or history TCR
    search?:string; //the search string in search box
    downloaded:boolean;
    selectionDetails:string;
    submitting:boolean;
    closing:boolean;
    selectedDocs:IDocDownloaded[];
    //tcrIds?: number[];
  }
  const _items: ICommandBarItemProps[] = [
    {
      
      key: 'filterStatus',
      text: 'Filter Status',
      cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
      iconProps: { iconName: 'Filter' },
      subMenuProps: {
        items: [
          {
            key: 'incoming',
            text: 'Incoming',
            iconProps: { iconName: 'RecurringTask' },
            onClick: () => {
              let element: HTMLElement = document.getElementById("btnFilterTCR") as HTMLElement;
              element.textContent = 'incoming'
              element.click();
            }
          },
          {
            key: 'outgoing',
            text: 'Outgoing',
            selected: true,
            iconProps: { iconName: 'TaskLogo' },
            onClick: () => {
              let element: HTMLElement = document.getElementById("btnFilterTCR") as HTMLElement;
              element.textContent = 'outgoing'
              element.click();
            }
          },
        ],
      },
    },
    {
      key: 'NewTCR',
      text: 'New',
      iconProps: { iconName: 'Add' },
      onClick: () => { 
        let element: HTMLElement = document.getElementById("btnPanelTCR") as HTMLElement;
        element.click();
      },
    },
    {
      key: 'EditTCR',
      text: 'Edit',
      iconProps: { iconName: 'Edit' },
      onClick: () => { 
        let element: HTMLElement = document.getElementById("btnPanelTCREdit") as HTMLElement;
        element.click();
      },
    },
    {
      key: 'CloseTCR',
      text: 'Close',
      iconProps: { iconName: 'Accept' },
      onClick: () => { 
        let element: HTMLElement = document.getElementById("btnCloseHidden") as HTMLElement;
        element.click();
      },
    },
    {
      key: 'submitTCR',
      text: 'Submit',
      iconProps: { iconName: 'Upload' },
      onClick: () => { 
        let element: HTMLElement = document.getElementById("btnSubmissionHidden") as HTMLElement;
        element.click();
      },
    },
    {
      key: 'download',
      text: 'Download',
      iconProps: { iconName: 'Download' },
      onClick: () => {
        let element: HTMLElement = document.getElementById("btnDownloadHide") as HTMLElement;
        element.click();
      },
    },
  ];
  const _overflowItems: ICommandBarItemProps[] = [];
  const _farItems: ICommandBarItemProps[] = [];
  const overflowProps: IButtonProps = { ariaLabel: 'More commands' };

  let _allTItems : ITCRItem[] = [];

  export default class DetailsListTCR extends React.Component<IDetailsListTCRProps, IDetailsListTCRExampleState,ICommandBarItemProps> {
    //private _root = React.createRef<IDetailsList>();
    private columns: IColumn[];
    private _allItems: ITCRItem[];
    private _selection: Selection;

    constructor(props: IDetailsListTCRProps) {      
      super(props);

      this._selection = new Selection({
        onSelectionChanged: () => {
          let element: HTMLElement = document.getElementById("btnDownloadHide") as HTMLElement;
          if (this._selection.getSelection().length > 0)
          {
            element.textContent= (this._selection.getSelection()[0] as ITCRItem).Id.toString();
            this.setState({
              selectionDetails: this._getSelectionDetails(),
              selectedDocs:this._getSelectedDocs(),
            });
          }
        },
      });
    this._allItems = [];
    this.columns = [
      { key: 'TcrNo', name: 'TCR NO', fieldName: 'TcrNo', minWidth: 160, maxWidth: 180,isResizable: true },
      { key: 'Subject', name: 'Subject', fieldName: 'Subject', minWidth: 260, maxWidth: 300,isResizable: true },
      { key: 'FromCompany', name: 'From Company', fieldName: 'FromCompany', minWidth: 50, maxWidth: 100,isResizable: true },
      { key: 'RequestReturnDate', name: 'Request Return Date', fieldName: 'RequestReturnDate', minWidth: 100, maxWidth: 200,isResizable: true },
      { key: 'ReturnDate', name: 'Return Date', fieldName: 'ReturnDate', minWidth: 100, maxWidth: 200,isResizable: true },
      { key:'sentBy', name:'Sent By',fieldName:'sentBy',minWidth:100,maxWidth:120,isResizable:true},
      { key:'stage', name:'Stage',fieldName:'stage',minWidth:100,maxWidth:120,isResizable:true},
      { key:'CurrentStatus',name: 'Status',fieldName:'CurrentStatus',minWidth:100,maxWidth:120,isResizable:true},
      { key: 'Comment', name: 'Comments', fieldName: 'Comment', minWidth: 40, maxWidth: 60,isResizable: true },
    ]
      //this._selection = new Selection({});
      this.state = {
        tcritems: [],
        //groups: [],
        //columns: columns,
        showItemIndexInView: false,
        isCompactMode: true,
        isBusy:false,
        downloaded:false,
        submitting:false,
        closing:false,
        isHistoryTCR:false,
        targetListRefresh:false,
        selectionDetails:this._getSelectionDetails(),
        selectedDocs:this._getSelectedDocs(),
      };
      this.tcrAddHandler = this.tcrAddHandler.bind(this)
    }
    private _getSelectionDetails(): string {
      let selectionCount;      
        selectionCount = this._selection.getSelectedCount();
        switch (selectionCount) {
          case 0:
            return 'No items selected';
          case 1:
            let element: HTMLElement = document.getElementById("btnPanelTCREdit") as HTMLElement;
            element.textContent= (this._selection.getSelection()[0] as ITCRItem).Id.toString();
            return '1 item selected: ' + (this._selection.getSelection()[0] as ITCRItem).TcrNo;
          default:
            return `${selectionCount} items selected`;
        }
    }    

  private _getSelectedDocs() : IDocDownloaded[] 
  {
    let results : IDocDownloaded[] = []; 
    this._selection.getSelection().forEach(item =>{
      let tcrItem = item as ITCRItem;
      results.push({
        tqdocId: tcrItem.TQId!,
        tqDocName: tcrItem.TQDocName!,
        ServerRelativeUrl: tcrItem.TQUrl!,
      })
    })
    return results;
  }
  private tcrAddHandler():void{
    this.setState({
      targetListRefresh : true,
    });
  }
    componentDidMount() {
      const url = require('url');
      const querystring = require('querystring');
      let rawUrl = window.location.href;
      let parsedUrl = url.parse(rawUrl);
      let parsedQs = querystring.parse(parsedUrl.query);
      
      if (this.props.currentUserInProject !== undefined)
      {
        this._buildDetailsTCRListItems();
      }       
    }     
    componentDidUpdate(previousProps:IDetailsListTCRProps, previousState:IDetailsListTCRExampleState) {
      if (this.props.currentUserInProject !== undefined && (previousProps.projectNo !== this.props.projectNo || previousProps.currentUserInProject !== this.props.currentUserInProject)) {
        console.log(`==1==`);
        this._buildDetailsTCRListItems();
      }
      if (previousState.isHistoryTCR !== this.state.isHistoryTCR)
      {
        console.log(`==2==`);
        this._buildDetailsTCRListItems();
      }
      if (previousState.search !== this.state.search)
      {
        console.log(`previous: ${previousState.search} vs current: ${this.state.search} `);
        console.log(`==3.1==`);
        if (this.state.search !== null && this.state.search !== undefined && this.state.search !== '')
        {
          console.log(`==3.21==`);
          let keystring = this.state.search.toLowerCase();
          this._allItems = _allTItems.filter((oitem:any) =>
            {
              return oitem.TcrNo.toLowerCase().includes(keystring) 
            || oitem.Subject.toLowerCase().includes(keystring) 
            || oitem.FromCompany.toLowerCase().includes(keystring) 
            || oitem.stage.toLowerCase().includes(keystring)  
            || oitem.CurrentStatus.toLowerCase().includes(keystring)  
          })
          this.setState({
            tcritems:this._allItems,
            isBusy:false,
          });
        }
        else{
          console.log(`==3.22==`);
          this.setState({
            tcritems:_allTItems,
            isBusy:false,
          });
        } 
      }
      if (previousState.downloaded === false && this.state.downloaded === true)
      {
        console.log(`==4==`);
        if (this._selection.getSelection().length === 0)
        {
          console.log(`==4.1==`);
          alert('Please select your items');
          this.setState({
            downloaded:false,
          });
        }
        else if (this._selection.getSelection().length === 1)
        {
          console.log(`==5==`);
          this._downloadSelectedItems();
        }
        else if (this._selection.getSelection().length > 1)
        {
          console.log(`==6==`);
        this._downloadAndZipSelectedItems();
        }
      }
      if (previousProps.added === true && this.props.added === false )
      {
        console.log(`==7==`);
        this._buildDetailsTCRListItems();
      }
      if (previousProps.added === false && this.props.added === true )
      {
        console.log(`==8==`);
        this.props.handlerAdded();
        //this.loadDataWithCallback("adding");
      }
      if (previousState.submitting === false && this.state.submitting === true)
      {
        console.log(`==9==`);
        if (this._selection.getSelection().length === 0)
        {
          alert('Please select your items');
          this.setState({
            submitting : false,
            isBusy:false,
          })
        }
        else if (this._selection.getSelection().length === 1)
        {
          console.log(`==9.2==`);
          this.submitTCR();
        }
        else
        {    
          console.log(`==9.3==`);
          alert('Please select only one item');
          this.setState({
            submitting : false,
            isBusy:false,
          })
        }
      }
      if (previousState.submitting === true && this.state.submitting === false)
      {
        console.log(`==10==`);
        this._buildDetailsTCRListItems();
      }
      if (previousState.closing === false && this.state.closing === true)
      {
        console.log(`==11==`);
        if (this._selection.getSelection().length === 0)
        {
          alert('Please select your items');
          this.setState({
            closing : false,
            isBusy:false,
          })
        }
        else if (this._selection.getSelection().length === 1)
        {
          console.log(`==11.2==`);
          this.closeTCR();
        }
        else
        {
          console.log(`==11.3==`);
          alert('Please select only one item');
          this.setState({
            closing : false,
            isBusy:false,
          })
        }
      }
      if (previousState.closing === true && this.state.closing === false)
      {
        console.log(`==12==`);
        this._buildDetailsTCRListItems();
      }
    }
   
    public render(): React.ReactElement<IDetailsListTCRProps> {
    const { tcritems, isCompactMode } = this.state;
    //const { items} = this.state;

    return (
      <div className={tabTableWrapper}>
        <table className={detailsListWrapper}>
          <tbody>
            <tr>
                <td>
                    <div>
                        <CommandBar
                        items={_items}
                        overflowItems={_overflowItems}
                        overflowButtonProps={overflowProps}
                        farItems={_farItems}
                        ariaLabel="Use left and right arrow keys to navigate between commands"/>
                    </div>
                </td>
                <td>
                    <div className={searchWrapperClass}>
                        {/* <SearchBox styles={searchBoxStyles} placeholder="Search" onChange={this.setSearchState} onBlur={ev =>{this._searchApply(ev.target.value);}}/> */}
                        <SearchBox styles={searchBoxStyles} placeholder="Search" onChange={this.setSearchState} />
                    </div> 
                </td>
            </tr>
            <tr>
              <td colSpan={2}>  <div>{this.state.selectionDetails}</div></td>
            </tr>
            <tr>
              <td colSpan={2}>  { (this.state.isBusy) ? <Spinner styles={spinnerStyles} /> : null } </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <DetailsList     
                  items={tcritems}                 
                  //componentRef={this._root} 
                  columns={this.columns}
                  getKey={this._getKey} 
                  selection={this._selection}
                  ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                  ariaLabelForSelectionColumn="Toggle selection"
                  checkButtonAriaLabel="Row checkbox"
                  isHeaderVisible={true}
                  //onRenderDetailsHeader={this._onRenderDetailsHeader}
                  onRenderItemColumn={this._onRenderColumn}
                  compact={isCompactMode}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <div className={controlWrapperClass}>
        </div>
        <DefaultButton style={hideStyle} id='btnFilterTCR' onClick={e=> this._setTCRFilterState(e)} text='btnFilterHide' ></DefaultButton>
        <DefaultButton style={hideStyle} id='btnDownloadHide' onClick={()=> this.setDownloadedState()} text='downloadHide' ></DefaultButton>
        <DefaultButton style={hideStyle} id='btnSubmissionHidden' onClick={() => this.setSubmissionState() } text = 'submissionHide'></DefaultButton>
        <DefaultButton style={hideStyle} id='btnCloseHidden' onClick={() => this.setCloseState() } text = 'submissionHide'></DefaultButton>
      </div>
    );    
  }
  
  setSearchState = (event:any) =>
    {
      if(event!){
        if (event.target !== undefined)
        {
          this.setState({
            search: event.target.value,
          });
        }
      }else{
        this.setState({
          search: "",
        });
      }    
    }

  private async _buildDetailsTCRListItems() {
    if (this.props.projectNo === null || this.props.projectNo === undefined || this.props.currentUserInProject === undefined)
      return;
    if (this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      });
    }
    //let mode;
    let mode = this.state.isHistoryTCR ? "incoming" : "outgoing"
    
    const TcrPromise = this._getTCRItems(mode);
     //const Promise1 = this._getAllRoles();
     //const Promise2 = this._getTCRStageActors();
     const TQPromise = this._getTQs();
     const CommentPromise= this._getTCrComments();
     const RequestPromise= this.getRequestUsers();
     const statusPromise = this.getTcrStatus();
     //let values = await Promise.all([TcrPromise, Promise1, Promise2, Promise3]);
     
    let values = await Promise.all([TcrPromise, TQPromise, CommentPromise, RequestPromise,statusPromise]);
     let responseItems = values[0];
     let responseTQDocs = values[1];
     let resComments = values[2];
     let resRequesterUser = values[3];
     let resStatus = values[4];
     console.log(`(values[4]) = ${JSON.stringify(values[4])}`);
    // let response1 = values[1];      
    
    
    _allTItems  = [];
    let _docs: { Id: any; TQNo: any; Subject: any; FileRef: any; FileLeafRef:any;}[] = [];
    let _resUsers:{ Id: any; UserName: any; Company: any; }[]=[];
    let _status:{Id:any,StatusCode:any;}[] = [];


    resStatus.map((item:{ID:any;Title:any}) => {
      _status.push({
        Id:item.ID,
        StatusCode:item.Title,
      });
    });

    responseTQDocs.map((item: { ID: any; Title: any; Subject:any; FileRef: any;FileLeafRef : any;})=>{
      _docs.push({
          Id:item.ID,
          TQNo:item.Title,
          Subject:item.Subject,
          FileRef:item.FileRef, 
          FileLeafRef:item.FileLeafRef,         
      });
    });
    resRequesterUser.map((item: { ID: any; Title: any; Company:any})=>{
      _resUsers.push({
          Id: item.ID,
          UserName: item.Title,
          Company: item.Company?.Short_x0020_Name,     
      });
    });  
    
    responseItems.map((item: { Id: any; Status:{Id:any}; Title: any; Subject: any; RequestReturnDate: any; 
      ResponseDate:any; DateSubmit: any; From: any; SubmitBy:any; DocId:{Id:any}; RequestBy:{Id:any};SequenceStageCode:{Id:any,Title:any};
      ClarificationStageCode:{Id:any,Title:any};IsClarificationStage:any}) =>{
      if (item != null)
      {
        // debugger;
        let resStaus = _status.find(obj => {return obj.Id === (item.Status ? item.Status.Id: 0)});
        let result2 = _docs.find(obj => {return obj.Id === (item.DocId ? item.DocId.Id : 0)});
        let resFCompany = _resUsers.find(obj => {return obj.Id === (item.RequestBy ? item.RequestBy.Id : 0)});
        let commentCounting : number = resComments.filter((obj: { Item_x0020_Bind_x0020_Id: any; }) => {return obj.Item_x0020_Bind_x0020_Id === item.Id}).length;
        _allTItems.push({
          Id: item.Id,
          TcrNo: item.Title,
          Subject: item.Subject,
          FromCompany: resFCompany?.Company,
          RequestReturnDate: item.RequestReturnDate === undefined ? "" : item.RequestReturnDate,
          //ReturnDate: item.DateSubmit === undefined ? "" : item.DateSubmit,
          ReturnDate: item.ResponseDate === undefined ? "" : item.ResponseDate,
          sentBy: resFCompany?.UserName,//item.SubmitBy === undefined? "" : item.SubmitBy.Title,            
          Comment: commentCounting,//item.comment == undefined? "" : item.comment,
          Key:item.Id,
          siteUrl:this.props.siteUrl,
          TQId: result2 === undefined? 0 : result2?.Id,
          TQUrl: result2 === undefined? "": result2?.FileRef,
          TQDocName: result2 === undefined? "": result2?.FileLeafRef,
          projectNo:this.props.projectNo,
          CurrentStatus:resStaus?.StatusCode === undefined ? "" : resStaus?.StatusCode,
          stage:item.IsClarificationStage?(item.ClarificationStageCode ? item.ClarificationStageCode.Title : "") 
          : (item.SequenceStageCode ? item.SequenceStageCode.Title : ""),
        });
      }
    }); 
    
    // responseItems.map((item: { Id: any; StatusId:any; Status_x003a_Code:any; Title: any; Subject: any; RequestReturnDate: any; ResponseDate:any; DateSubmit: any; FromId: { Title: any; }; SubmitBy:{Title:any;}; DocIdId:any; RequestById:any}) =>{
    //   if (item != null)
    //   {
    //     let resStaus = _status.find(obj => {return obj.Id === item.StatusId});
    //     let result2 = _docs.find(obj => {return obj.Id === item.DocIdId});
    //     let resFCompany = _resUsers.find(obj => {return obj.Id === item.RequestById});
    //     let commentCounting : number = resComments.filter((obj: { Item_x0020_Bind_x0020_Id: any; }) => {return obj.Item_x0020_Bind_x0020_Id === item.Id}).length;
    //     _allTItems.push({
    //       Id: item.Id,
    //       TcrNo: item.Title,
    //       Subject: item.Subject,
    //       FromCompany: resFCompany?.Company,
    //       RequestReturnDate: item.RequestReturnDate === undefined ? "" : item.RequestReturnDate,
    //       //ReturnDate: item.DateSubmit === undefined ? "" : item.DateSubmit,
    //       ReturnDate: item.ResponseDate === undefined ? "" : item.ResponseDate,
    //       sentBy: resFCompany?.UserName,//item.SubmitBy === undefined? "" : item.SubmitBy.Title,            
    //       Comment: commentCounting,//item.comment == undefined? "" : item.comment,
    //       Key:item.Id,
    //       siteUrl:this.props.siteUrl,
    //       TQId: result2 === undefined? 0 : result2?.Id,
    //       TQUrl: result2 === undefined? "": result2?.FileRef,
    //       TQDocName: result2 === undefined? "": result2?.FileLeafRef,
    //       projectNo:this.props.projectNo,
    //       CurrentStatus:resStaus?.StatusCode,
    //     });
    //   }
    // });  

    this.setState({
      tcritems:_allTItems,
      isBusy:false,
    });
  }
  private async _getTCRItems(mode: string) {
    let result = await axios.get(`/api/v1/tcr/getTcrs?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}&isIncoming=${mode}`);
    return result.data.body;
  }
  private async _getTQs() {
   let result = await axios.get(`/api/v1/tcr/getTQDocs?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}`);
   return result.data.body;
  }
  private async _getTCrComments() {
    // let result = await axios.get(`/api/v1/tcr/getTcrComments?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}`);
    // return result.data.body;
    let result = await axios.get(`/api/v1/tcr/allComments?projectNo=${this.props.projectNo}`);
    return result.data.body;
  }
  private async getRequestUsers() {
    let result = await axios.get(`/api/v1/tcr/getTQUser?projectNo=${this.props.projectNo}`);
    return result.data.body;
  }
  private async getTcrStatus() {
    let result = await axios.get(`/api/v1/tcr/getTcrStatus?projectNo=${this.props.projectNo}`);
    return result.data.body;
  }
  
  private async _getTCRStageActors() {
    console.log("TCR")
    // let result = await axios.get(`/api/v1/TCR?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}`);
    // return result.data.body;
  }
  private async _getAllRoles() {
    console.log("TCR")
    // let result = await axios.get(`/api/v1/TCR?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}`);
    // return result.data.body;
  }
  
  private _setTCRFilterState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | import("office-ui-fabric-react").BaseButton | import("office-ui-fabric-react").Button | HTMLSpanElement, MouseEvent>): void {
    if (this.state.isHistoryTCR !== ((e.target as HTMLElement).textContent === "incoming" ? true : false))
    {
    this.setState({
      isHistoryTCR:(e.target as HTMLElement).textContent === "incoming" ? true : false,
      isBusy:true,
      tcritems:[],
      //groups:[],
    });
  }
  }
    
  private setDownloadedState()
  {
    this.setState({
      downloaded:true,
    });
  }

  private _downloadSelectedItems()
  {
    if (this._selection.getSelection().length > 0)
    {
      if (this.state.isBusy === false)
      {
        console.log(495);
        this.setState({
          isBusy:true,
        });
      }
      let srUrl = this.state.selectedDocs[0].ServerRelativeUrl;
      //let fileType = srUrl.substring(srUrl.lastIndexOf("."));
      let fileName = `${this.state.selectedDocs[0].tqDocName}`;
      axios.get(`/api/v1/singleFileDownload?projectNo=${this.props.projectNo}&serverReltiveURL=${srUrl}`,
      {responseType: 'blob',})
      .then((res)=>{
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}`); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.setState({
          downloaded:false,
          isBusy:false,
        });
      })
    };
  }
  

  private _downloadAndZipSelectedItems()
    {
      if (this.state.isBusy === false)
      {
        this.setState({
          isBusy:true,
        });
      }
      var zipFilename = `${this.props.projectNo}_TcrDocs.zip`;		
      var zip = new JSZip();
      var allPromise = [];
      if (this._selection.getSelection().length > 0)
      {
        let countFile = 0;
        for (var i = 0; i < this.state.selectedDocs.length; i++)
        {
          let cFile : IDocDownloaded = this.state.selectedDocs[i] as IDocDownloaded;
          let srUrl = cFile.ServerRelativeUrl;
          let fileType = srUrl.substring(srUrl.lastIndexOf("."));
          let fileName = `${this.state.selectedDocs[i].tqDocName}${fileType}`;
          let c_promise = new Promise((resolve, reject)=>{}); 
          allPromise.push(c_promise);
          axios.get(`/api/v1/singleFileDownload?fileName=${fileName}&projectNo=${this.props.projectNo}&serverReltiveURL=${srUrl}`,
          {responseType: 'blob',})
          .then((res)=>{
            countFile ++;
           zip.file(fileName, res.data, {binary:true});
           if  (countFile === this.state.selectedDocs.length)
           {
            zip.generateAsync({type:'blob'}).then(function(content) {
              saveAs(content, zipFilename);  
            }).then( ()=>
            {
               this.setState({
                 downloaded:false,
                 isBusy:false
                });
            });
           }
          });
        }
      }
    }

  private setSubmissionState ()
  {    
    this.setState({
      submitting:true,
      isBusy:true,
    });
  }
  private setCloseState ()
  {    
    this.setState({
      closing:true,
      isBusy:true,
    });
  }
  private _searchApply(keystring:string) : void {
    //alert(keystring);
    console.log(574);
    this.setState({
      search:keystring,
      isBusy:true,
      tcritems:[],
      //groups:[],
    });
  }
  private _getKey(item: any, index?: number): string {
    return item.Id.toString();
  }
  // private _onRenderDetailsHeader(props?: IDetailsHeaderProps, _defaultRender?: IRenderFunction<IDetailsHeaderProps>) {
  //   return <DetailsHeader {...props!} ariaLabelForToggleAllGroupsButton={'Expand collapse groups'} />;
  // }
  private _onRenderColumn(item?: ITCRItem, index?: number, column?: IColumn) {
    const value =
    item && column && column.fieldName ? item[column.fieldName as keyof ITCRItem] || '' : '';
    if (column!.fieldName === "Comment")
    {
      return <TcrCommentsCounting refItem={item!} count={value as number} ></TcrCommentsCounting>
    }
    if (column!.fieldName === "RequestReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else if (column!.fieldName === "ReturnDate")
    {
      if (value !== null && value !== '')
      {
      let formatedDateValue = DateHelper.FormatDateToLongDate(value as string);
      //let dateValue1 = DateHelper.Deserialize(value.toString());
      return <div data-is-focusable={true}>{formatedDateValue}</div>
      }
    }
    else
    {
       return <div data-is-focusable={true}>{value}</div>; 
    }
  }

  //Submit TCR
  private submitTCR(){
    this.setState({
      isBusy:true,
    });

    let tcrIds: number[] = [];
    let tcrId: number = 0;
    this._selection.getSelection().forEach(item => {
      tcrIds.push((item as ITCRItem).Id);
      tcrId = (item as ITCRItem).Id;
    });

    //let current = this.getById(tcrIds, stageId, this.props.projectNo);//TCRStageActor
    //let next = this.getNextTransmitallStage(current);
// debugger;
    let request = {
      projectNo : this.props.projectNo,
      tcrId: tcrId,
      currentUser:this.props.currentUserInProject,
    }
    axios.post(`/api/v1/tcr/submitOutGoingTcr`,request).then( (d) =>{
      console.log(JSON.stringify(d.data.body));
      if (d.data.body === null || d.data.body === undefined)
      {
        //alert(`Submission successfully`);
        let emailRequest = {
          projectNo:this.props.projectNo,
          tcrId:tcrId,
          dcEmails:this.props.dcEmails,
          user:this.props.currentUserInProject,
        }

        axios.post(`/api/v1/tcr/sendTCRRequestEmail`,emailRequest).then((d) => {
          alert(`Submitted successfully with email notification sent.`);
          this.setState({
            isBusy:false,
            submitting:false,
          });
        }).catch((ex) => {
          alert(`Send Email Error: ${ex}`);
          this.setState({
            isBusy:false,
            submitting:false,
          });
        });
      }
      else if (d.data.body === 'Invalid TCR')
      {
        alert(`This TCR is not valid for submission.`);
        this.setState({
          isBusy:false,
          submitting:false,
        });
      }
      else if (d.data.body.indexOf("Error") >= 0)
      {
        alert(d.data.body);
        this.setState({
          isBusy:false,
          submitting:false,
        });
      }

    }) 
  }

   //Submit TCR
   private closeTCR(){
    this.setState({
      isBusy:true,
    });

    let tcrIds: number[] = [];
    let tcrId: number = 0;
    this._selection.getSelection().forEach(item => {
      tcrIds.push((item as ITCRItem).Id);
      tcrId = (item as ITCRItem).Id;
    });

    let request = {
      projectNo : this.props.projectNo,
      tcrId: tcrId,
      user:this.props.currentUserInProject!,
    }
    axios.post(`/api/v1/tcr/UpdateTCRForClose`,request).then( (d) =>{
      console.log(JSON.stringify(d.data.body));
      if (d.data.body === null || d.data.body === undefined || d.data.body === '')
      {
        //alert(`Submission success!`);
        let emailRequest = {
          projectNo:this.props.projectNo,
          tcrId:tcrId,
          dcEmails:this.props.dcEmails,
          user:this.props.currentUserInProject!,
        }

        axios.post(`/api/v1/tcr/sendTCRCloseEmail`,emailRequest).then((d) => {
          alert(`Close successfully with email notification sent.`);
          this.setState({
            isBusy:false,
            closing:false,
          });
        }).catch((ex) => {
          alert(`Send Email Error: ${ex}`);
          this.setState({
            isBusy:false,
            closing:false,
          });
        });
      }
      else if (d.data.body.indexOf('not valid') >= 0)
      {
        alert(`This TCR is not valid for close.`);
        this.setState({
          isBusy:false,
          closing:false,
        });
      }
      else 
      {
        alert(d.data.body);
        this.setState({
          isBusy:false,
          closing:false,
        });
      }

    }) 
  }

  private async getById(tcrIds:any, projectNo:any){
    let tcrId = tcrIds;
    for(var i=0; i<tcrId.length; i++){
      let result = await axios.get(`/api/v1/tcr/GetItemByTcrId?projectNo=${this.props.projectNo}&userId=${this.props.currentUserInProject?.key}&tcrId=${tcrId}`);
      return result.data.body;
    }   
  }
  private getNextTransmitallStage(current:any){
    //getByTCRNo(current.TCRNo, current.ProjectNo)
  }
}