import * as React from 'react';
import { Panel, } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
//import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import CommentsMDLAssignment from './CommentsMDLAssignment'
// import { ICommentMDLAssignment } from './ICommentMDLAssignment';
import PanelMDLAssignment from './PanelMDLAssignmentComments';
import {IPdmsUser} from '../IPdmsUser';

//import { Web } from "@pnp/sp/webs"; 

import { DateTimeFieldFormatType } from "sp-pnp-js";

export interface ICommentMDLAssignment
{
    "Id": number;
    "Title": string;
    "Item_x0020_Bind_x0020_Id": number;
    "Category": string;
    "Comments": string;
    "CommentDate": DateTimeFieldFormatType.DateTime,
    "CommentById": number;
    "CommentByTitle": string;
}

const buttonStyles = { root: { marginRight: 8 } };

export interface ICustomPanelMDLCommentsProps {
    //context?: any | null;
    isOpen: boolean;
    siteUrl:string;
    projectNo:string;
    onDissmiss:any;
    mdlAssignmentId:string;
    currentProjectUser:IPdmsUser;
    commentCounting?:string;
    commentMode:string;
    revNo:string;
    mdlId:string;
    documentNo:string;
    //handlerNonCrlPanel?:any
    //isRefreshTarget?:boolean
  }

  export interface ICustomPanelMDLCommentsState {
    //isOpen: boolean;
    // targetListRefresh:boolean;
    // uploaded:boolean;
    isAddingMode:boolean;
    commentItems:ICommentMDLAssignment[];
  }

export default class CustomPanelMDLComments extends React.Component<ICustomPanelMDLCommentsProps, ICustomPanelMDLCommentsState> {


    constructor(props: ICustomPanelMDLCommentsProps | Readonly<ICustomPanelMDLCommentsProps>) {  
        super(props);  
        // sp.setup({
        //   spfxContext: this.props.context
        // });
        this.state={
          isAddingMode : false,
          commentItems:[],
        };
        //this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this);  
      } 

      componentDidMount() 
      {
        //alert(this.state.isAddingMode.toString() + this.props.commentCounting );
        console.log(this.state.isAddingMode);
        console.log(this.props.commentCounting === "0");
      }


  // componentDidUpdate(previousProps:ICustomPanelMDLCommentsProps, previousState: ICustomPanelMDLCommentsState) {
  //     if (previousState.uploaded == false && this.state.uploaded == true) {
  //       //this.props.handlerNonCrlPanel();
  //     }
  // }



  public render()
  {
    return (
      <div>
        <Panel
          //isOpen={isopen}
          //type={ PanelType.smallFixedFar }
          //headerText='Upload document'
          //closeButtonAriaLabel='Close'

          isOpen={this.props.isOpen}
          onDismiss={() => this.triggerItemCommentsCounting()}
          headerText={`MDL Assignment ${this.props.documentNo} ${this.props.revNo}`}
          closeButtonAriaLabel="Close"
          //onRenderFooterContent={onRenderFooterContent}
          // Stretch panel content to fill the available height so the footer is positioned
          // at the bottom of the page
          //isFooterAtBottom={true}
        >
      { 
        (() => {
          if(this.state.isAddingMode || this.props.commentCounting === "0") {
            return (<div><PanelMDLAssignment documentNo={this.props.documentNo} mdlId={this.props.mdlId} commentMode={this.props.commentMode} handleAdd={() => this.handleCommentAdd()} currentProjectUser={this.props.currentProjectUser} revNo = {this.props.revNo}  mdlAssignmentId = {this.props.mdlAssignmentId!} projectNo={this.props.projectNo} siteUrl={this.props.siteUrl}></PanelMDLAssignment>         
            <div><PrimaryButton onClick={()=>this.restoreMode()} id="btnAllComment" styles={buttonStyles}>All Comments</PrimaryButton></div></div>);
          }
          else
          {
            return (<div><CommentsMDLAssignment commentMode={this.props.commentMode} siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} mdlAssignmentId={this.props.mdlAssignmentId}/>
              <PrimaryButton onClick={()=>this._addComment()} id="btnNewComment" styles={buttonStyles}>Add</PrimaryButton></div>);
          }
        })()
      }  
        </Panel>
      </div>
    );
  }

  private _addComment(){
    this.setState({
      isAddingMode:true,
    })   
  }
  private handleCommentAdd(){
    this.setState({
      isAddingMode:false,
    })
  }
  private restoreMode(){
    this.setState({
      isAddingMode:false,
    })
  }
  private triggerItemCommentsCounting() {
    //find the comment counting item hidden button and click
    let element: HTMLElement = document.getElementById(`btnCC${this.props.mdlAssignmentId!}`) as HTMLElement;
    element.click();
    this.props.onDissmiss();
  }
}