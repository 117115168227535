import * as React from 'react';
import { Panel, } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
//import { PanelType } from 'office-ui-fabric-react/lib/Panel';
//import { IComment } from './IComment';
//import DatePicker from 'react-datepicker';

import {IPdmsUser} from './IPdmsUser';
import { addMonths, addYears, CheckboxBase, IDatePickerStyles, IStackProps, Label, Overlay, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { Checkbox} from 'office-ui-fabric-react/lib/Checkbox';
import axios from 'axios';
//import { AnyAaaaRecord } from 'dns';

const buttonStyles = { root: { marginRight: 8 } };
const stackTokens = { childrenGap: 50 };
const columnProps: Partial<IStackProps> = {
  tokens: { childrenGap: 15 },
  styles: { root: { width: 300 } },
};
export interface ICheckboxInput{
  Id:number,
  Title?:string,
}
let options: ICheckboxInput[] = []
//const datePickerStyles: Partial<IDatePickerStyles> = { root: { maxWidth: 300, marginTop: 15 } };
export interface ICustomTCRPanelProps {
    //context?: any | null;
    isOpen: boolean;
    siteUrl:string;
    projectNo:string;
    onDissmiss:any;
    currentProjectUser:IPdmsUser;    
    //transmittalItemId:string;
    context?: any | null;
    token?:string;
    handleAdd?:any;
    commentMode:string;
    dcCompany:string;
    dcEmails:string;
  }

  export interface ICustomTCRPanelState {
    isAddingMode:boolean;
    targetListRefresh:boolean;
    added:boolean;
    //currentDate:string;
    //traID:string;
    isBusy?:boolean;
    checkboxes: CheckboxBase[];
    isChecked: boolean;
    TCRNumber?: string
    startDate:any;
    classifications?: ITCRClassification[];
    classificationSelectedVal: [];
  }
  export interface ITCRClassification
  {  
    Id?: number; 
    Title?: string;    
  }
  //let classificationSelectedVal = [];
//const [startDate, setStartDate] = useState(new Date());
export default class TCRPanel extends React.Component<ICustomTCRPanelProps, ICustomTCRPanelState> {
  private _options : CheckboxBase[] = [];
  
    constructor(props: ICustomTCRPanelProps | Readonly<ICustomTCRPanelProps>) {  
        super(props);  
        this.state={
            isAddingMode : false,
            targetListRefresh:false,
            added:false,
            checkboxes: this._options,
            isChecked: false,            
            //currentDate: date,
            startDate: new Date(),
            classificationSelectedVal: [],
        };
        //tcrAddHandler
        this.tcrAddHandler = this.tcrAddHandler.bind(this);   
      } 
      handleChange(date: any) {
        this.setState({
          startDate: date
        })
      }
      private tcrAddHandler():void{
        this.setState({
          targetListRefresh:true,
          added:true,
          classificationSelectedVal:[],      
        });
      }
      componentDidMount() 
      {
        console.log(this.state.isAddingMode);
        // this._tcrClassification();
        // this.getTCRNo();
      if (this.props.currentProjectUser !== undefined)
      {
        //alert(98);
        this.getTCRNo();
        this._tcrClassification();
      }
        
      }
      componentDidUpdate(previousProps:ICustomTCRPanelProps, previousState:ICustomTCRPanelState){
        if (this.props.currentProjectUser !== undefined && (previousProps.projectNo !== this.props.projectNo || previousProps.currentProjectUser !== this.props.currentProjectUser)) {
          //alert(106);
          this.getTCRNo();
          //this._tcrClassification();
        }
        if (previousState.TCRNumber != undefined && ( this.state.TCRNumber !== previousState.TCRNumber || this.state.added !== previousState.added))
        {
          //alert(112);
          this.getTCRNo();
        }
        // if (this.props.currentProjectUser !== undefined && this.props.isOpen === true)
        // {
        //   this.getTCRNo();
        //   // this._tcrClassification();
        // }
      }

  public render() {    
    //let {checkboxes} =  this.state;
    //let userName = this.props.currentProjectUser ? this.props.currentProjectUser.name : "";    
    return (
      <div>
        <Panel
          isOpen={this.props.isOpen}
          onDismiss={this.props.onDissmiss}//{() => this.triggerItemTcr()}
          headerText="TCR"
          closeButtonAriaLabel="Close">
          <Stack horizontal tokens={stackTokens} >
            <Stack {...columnProps}>
            <Label>Add New TCR</Label>
            <TextField id="txtTcrNo" className="clsText" value={this.state.TCRNumber} label="TCR No.: " required></TextField>
            {/*<Label>Request Return Date :</Label>   
             <DatePicker
              selected={ startDate }
              onChange={handleChange}
              name="startDate"
              dateFormat="MM/dd/yyyy"
              isClearable
              placeholderText="Click to select a date"
            /><button className="btn btn-primary">Show Date</button> */}
            <TextField
              id="txtDate"
              label="Request Return Date :"
              type="date"
              placeholder="Select a date..."
            />         
            {/* <DatePicker id="txtDate"  showMonthPickerAsOverlay={true} styles={datePickerStyles} placeholder="Select a date..." /> */}
            <TextField id="txtSub" className="clsText" label="Subject : " required></TextField>            
            <Label id="txtClassification">Classification :  
            </Label>
            {             
              options.map((checkBoxItem: ICheckboxInput) => {
                return (
                //<div>
                  <Checkbox id={(checkBoxItem.Id).toString()} label={checkBoxItem.Title} value={checkBoxItem.Id} onChange={e => this._onChange(e)} ></Checkbox>
                )
              })           
            }
            <Label>Select Files :</Label>
            {/* <input id="txtfile" type="file" required/> */}
            <form encType="multipart/form-data" id="myForm">
            <input type="file" name="myFile" id="txtfile" required></input>
        </form>
            <div>
            <PrimaryButton onClick={()=>this._addTCR()} id="btnSave" styles={buttonStyles}>Create</PrimaryButton>
            { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
            {/* <DefaultButton onClick={() =>close()}>Cancel</DefaultButton>   */}
            </div>
            </Stack>      
          </Stack>           
        </Panel>
      </div>
    );
  }
  
  private _onChange(e:any){  
    console.log(`The option is changed.`);  
    let cla_list:any = [];
    cla_list = this.state.classificationSelectedVal;
    let isChecked = e.target.checked;
    var isCheckedId = e.target.id;
    if(isChecked === true){
      cla_list.push(isCheckedId);      
      this.setState({
        classificationSelectedVal: cla_list,//[this.state.classificationSelectedVal, isCheckedId]
      })      
      //classificationSelectedVal.push(isCheckedId)
    }else{
      //var values = isChecked.index(e)
      var index = cla_list.indexOf(isCheckedId);
      if (index > -1) {
        cla_list.splice(index, 1);
        this.setState({
          classificationSelectedVal: cla_list
        })
      }      
    }
    //alert(isChecked);
  } 
  // _formateDate = (date?: Date|null|undefined) => void() {
  //   (date: Date): string => {
  //     return  (date.getMonth() + 1) + '/' + date.getDate()  + '/' + (date.getFullYear()); 
  //   }
  // }
  private async _addTCR(){
    if ((document.querySelector("#txtfile") as HTMLInputElement).files!.length === 0)
    {
      alert("Please choose the file to upload.")
      return 0;
    }
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
    let projectNo = this.props.projectNo;
    let runningNumberId = 0;
    let runningNumberValue = '';
    //let currentUserId = this.props.currentProjectUser.key;
    let tcrNo = (document.querySelector("#txtTcrNo") as HTMLInputElement).value; 
    let subject = (document.querySelector("#txtSub") as HTMLInputElement).value; 
    let requestReturnDate = (document.querySelector("#txtDate") as HTMLInputElement).value;
    
    //let workflowID = this.getuserData(currentUserId);
    
    
    var isVendor = this.props.currentProjectUser.role! === "VEN" ? true : false;
    var isCustomer =  this.props.currentProjectUser.role! === "CUS" ? true : false;
    var workflowTitle = isVendor ? "Vendor Send TCR" : "Customer Send TCR";
    let typeCode = isCustomer ? "CTCR" : "VTCR";
    
    let runnigResult = await axios.get(`/api/v1/tcr/getRunningTypeByCode?projectNo=${projectNo}&typeCode=${typeCode}`);
          // if(tcrNo.indexOf("-")>0){
      //   let validFormat = typecoderesult.data.body[0].Running_x0020_Type + "-" + runnigResult.data.body[0].Value;
      runningNumberId = runnigResult.data.body[0].Id;
      runningNumberValue = runnigResult.data.body[0].Value;
      let idxSeqStr = tcrNo.split('-').length - 1;
      let seqStr = tcrNo.split('-')[idxSeqStr];

      if(parseInt(seqStr) !== parseInt(runnigResult.data.body[0].Value) + 1)
      {
        alert (`TCR number invalid, please close the panel and click "Create" again`);
        this.setState({
          isBusy:false,
        })
        return 1;
      }
      let workflowResult = await axios.get(`/api/v1/tcr/getWorkflows?projectNo=${projectNo}&workflowTitle=${workflowTitle}`);//.then((workflowData)=>{
      if(workflowResult.data.body.length === 0){
        return "Workflow stages not configured for :" + workflowTitle;
      }
      //let stageActorId;
      let workflowId;
      for(var i= 0; i < workflowResult.data.body.length; i++){
        if (workflowResult.data.body[i].Sequence === 1)
        {
            //stageActorId = workflowResult.data.body[i].Id;
            workflowId = workflowResult.data.body[i].WorkflowId.Id;
        }
      }           

    //Create TCR      


    let request = {
      projectNo: this.props.projectNo,
      tcrNo:tcrNo,
      subject:subject,
      requestReturnDate: requestReturnDate === undefined ? "": new Date(requestReturnDate),
      classification: this.state.classificationSelectedVal,
      currentUser: this.props.currentProjectUser,
      runningNumberId: runningNumberId,
      runningNumberValue:runningNumberValue,
    };

    if(tcrNo !== "" ){
      axios.post(`/api/v1/tcr/addTcr`,request).then((d) => {
        console.log(d);
        let tcrId = d.data.body;
        
        if ((document.querySelector("#txtfile") as HTMLInputElement).files!.length > 0)
        {
          let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];
          let fname = myfile.name.substring(0, myfile.name.lastIndexOf('.'));
          let fileType = myfile.name.replace(fname,"");
          let newFIleName = `${tcrNo}${fileType}`; 
          //let newFIleName = `${fname}(comment-${tcrId})${fileType}`; 
          let myForm = document.getElementById('myForm') as HTMLFormElement;
          let formData = new FormData(myForm);

          axios.post(`/api/v1/fileUpload?filename=${encodeURIComponent(newFIleName)}&projectno=${this.props.projectNo}&libraryname=TQ`,formData).then((f) => {
            console.log(`f=${f}`);
            let DocId = f.data.fileId;
            //update the metadata for attachment
            let oRequest = {
              //commentId : commentId,
              tcrId: tcrId,
              attachmentId : DocId,
              projectNo : this.props.projectNo,
            };
            axios.post(`/api/v1/tcr/updateTCRDoc`,oRequest).then(() => {
              //this.props.handleAdd();
              alert("TCR successfully created.")
              
              this.setState({
                isBusy:false,
              });
            });
          });
        }        
        //this.props.handleAdd();       
        // this.setState({
        //   isBusy:false,
        // })        
      })
    }
    //Update Docs and Running Number
    this.setState({
      isAddingMode:true,
    });   
  }



  private async getTCRNo(){
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
    let  tcrGenNo = "" ;
    await axios.get(`/api/v1/common/dcUsers?projectNo=${this.props.projectNo}`).then(async (userData)=>{
      var code = this.props.currentProjectUser.role! === "VEN" ? "VTCR" : (this.props.currentProjectUser.role! === "CUS" ? "CTCR" : "");
      var userCom =this.props.currentProjectUser.companyShrotName;
      var dcCom = userData.data.body[0].Company?.Short_x0020_Name;
      await axios.get(`/api/v1/tcr/getNamingType?projectNo=${this.props.projectNo}&typeCode=${code}`).then((typecodeData)=>{
        //alert(typecodeData.data.body[4].Running_x0020_Type.replace("[ProjectNo]", projectNo).replace("[From]", userCom));
        tcrGenNo = typecodeData.data.body[0].Running_x0020_Type.replace("[ProjectNo]", this.props.projectNo).replace("[From]", userCom).replace("[To]",dcCom);
      });
      await axios.get(`/api/v1/tcr/getRunningTypeByCode?projectNo=${this.props.projectNo}&typeCode=${code}`).then((typecodeData)=>{
        let lng = typecodeData.data.body[0].Value.length;
        let val = typecodeData.data.body[0].Value;
        let newVal = Number(val)+Number(1);//+1;
        let newStringVal = newVal.toString().padStart(lng,"0");
        tcrGenNo = tcrGenNo + "-" + newStringVal;
        this.setState({
          TCRNumber: tcrGenNo,
          isBusy:false
        })
        });
    });
    // const Promise1 = this.getTCRNoPrefix();
    // const Promise2 = this.getNextTCRNoSeq();
    // let values = await Promise.all([Promise1,Promise2]);
    // let responseItems = values[0]+'-'+values[1];
    
    // let tcrNumber = responseItems;
    // this.setState({
    //   TCRNumber: tcrNumber,
    // })
  }

//   private async getTCRNoPrefix(){
//     var tcrGenNo ;
//     await axios.get(`/api/v1/common/dcUsers?projectNo=${this.props.projectNo}`).then(async (userData)=>{
//       var code = this.props.currentProjectUser.role! == "VEN" ? "VTCR" : (this.props.currentProjectUser.role! == "CUS" ? "CTCR" : "");
//       var userCom =this.props.currentProjectUser.companyShrotName;
//       var dcCom = userData.data.body[0].Company?.Short_x0020_Name;
//       await axios.get(`/api/v1/tcr/getNamingType?projectNo=${this.props.projectNo}&typeCode=${code}`).then((typecodeData)=>{
//         //alert(typecodeData.data.body[4].Running_x0020_Type.replace("[ProjectNo]", projectNo).replace("[From]", userCom));
//         tcrGenNo = typecodeData.data.body[0].Running_x0020_Type.replace("[ProjectNo]", this.props.projectNo).replace("[From]", userCom).replace("[To]",dcCom);
        
//       });
//     });
//     return tcrGenNo;
//   }

//   private async getNextTCRNoSeq(){

//     var newSeqStr;

//       var code = this.props.currentProjectUser.role! == "VEN" ? "VTCR" : (this.props.currentProjectUser.role! == "CUS" ? "CTCR" : "");
//       await axios.get(`/api/v1/tcr/getRunningTypeByCode?projectNo=${this.props.projectNo}&typeCode=${code}`).then((typecodeData)=>{
//       let lng = typecodeData.data.body[0].Value.length;
//       let val = typecodeData.data.body[0].Value;
//       let newVal = Number(val)+Number(1);//+1;
//       newSeqStr = newVal;
// return newSeqStr;
//       });
//   }
  
  private triggerItemTcr() {
    //find the comment counting item hidden button and click
    //let element: HTMLElement = document.getElementById(`btnCC`) as HTMLElement;
    //element.click();
    this.props.onDissmiss();
  }
  
  toggleChange() {
    this.setState({
      isChecked: !this.state.isChecked // flip boolean value
    }, function() {
    }.bind(this));
  }

  private async _tcrClassification () {
    if(this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      })
    }
    let clas : ITCRClassification = {
    };
    await axios.get(`/api/v1/tcr/getTcrClassification?projectNo=${this.props.projectNo}`).then((clsdata)=>{
      //clsdata
      options = [];
      let classificationData = clsdata.data.body;
      let _classifications = classificationData.map((xx:{Title: string, Id:number})=>{
        options.push({
          Id: xx.Id,
          Title:xx.Title
        });
      // clas.Title = xx.Title;
      // clas.Id = xx.Id;
        //return clas;
      // for (var i = 0; i < classificationData.length; i++)
      // {
      //   // this._options.push({
      //   //   _id: classificationData[i].Id,
      //   //   _checkBox: classificationData[i].Title,
      //   // })
        
      // }
    });
    this.setState({ 
      classifications:_classifications,
      isBusy:false,
    });
  });
    return clas;
  }  
}