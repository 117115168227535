import * as React from 'react';
import { Icon,} from 'office-ui-fabric-react';
import { IComment } from './IComment';
import axios from 'axios';
import { ITCRItem } from './ITCRItem';

export interface ICommentsCountingProps {
    count?:number;
    refItem:ITCRItem;
}

export interface ICommentsCountingState
{
    counted:string;
    refItem:ITCRItem;
}

const hideStyle = {display : 'none',maxWidth:'1px'};
export default class CommentsCounting extends React.Component<ICommentsCountingProps, ICommentsCountingState> {
    constructor(props: ICommentsCountingProps | Readonly<ICommentsCountingProps>) {  
        super(props); 
        this.state={
            counted:this.props.count? this.props.count.toString() : "0",
            refItem:this.props.refItem
        };
    } 

    public render(): React.ReactElement<ICommentsCountingProps> {
        return (
            <div>
                <span onClick={()=>this._openCommentsListPanel()} style={{color: "red",cursor:"pointer"}}><Icon iconName={'FileComment'} />{this.state.counted}</span>
                <button style={hideStyle}  id={`btnCC${this.props.refItem.Id}`} onClick={()=>{this._countingComments()}}></button>
            </div>
        );
    }

    componentDidUpdate(previousProps:ICommentsCountingProps, previousState:ICommentsCountingState)
    {
        //this._countingComments();
    }

    private _openCommentsListPanel() : void
    {
        let element: HTMLElement = document.getElementById("btnPanelTcrComment") as HTMLElement;
        element.textContent= this.state.refItem.Id.toString();
        element.title =  this.state.refItem.Comment === undefined ? '0' : this.props.refItem.Comment!.toString();
        element.click();
    }

    private async _getTCRComments() : Promise<any>
    {
        //alert('_getTCRComments');
        let result = await axios.get(`/api/v1/tcr/commentsCounting?projectNo=${this.props.refItem.projectNo!}&refItemId=${this.props.refItem.Id}`);
        return result.data.body;            
    }

    private async _countingComments()
    {
        //alert(this.props.refItem.projectNo!);
        const promise1=  this._getTCRComments();
        let values = await Promise.all([promise1]);
        let tcrResponse1 = values[0];
        //let _allTransComments : IComment[]  = [];
        let count:number=tcrResponse1.length;
        
        // if(tcrResponse1 != undefined){
        //     count = tcrResponse1.length;
        // }
        var nRefItem = this.state.refItem;
        nRefItem.Comment = tcrResponse1.length.toString();
        this.setState({
            counted:"  " + count?.toString(),
            refItem: nRefItem
        });        
    }  

    private async _buildDetailsListItems()
    {
        const promise1=  this._getTCRComments();
        let values = await Promise.all([promise1]);
        let response1 = values[0];
        let _allTransComments : IComment[]  = [];
        response1.map((item: { ID: any; Title: any; Category: any; Item_x0020_Bind_x0020_Id: any; CommentBy: { Id: number; Title: string; } | null; CommentDate: any; CommentByTitle: any; }) =>{
        _allTransComments.push({
            Id: item.ID,
            Title: item.Title,
            Category: item.Category,
            Item_x0020_Bind_x0020_Id:item.Item_x0020_Bind_x0020_Id,
            CommentById:item.CommentBy == null ? 0 : item.CommentBy.Id,
            CommentDate:item.CommentDate,
            CommentByTitle:item.CommentByTitle,
            Comments:item.CommentBy == null ? "" : item.CommentBy.Title,
            });
        });
        console.log("==========allItems==========:");
        console.log(_allTransComments.length.toString());
    
        this.setState({
            counted:_allTransComments.length.toString(),
        });        
    }
};
