import './Header.css';
import logo from './logo-ia-redesign.png';
import * as React from 'react';
import {IPdmsUser} from './IPdmsUser';
import { IconButton, initializeIcons } from 'office-ui-fabric-react';

//import { initializeIcons } from "fluentui/react";
//import { IconButton } from 'fluentui/react';


initializeIcons();

//ReactDOM.render(<ChevronUpIcon />, document.body.firstChild);

export interface IHeaderProps
{
    currentUser?:IPdmsUser;
    currentUserInProject?:IPdmsUser;
}
export interface IHeaderState
{
    token?:string;
}
export default class Header extends React.Component<IHeaderProps, IHeaderState> {


    constructor(props: IHeaderProps | Readonly<IHeaderProps>) {  
        super(props);  
        this.state={
        };
      } 


  public render(): React.ReactElement<IHeaderProps> {
    return (
        <div className="header">
            <img alt="logo" src={logo} className="logo"></img>
  {/* <a href={logo} className="logo">CompanyLogo</a> */}
  <div className="header-right">
      <table className='header-right'><tbody>
          <tr><td><label className='greeting'>Welcome to PDMS Portal</label><label className='userInfo'></label></td></tr>
          <tr><td>
              <label className='userInfo'>{this.props.currentUserInProject? 
              `${this.props.currentUserInProject!.name}: ${this.props.currentUserInProject!.role!}` : this.props.currentUser!.email}</label>

              {/* <a href="#Signout"  onClick={() => this.logoutByButtonHelper()} >Sign out</a> */}
              <IconButton iconProps={{ iconName: 'Signout' }} className="clearButton" title='Sign out' onClick={() => this.logoutByButtonHelper()} />
          </td></tr>
          <tr><td></td><td></td><td></td></tr>
          </tbody>
      </table>

    {/* <IconButton iconProps={{ iconName: 'Signout' }} className="clearButton" title='Sign out'/> */}
    {/* <a href="#Signout"  onClick={() => this.logoutByButtonHelper()} >Sign out</a> */}
  </div>
</div>
    );
  }

  componentDidUpdate(previousProps:IHeaderProps, previousState: IHeaderState)
  {

  }

  private logoutByButtonHelper()
  {
    let element: HTMLElement = document.getElementById("btnDistroyToken") as HTMLElement;
    element.click();
  }

}