import * as React from 'react';
import { ActivityItem, Icon, Link, mergeStyleSets, Panel } from 'office-ui-fabric-react';
import axios from 'axios';


export interface IAttachmentTQ
{
    Id:string;
    Title:string;
    FileName:string;
    FileUrl:string;

}

export interface IAttachmentTQForTCRProps {
    siteUrl:string;
    projectNo:string;
    tcrId:string;
    tqId:string;
  }

  export interface IAttachmentTQForTCRState
  {
      loaded:string;
      attachments?:IAttachmentTQ[];
  }

//export const ActivityItemBasicExample: React.FunctionComponent = () => {
    export default class AttachmentTQForTCR extends React.Component<IAttachmentTQForTCRProps, IAttachmentTQForTCRState> {

        constructor(props: IAttachmentTQForTCRProps | Readonly<IAttachmentTQForTCRProps>) {  
            super(props); 
            this.state={
                loaded:"",
                attachments:[],
            };
          }

          public render(): React.ReactElement<IAttachmentTQForTCRProps> {
            if (this.state.attachments!.length > 0)
            {
                return (<p><span onClick={()=>this._downloadAttachment()} style={{color: "blue",cursor:"pointer"}}><Icon iconName={'Attach'} />{this.state.loaded}</span></p>);
            }
            else
            {
                return(<p></p>);
            }
        }

        componentDidMount()
        {
            this._loadingAttachment();
        }

        componentDidUpdate(previousProps:IAttachmentTQForTCRProps, previousState:IAttachmentTQForTCRState) {
          if (previousProps.tqId !== this.props.tqId)
          {
          this._loadingAttachment();
          }
      }

        private _downloadAttachment() : void
        {
          let fileName = this.state.attachments![0].FileName;
          axios.get(`/api/v1/singleFileDownload?libraryName=TQ&fileName=${fileName}&projectNo=${this.props.projectNo}`,
          {responseType: 'blob',})
          .then((res)=>{
            //Buffer.from(res.data, 'binary').toString('base64');
            //this.props.handlerDownload();
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`); //or any other extension
            document.body.appendChild(link);
            link.click();
           });
        }

        private async _getCommentAttachment() : Promise<any>
        {
          //let result = await axios.get(`/api/v1/transmittalItems/commentAttachment?projectNo=${this.props.projectNo}&commentId=${this.props.CommentId}`);
          let result = await axios.get(`/api/v1/tcr/tqAttachment?projectNo=${this.props.projectNo}&tqId=${this.props.tqId}`);
          return result.data.body;
       }

        private async _loadingAttachment()
        {
          const promise1=  this._getCommentAttachment();
         let values = await Promise.all([promise1]);
         let response1 = values[0];
         let _allAtts : IAttachmentTQ[]  = [];
         response1.map((item: { ID: any; Title: any; File: { Name: string; }; }) =>{
          _allAtts.push({
              Id: item.ID,
              Title:item.Title,
              FileName:item.File.Name,
              FileUrl:this.props.siteUrl + "/" + this.props.projectNo + "/TQ/"+ item.File.Name,
           });
          });

      
         //this._options = this._renderStatusOptions();
      if (_allAtts.length > 0)
      {
          this.setState({
              loaded:_allAtts[0].FileName,
              attachments:_allAtts,
          });
      }
         
      
        }
};
