import * as React from 'react';
//import { TextField } from 'office-ui-fabric-react/lib/TextField';
//import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Fabric } from 'office-ui-fabric-react/lib/Fabric';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
//import { PrimaryButton, IButtonProps ,MessageBar,CompoundButton, DefaultButton} from 'office-ui-fabric-react'; 
import {
  DetailsList,
  DetailsListLayoutMode,
  Selection,
  SelectionMode,
  IColumn,
} from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Web } from "@pnp/sp/webs";  

import "@pnp/sp/files";
import "@pnp/sp/folders";
import  JSZip from 'jszip';
//import JSZipUtils from 'jszip-utils';

import {saveAs} from 'file-saver';
import { SearchBox, ISearchBoxStyles } from 'office-ui-fabric-react/lib/SearchBox';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
//import { Z_PARTIAL_FLUSH } from 'zlib';

//const FileDownload = require('js-file-download');
//const JSZipUtils = require('jszip-utils');
import{DateHelper} from "./DateHelper";
import { IPdmsUser } from './IPdmsUser';
import { Overlay, ProgressIndicator } from 'office-ui-fabric-react';
import { config } from 'sp-pnp-js';

const controlWrapperClass = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const searchWrapperClass = mergeStyles({
  float:'right',
  margin: '10px',
  display:'block',
});

const tabTableWrapper = mergeStyles({
  width:'100%',
  overflowY:'scroll',
  overflowX:'scroll',
});

const detailsListWrapper = mergeStyles({
  width: '100% !important',
  margin:'0px',
  overflowY:'auto',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const searchBoxStyles: Partial<ISearchBoxStyles> = { root: { width: 200 } };

export interface IDetailsListDocumentsState {
  columns: IColumn[];
  items: IDocument[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
  jsonResponse?:any; //json object to hold response from pnp js methods 
  deleting:boolean;
  adding:boolean;
  downloading:boolean;
  targetListRefresh:boolean; 
  isBusy?:boolean;
  progressPercent: number;
  progressLabel:string;
  progressDescription:string;
  showProgress: boolean;
}

export interface IDetailsListDocumentsProps {
  currentSite:string,
  currentProject:string,
  deleted:boolean,
  added:boolean,
  downloaded:boolean,
  handlerDeleted:any,
  handlerAdded:any,
  handlerDownload:any,
  currentUserInProject?:IPdmsUser;
}

export interface IDocument {
  key: string;
  name: string;
  value: string;
  iconName: string;
  fileType: string;
  fileSize: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
  fileSizeValue:number;
}

var docsNonContorlBeforeSeach: any[] = [];
export class DetailsListDocumentsExample extends React.Component<IDetailsListDocumentsProps, IDetailsListDocumentsState> {
  private _selection: Selection;
  private _allItems: IDocument[];

  componentDidMount() {
    if (this.props.currentUserInProject !== undefined)
    {
      this.loadDataWithCallback();
    }
  }

  constructor(props: IDetailsListDocumentsProps) {
    super(props);
    this._allItems = [];
    const columns: IColumn[] = [
        {
          key: 'column1',
          name: 'File Type',
          className: classNames.fileIconCell,
          iconClassName: classNames.fileIconHeaderIcon,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          iconName: 'Page',
          isIconOnly: true,
          fieldName: 'name',
          minWidth: 16,
          maxWidth: 16,
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
          },
        },
        {          
          key: 'column0',          
          name: 'File Size',          
          fieldName: 'fileSizeValue',          
          minWidth: 210,          
          maxWidth: 350,          
          isRowHeader: true,          
          isResizable: true,          
          isSorted: true,          
          isSortedDescending: false,                  
          onColumnClick: this._onColumnClick,          
          data: 'number',  
          onRender: (item: IDocument) => {
            return <span>{item.fileSize}</span>;
          },        
          isPadded: true,        
        },
        {
          key: 'column2',
          name: 'Name',
          fieldName: 'name',
          minWidth: 210,
          maxWidth: 350,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: this._onColumnClick,
          data: 'string',
          isPadded: true,
        },
        {
          key: 'column3',
          name: 'Date Modified',
          fieldName: 'dateModifiedValue',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          onColumnClick: this._onColumnClick,
          data: 'number',
          onRender: (item: IDocument) => {
            return <span>{item.dateModified}</span>;
          },
          isPadded: true,
        },
        {
          key: 'column4',
          name: 'Modified By',
          fieldName: 'modifiedBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'string',
          onColumnClick: this._onColumnClick,
          onRender: (item: IDocument) => {
            return <span>{item.modifiedBy}</span>;
          },
          isPadded: true,
        },
      ];
    
      this._selection = new Selection({
        onSelectionChanged: () => {
          let element: HTMLElement = document.getElementById("btnPanelNonControl") as HTMLElement;
          if (this._selection.getSelection().length > 0)
          {
            element.textContent= (this._selection.getSelection()[0] as IDocument).key;
            this.setState({
              selectionDetails: this._getSelectionDetails(),
            });
          }
        },
      });
    
      this.state = {
        items: this._allItems,
        columns: columns,
        selectionDetails: this._getSelectionDetails(),
        isModalSelection: true,
        isCompactMode: false,
        announcedMessage: undefined,
        deleting:false,
        adding:false,
        targetListRefresh:false,
        downloading:false,
        isBusy:false,
        showProgress: false,
        progressPercent: 0,  
        progressLabel: "File download progress",
        progressDescription:"",
      };
      this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this)
  }

  public render() {
    const { columns, isCompactMode, items, selectionDetails, isModalSelection, announcedMessage } = this.state;

    return (
      <div className={tabTableWrapper}>
        <table className={detailsListWrapper}>
          <tbody>
          {/* <Fabric> */}
          {/* <div className={classNames.controlWrapper}> */}
          <tr>
            <td>
            <div className={searchWrapperClass}>
              <SearchBox
                styles={searchBoxStyles}
                placeholder="Search"
                onEscape={ev => { console.log('Custom onEscape Called'); }}
                onClear={ev => { this.setState({items:docsNonContorlBeforeSeach}); }}
                onSearch={_sKey => this._searchApply(_sKey)}
              /> 
            </div>
            </td>
          </tr>
          <tr>
            <td><Announced message={`Number of items after filter applied: ${items.length} in ${this.props.currentProject}.`} /></td>
          </tr>
          {/* </div> */}
          <tr>
            <td colSpan={2}><div className={classNames.selectionDetails}>{selectionDetails}
          <Announced message={selectionDetails} />
          {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
          </div></td>
          </tr>
          <tr>
          { this.state.showProgress? <td width={100}><ProgressIndicator label={this.state.progressLabel} description={this.state.progressDescription} percentComplete={this.state.progressPercent}
          barHeight={5} /></td>: null }

            <td colSpan={2}>  
            {/* { (this.state.isBusy) ? <Spinner size={SpinnerSize.small} /> : null }  */}
            { (this.state.isBusy) ? <Overlay><Spinner size={SpinnerSize.large} ></Spinner ></Overlay> : null }
            </td>
          </tr>
          <tr>
              <td colSpan={2}>
          {isModalSelection ? (
          <MarqueeSelection selection={this._selection}>
            <DetailsList
              items={items}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
            />
          </MarqueeSelection>) : (
          <DetailsList
            items={items}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={this._getKey}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        )}
        </td>
        </tr>
        {/* </Fabric> */}
        </tbody>
        </table>
      </div>
    );
  }


  private loadDataWithCallback(mode:string = ""){
    if (this.props.currentProject === null || this.props.currentProject === undefined || this.props.currentUserInProject === undefined)
      return;

    if (this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      });
    }      
    axios.get(`/api/v1/noneCtrDocs/list?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject?.key}`)
    .then(
      (response) => {
        let _allItems: any[] = [];
        
        response.data.body.forEach((item: { ID: { toString: () => any; }; File: { Name: any; Length:any } | null; Document_x0020_Type: string | null | undefined; ModifiedBy: any; Modified: any; }) =>{
          _allItems.push({
            key: item.ID.toString(),
            name: item.File === null ? "" : item.File.Name,
            value: item.ID,
            iconName : item.Document_x0020_Type === null ? "" : _randomFileIcon(item.Document_x0020_Type).url,
            fileType: item.Document_x0020_Type,
            modifiedBy: item.ModifiedBy === undefined ? "" : item.ModifiedBy.Title,
            fileSize: item == null ? "0 kb" : `${Math.round(item.File?.Length/1024)} kb`,
            dateModified: DateHelper.FormatDateToLongDate(item.Modified),
            dateModifiedValue: item.Modified.valueOf(),
            fileSizeValue: (item.File?.Length/10240).valueOf(),
          });
        });
        this.setState({
          items:_allItems,
          isBusy:false,
        });
        docsNonContorlBeforeSeach = _allItems;
        if(mode === "deleteing")
        {
          this.props.handlerDeleted();
        }
        if(mode === "adding")
        {
          this.props.handlerAdded();
        }
      }
    ) 
  }

  private nonCtrAddHandler():void{
    this.setState({
      targetListRefresh : true,
    });
  }

  public componentDidUpdate(previousProps: IDetailsListDocumentsProps, previousState: IDetailsListDocumentsState) {
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
        //refresh when change project
        if (previousProps.currentProject !== this.props.currentProject) {
          //alert(previousProps.currentProject);
          this.loadDataWithCallback();
        }
        if (previousProps.currentUserInProject !== this.props.currentUserInProject) {
          //alert(previousProps.currentProject);
          this.loadDataWithCallback();
        }

                //refresh when change deleted property
                if (previousProps.deleted === true && this.props.deleted === false) {
                  //alert(previousProps.currentProject);
                  this.loadDataWithCallback();
                }

                //refresh when change added property
                if (previousProps.added === true && this.props.added === false )
                {
                  this.loadDataWithCallback();
                }

        if (previousProps.deleted === false && this.props.deleted === true )
        {
          //alert('call delete function here');
          if (this._selection.getSelection().length > 0)
          {
            let id = (this._selection.getSelection()[0] as IDocument).key;
            var y : number  = +id;
            let request = {
            projectNo:this.props.currentProject,
            fileId:y
          }
          axios.post('/api/v1/fileDelete',request).then((res) => {
           // alert(JSON.stringify(res.data.body));

          // let web = Web(this.props.currentSite + "/" + this.props.currentProject);
          // let list = web.lists.getByTitle("NonProject Documents");
          // list.items.getById(y).recycle().then(():void => {
            this.props.handlerDeleted();
          // });
          })
        }
          else
          {
          this.props.handlerDeleted();
          }
        }

        if (previousProps.added === false && this.props.added === true )
        {
          this.props.handlerAdded();
          //this.loadDataWithCallback("adding");
        }
        if (previousProps.downloaded === false && this.props.downloaded === true)
        {
          if (this._selection.getSelection().length === 0)
          {
            alert('Please select your items');
          }
          else if (this._selection.getSelection().length === 1)
          {
            this._downloadSelectedItems();
          }
          else if (this._selection.getSelection().length > 1)
          {
          this._downloadAndZipSelectedItems();
          }
        }

        // if (previousState.targetListRefresh === false && this.state.targetListRefresh === true)
        // {
        //   this.componentDidMount();
        // }

  }

  private _downloadSelectedItems() : void
  {
    if (this._selection.getSelection().length > 0)
    {
      this.setState({
        isBusy : true,
        showProgress:true,
      });

      var config = {
        onDownloadProgress: (progressEvent: { loaded: number; total: number; }) => {
            console.log(progressEvent)
            let { progressPercent } = this.state;
            this.setState({
            progressPercent : Math.round((progressEvent.loaded * 100) / progressEvent.total),
            progressDescription: `${Math.round(progressEvent.loaded * 100)/100} %`
          });
        },};

       let fileName = (this._selection.getSelection()[0] as IDocument).name;
       axios.get(`/api/v1/singleFileDownload?libraryName=NonProject%20DOcuments&fileName=${fileName}&projectNo=${this.props.currentProject}`,
       //{responseType: 'blob',})
       config,)
       .then((res)=>{
         //Buffer.from(res.data, 'binary').toString('base64');
         //this.props.handlerDownload();
         const url = window.URL.createObjectURL(new Blob([res.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `${fileName}`); //or any other extension
         document.body.appendChild(link);
         link.click();
         this.setState({
          isBusy : false,
          showProgress:false,
        });
         this.props.handlerDownload();
        })};
    
    // let web = Web(this.props.currentSite + "/" + this.props.currentProject);
    // let url = this.props.currentSite + "/" + this.props.currentProject + "/" +"NonProject%20DOcuments"+"/" + fileName;
    // let file = web.getFileByUrl(url)

    // var link = document.createElement("a");
    // // If you don't know the name or want to use
    // // the webserver default set name = ''
    // link.setAttribute('download', fileName);
    // link.href = url;
    // document.body.appendChild(link);
    // link.click();
    // link.remove();
    // this.props.handlerDownload();
  }

  private _downloadAndZipSelectedItems() : void
  {

      var zipFilename = `${this.props.currentProject}_NonContrlDocs.zip`;		
    var zip = new JSZip();
    var allPromise = [];
    if (this._selection.getSelection().length > 0)
    {
      this.setState({
        isBusy : true,
      });
      let countFile = 0;
      for (var i = 0; i < this._selection.getSelection().length; i++)
      {
        let fileName = (this._selection.getSelection()[i] as IDocument).name;
        let c_promise = new Promise((resolve, reject)=>{}); 
        allPromise.push(c_promise);
        axios.get(`/api/v1/singleFileDownload?libraryName=NonProject%20DOcuments&fileName=${fileName}&projectNo=${this.props.currentProject}`,{responseType: 'blob',},)
        .then((res)=>{
          countFile ++;
         zip.file(fileName, res.data, {binary:true});
         if  (countFile === this._selection.getSelection().length)
         {
          zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, zipFilename);  
          }).then( ()=>
          {
            this.setState({
              isBusy : false,
            });
             this.props.handlerDownload();
          });
         }
        });
      }
    }
  }


  // private _downloadAndZipSelectedItems() : void
  // {
  //   var urls:string[] = [];
  //   var fileNames:string[] = [];
  //   if (this._selection.getSelection().length > 0)
  //   {
  //     this._selection.getSelection().forEach(element => {
  //       let fileName = (element as IDocument).name;
  //       let url = this.props.currentSite + "/" + this.props.currentProject + "/" +"NonProject%20DOcuments"+"/" + fileName;
  //       urls.push(url);
  //       fileNames.push(fileName);
  //     });
  //     this._downloadZipFile(urls,fileNames,"NonControlDocuments").then(
  //       ()=>{
  //         this.props.handlerDownload();
  //       });
  //   }
  // }

  /*ZipFile*/
// private async _downloadZipFile(urls:string[], fileNames:string[], zipFileName:string) 
// {
// 	var zip = new JSZip();
// 	var count = 0;
// 	if(zipFileName !== ""){
// 		zipFileName = zipFileName+".zip";
// 	}	
// 	else{
// 		zipFileName = "zipFilename.zip";		
// 	}
// 	//var urls = [];
	
// 	urls.forEach(function(url){
// 	  	// loading a file and add it in a zip file
// 		JSZipUtils.getBinaryContent(url, function (err: any, data: string | Blob | number[] | Uint8Array | ArrayBuffer | NodeJS.ReadableStream | Promise<string | Blob | number[] | Uint8Array | ArrayBuffer | NodeJS.ReadableStream>) {
// 			if(err) {
// 				throw err; // or handle the error
// 			}
// 			var filename = fileNames[count];
// 		 	zip.file(filename, data, {binary:true});
// 		 	count++;
// 		 	if (count === urls.length) {
// 		   		zip.generateAsync({type:'blob'}).then(function(content) {
// 		      		saveAs(content, zipFileName);
//               //complete(data);
              
// 		   		});
// 			}
// 		});
// 	});
// }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _searchApply(keystring:string) : void {
    //let oItems = this.state.items;
    this.setState({
      items:keystring ? this.state.items.filter(i => i.name.toLowerCase().indexOf(keystring.toLowerCase()) > -1) : this.state.items,
    });
  }

  private _onChangeCompactMode = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    this.setState({ isCompactMode: checked });
  }

  private _onChangeModalSelection = (ev: React.MouseEvent<HTMLElement>, checked: boolean): void => {
    this.setState({ isModalSelection: checked });
  }

  private _onChangeText = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, text: string): void => {
    this.setState({
      items: text ? this._allItems.filter(i => i.name.toLowerCase().indexOf(text) > -1) : this._allItems,
    });
  }

  private _onItemInvoked(item: any): void {
    alert(`Item invoked: ${item.name}`);
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();

    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IDocument).name;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  }

  private async getNonControlDocuments(){

    //remove hardcode later
    let web = Web("https://apyokogawa.sharepoint.com/sites/EDMSV1/20191002EDMS116");
    //let list = await web.lists.getByTitle("NonProject Documents").items.select("Title", "Lookup/Title", "Lookup/ID").expand("Lookup").get();
    let list = await web.lists.getByTitle("NonProject Documents").items.select("Id","Title","Document_x0020_Category","Modified","Editor","File").expand("Editor","File").get();//.filter(`ModifiedBy/EmpNo eq 'remala.abhilash'`)
    this.setState({jsonResponse:list});  
    console.log(list);
  }

}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

// function _generateDocuments() {
//   const items: IDocument[] = [];
//   for (let i = 0; i < 500; i++) {
//     const randomDate = _randomDate(new Date(2012, 0, 1), new Date());
//     const randomFileSize = _randomFileSize();
//     const randomFileType = _randomFileIcon();
//     let fileName = _lorem(2);
//     fileName = fileName.charAt(0).toUpperCase() + fileName.slice(1).concat(`.${randomFileType.docType}`);
//     let userName = _lorem(2);
//     userName = userName
//       .split(' ')
//       .map((name: string) => name.charAt(0).toUpperCase() + name.slice(1))
//       .join(' ');
//     items.push({
//       key: i.toString(),
//       name: fileName,
//       value: fileName,
//       iconName: randomFileType.url,
//       fileType: randomFileType.docType,
//       modifiedBy: userName,
//       dateModified: randomDate.dateFormatted,
//       dateModifiedValue: randomDate.value,
//       //fileSize: randomFileSize.value,
//       //fileSizeRaw: randomFileSize.rawSize,
//     });
//   }
//   return items;
// }

// function _randomDate(start: Date, end: Date): { value: number; dateFormatted: string } {
//   const date: Date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
//   return {
//     value: date.valueOf(),
//     dateFormatted: date.toLocaleDateString(),
//   };
// }

// const FILE_ICONS: { name: string }[] = [
//   { name: 'accdb' },
//   { name: 'audio' },
//   { name: 'code' },
//   { name: 'csv' },
//   { name: 'docx' },
//   { name: 'dotx' },
//   { name: 'mpp' },
//   { name: 'mpt' },
//   { name: 'model' },
//   { name: 'one' },
//   { name: 'onetoc' },
//   { name: 'potx' },
//   { name: 'ppsx' },
//   { name: 'pdf' },
//   { name: 'photo' },
//   { name: 'pptx' },
//   { name: 'presentation' },
//   { name: 'potx' },
//   { name: 'pub' },
//   { name: 'rtf' },
//   { name: 'spreadsheet' },
//   { name: 'txt' },
//   { name: 'vector' },
//   { name: 'vsdx' },
//   { name: 'vssx' },
//   { name: 'vstx' },
//   { name: 'xlsx' },
//   { name: 'xltx' },
//   { name: 'xsn' },
// ];

function _randomFileIcon(docType:string = 'docx'): { docType: string; url: string } {
  //const docType: string = FILE_ICONS[Math.floor(Math.random() * FILE_ICONS.length)].name;
  return {
    docType,
    url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType.toLowerCase()}.svg`,
  };
}

// function _randomFileSize(): { value: string; rawSize: number } {
//   const fileSize: number = Math.floor(Math.random() * 100) + 30;
//   return {
//     value: `${fileSize} KB`,
//     rawSize: fileSize,
//   };
// }



// const LOREM_IPSUM = (
//   'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut ' +
//   'labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut ' +
//   'aliquip ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ' +
//   'eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt '
// ).split(' ');
// let loremIndex = 0;
// function _lorem(wordCount: number): string {
//   const startIndex = loremIndex + wordCount > LOREM_IPSUM.length ? 0 : loremIndex;
//   loremIndex = startIndex + wordCount;
//   return LOREM_IPSUM.slice(startIndex, loremIndex).join(' ');
// }
