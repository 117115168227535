import * as React from 'react';
import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { IStackProps, IStackStyles, Label, Overlay, Spinner, SpinnerSize, Stack, TextField } from 'office-ui-fabric-react';
import { IPdmsUser } from '../IPdmsUser';
//import { ActivityItem, Icon, Link} from 'office-ui-fabric-react';
//import { sp, Web } from '@pnp/sp/presets/all';
//import pnp from 'sp-pnp-js';
//import PnpFileUpload from './FileUploadReact';
import axios from 'axios';

const buttonStyles = { root: { marginRight: 8 } };
const stackTokens = { childrenGap: 50 };
//const stackStyles: Partial<IStackStyles> = { root: { width: 650 } };
const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
};

var dcEmails = '';

export interface IMDLAssignmentCommentProps {
    siteUrl: string;
    projectNo: string;
    //transmittalItemId:string;
    mdlAssignmentId: string;
    mdlId?: string;
    context?: any | null;
    token?: string;
    currentProjectUser: IPdmsUser;
    handleAdd?: any;
    commentMode: string;
    revNo: string;
    documentNo: string;
}

interface IMDLAssignmentCommentState {
    targetListRefresh: boolean;
    added: boolean;
    currentDate: string;
    //traID:string;
    mdlAssignmentId: string;
    isBusy?: boolean;
}


export default class PanelMDLAssignment extends React.Component<IMDLAssignmentCommentProps, IMDLAssignmentCommentState> {

    constructor(props: IMDLAssignmentCommentProps | Readonly<IMDLAssignmentCommentProps>) {
        var today = new Date(),
            date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
        super(props);
        // sp.setup({
        //   spfxContext: this.props.context
        // });  
        this.state = {
            targetListRefresh: false,
            added: false,
            currentDate: date,
            mdlAssignmentId: this.props.mdlAssignmentId,
            isBusy: false,
        };
        this.traAddHandler = this.traAddHandler.bind(this);
    }

    private traAddHandler(): void {
        this.setState({
            targetListRefresh: true,
            added: true,
        });
    }

    public render(): React.ReactElement<IMDLAssignmentCommentProps> {
        let userName = this.props.currentProjectUser ? this.props.currentProjectUser.name : "";
        return (
            <Stack horizontal tokens={stackTokens} >
                <Stack {...columnProps}>
                    <Label>Category: </Label> <Label>MDL Assignment</Label>
                    <TextField id="txtSub" label="Subject: " required></TextField>
                    <TextField id="txtComment" label="Comment: " multiline autoAdjustHeight />
                    <Label id="txtDate" htmlFor="commentDate">Comment Date: {this.state.currentDate}</Label>
                    <Label id="txtCommentBy">Comment By: {userName}</Label>
                    <Label>Attachment: </Label>
                    <Label id="txtNotes">Please rename the filename to {this.props.documentNo}_{this.props.revNo}</Label>
                    {/* <DefaultButton content="Choose File" /> */}
                    {/* <input id="txtfile" type="file"/> */}
                    <form encType="multipart/form-data" id="myForm">
                        <input type="file" name="myFile" id="txtfile"></input>
                    </form>
                    {/* <input ref={this.fileInputRef} type="file" hidden onChange={this.fileChange}/> */}
                    <div>
                        <PrimaryButton onClick={() => this._addComment(this.props) } id="btnSave" styles={buttonStyles}>Save</PrimaryButton>
                        { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
                        {/* <DefaultButton onClick={() =>close()}>Cancel</DefaultButton>   */}
                    </div>
                </Stack>
            </Stack>
        );

    }


    componentDidMount() {
        axios.get(`/api/v1/projectUser/getByRole?projectNo=${this.props.projectNo}&role=DC`).then((response) => {

            let _temailId = "";
            response.data.body.forEach((item: { Email: string; }) => {
                _temailId += item.Email + ";"
            });
            dcEmails = _temailId;
        })
    }

    private _sendNotification(oRequest:any,dcEmails:any)
    {
      let cRequest = {
          mdlAssignId: oRequest.assignmentId,
          projectNo: oRequest.projectNo,
          commentId: oRequest.commentId,
          dcEmails: dcEmails,
      }
      axios.post(`/api/v1/mdls/commentNotification`, cRequest).then(() => {
          this.props.handleAdd();
          this.setState({
              isBusy: false,
          })
      }).catch((ex) => {
          alert("Comment added but notification failed.")
          this.props.handleAdd();
          this.setState({
              isBusy: false,
          })
      });
      this.props.handleAdd();
    }

    private _addComment(traProps: Readonly<IMDLAssignmentCommentProps> & Readonly<{ children?: React.ReactNode; }>) {

        //alert(traProps.projectNo);
        if ((document.querySelector("#txtfile") as HTMLInputElement).files!.length > 0)
        {
            //upload the file as the attachment
            let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];
            let fname = myfile.name.substring(0, myfile.name.lastIndexOf('.'));
            if (fname !== this.props.documentNo + "_" + this.props.revNo) {
                alert("Invalid file name");
                return 0;
            }
        }

        let subject = (document.querySelector("#txtSub") as HTMLInputElement).value;
        let detail = (document.querySelector("#txtComment") as HTMLInputElement).value;
        let currentUserId = this.props.currentProjectUser.key;
        let request = {
            projectNo: this.props.projectNo,
            title: subject,
            mdlAssignId: this.props.mdlAssignmentId,
            detail: detail,
            currentUserId: currentUserId,
            mdlId: this.props.mdlId,
        };
        if (subject !== "") {
            if (this.state.isBusy === false) {
                this.setState({
                    isBusy: true,
                })
            }
            let particalUrl = "mdls";
            axios.post(`/api/v1/${particalUrl}/addComment`, request).then((d) => {
                console.log(d);
                let commentId = d.data.body.data.Id;
                if ((document.querySelector("#txtfile") as HTMLInputElement).files!.length > 0)
            {
                //upload the file as the attachment
                let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];
                let fname = myfile.name.substring(0, myfile.name.lastIndexOf('.'));
                let fileType = myfile.name.replace(fname, "");
                let newFIleName = `${fname}-${commentId}${fileType}`;
                let myForm = document.getElementById('myForm') as HTMLFormElement;
                let formData = new FormData(myForm);
                let mdlIdRev = `${this.props.mdlId + '_' + this.props.revNo}_${commentId}${fileType}`;
                axios.post(`/api/v1/fileUpload?filename=${encodeURIComponent(newFIleName)}&projectno=${this.props.projectNo}&mdlId=${mdlIdRev}&libraryname=Vendor%20Documents`, formData).then((f) => {
                    //debugger;
                    console.log(`f=${f}`);
                    let attachmentId = f.data.fileId;
                    //update the metadata for attachment
                    // let commentId = req.body.commentId;
                    // let vDocId = req.body.vDocId;
                    // let projectNo = req.body.projectNo;
                    // let revNo = req.body.revNo;
                    let oRequest = {
                        commentId: commentId,
                        vDocId: attachmentId,
                        projectNo: this.props.projectNo,
                        revNo: this.props.revNo,
                        mdlId: this.props.mdlId!,
                        assignmentId:this.props.mdlAssignmentId,
                      };
                      if (attachmentId !== 0)
                      {                   
                          axios.post(`/api/v1/mdls/updateVendorDocument`, oRequest).then(() => {
                            this._sendNotification(oRequest,dcEmails);
                          }); 
                      }
                      else
                      {
                        this._sendNotification(oRequest,dcEmails);
                      }
            });
        }
        else {
            alert('Comment Added');
            this.props.handleAdd();
            this.setState({
                isBusy: false,
            })
        }
    })
}
    else{
    alert("Subject field mandatory")
}

    
  }
  private _fileUpload(commentId: any){
    // let myfile = (document.querySelector("#txtfile") as HTMLInputElement).files![0];  
    // var rUrl = this.props.context.pageContext.web.serverRelativeUrl + "/" + this.props.projectNo + "/Attachments";
    // let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
    // web.getFolderByServerRelativeUrl(rUrl).files.add(myfile.name, myfile, true).
    //   then((result)=> {        
    //     alert(myfile.name + " upload successfully!");
    //     console.log("File Uploaded");
    //     result.file.listItemAllFields.get().then((listItemAllFields) => {
    //       // get the item id of the file and then update the columns(properties)
    //       web.lists.getByTitle("Attachments").items.getById(listItemAllFields.Id).update({
    //         Item_x0020_Bind_x0020_ID: commentId,//this.state.mdlAssignmentId,
    //         //Description: "Here is a new description"
    //       }).then(r=>{
    //         console.log(myfile.name + " properties updated successfully!");
    //       });
    //     });
    // });
}  
}


