import * as React from 'react';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

import "@pnp/sp/files";
import "@pnp/sp/folders";
import  JSZip from 'jszip';

import {saveAs} from 'file-saver';
import { SearchBox, ISearchBoxStyles } from 'office-ui-fabric-react/lib/SearchBox';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IPdmsUser } from './IPdmsUser';
import { CommandBar, Dropdown, ICommandBarItemProps, IDropdownOption, IDropdownStyles, Overlay, Stack } from 'office-ui-fabric-react';
import { DateHelper } from './DateHelper';


const spinnerStyles= {
  circle: {
    height: 25,
    width: 25,
    borderWidth: 4
  }
}

const controlWrapperClass = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const searchWrapperClass = mergeStyles({
  float:'right',
  margin: '10px',
  display:'block',
});

const tabTableWrapper = mergeStyles({
  width:'100%',
  overflowY:'scroll',
  overflowX:'scroll',
});

const detailsListWrapper = mergeStyles({
  width: '100% !important',
  margin:'0px',
  overflowY:'auto',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const _items: ICommandBarItemProps[] = [
    {
      key: 'download',
      text: 'Download',
      iconProps: { iconName: 'Download' },
      onClick: () => {
        let element: HTMLElement = document.getElementById("btnDownloadDistributions") as HTMLElement;
        element.click();
      },
    },
  ];

const _overflowItems: ICommandBarItemProps[] = [];
const _farItems: ICommandBarItemProps[] = [];
const searchBoxStyles: Partial<ISearchBoxStyles> = { 
  root: { width: 200,float:'right' } 
};

const url = require('url');
const querystring = require('querystring');
 let rawUrl = window.location.href;
 let parsedUrl = url.parse(rawUrl);
 let parsedQs = querystring.parse(parsedUrl.query);

console.log(parsedQs.distributionNo);
//DistributionNo={4}&pageId=project-list&websiteId=2&tabId=Distributiondocs
let distributionNo = parsedQs.distributionNo;

export interface IDetailsListDistributionsState {
  status: string;
  columns: IColumn[];
  items: IDocument[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
  jsonResponse?:any; //json object to hold response from pnp js methods 
  downloading:boolean;
  targetListRefresh:boolean; 
  isBusy?:boolean;
  distributionNo?:string;
  distributions?: IDistribution[];
}

export interface IDetailsListDistributionsProps {
  currentSite:string,
  currentProject:string,
  downloaded:boolean,
  handlerDownload:any,
  currentUserInProject?:IPdmsUser;
}

export interface IDocument {
  key: string;
  name: string;
  value: string;
  iconName: string;
  fileType: string;
  modifiedBy: string;
  dateModified: string;
  dateModifiedValue: number;
  documentType: string;
  //IsControlDoc:boolean;
  fileSize: string;
  fileSizeValue:number;
  ServerRelativeUrl:string;
}

export interface IDistribution
{  
    distributionNo?: string;
    Id?: number; 
}
const hideStyle = {display : 'none'};
var docsNonContorlBeforeSeach: any[] = [];
export class DetailsListDistributions extends React.Component<IDetailsListDistributionsProps, IDetailsListDistributionsState> {
  private _selection: Selection;
  private _allItems: IDocument[];

  componentDidMount() {
    if (this.props.currentUserInProject !== undefined)
    {
      this.loadDataWithCallback();
      this.loadDistributions();
    } 
  }

  private keyChanged(option: { key: any; }):void{
    // distributionNo level for current user should be init at here:
    this.setState(
    {
      status:"current distribution changed ...",
      distributionNo: option.key,
      isBusy:true,
    });              
  }
  
  constructor(props: IDetailsListDistributionsProps) {
    super(props);
    this._allItems = [];
    const columns: IColumn[] = [
      // {
      //   key: 'column0',
      //   name: 'ServerRelativeUrl',
      //   iconName: 'Page',
      //   isIconOnly: true,
      //   fieldName: 'name',
      //   minWidth: 16,
      //   maxWidth: 16,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item1: IDocument) => {
      //     return <span>{item1.ServerRelativeUrl}</span>;
      //     //return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
      //   },
      // },
      {
        key: 'column0',
        name: 'File Type',
        className: classNames.fileIconCell,
        iconClassName: classNames.fileIconHeaderIcon,
        ariaLabel: 'Column operations for File type, Press to sort on File type',
        iconName: 'Page',
        isIconOnly: true,
        fieldName: 'name',
        minWidth: 16,
        maxWidth: 16,
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
        },
      },
      {
        key: 'column1',
        name: 'Document Title',
        fieldName: 'documentTitle',
        minWidth: 190,
        maxWidth: 320,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'File Size',
        fieldName: 'fileSizeValue',
        minWidth: 50,
        maxWidth: 100,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        onColumnClick: this._onColumnClick,
        data: 'number',
        onRender: (item: IDocument) => {
          return <span>{item.fileSize}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Revision No',
        fieldName: 'revisionNo',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        // onRender: (item1: IDocument) => {
        //   return <span>{item1.revisionNo}</span>;
        // },
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Customer Version No',
        fieldName: 'customerVersionNo',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        // onRender: (item1: IDocument) => {
        //   return <span>{item1.customerVersionNo}</span>;
        // },
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Document Type',
        fieldName: 'documentType',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        onRender: (item: IDocument) => {
          return <span>{item.documentType}</span>;
        },
        isPadded: true,
      },
      {
        key: 'column6',
        name: 'Number of Pages',
        fieldName: 'numberOfPages',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        // onRender: (item1: IDocument) => {
        //   return <span>{item1.modifiedBy}</span>;
        // },
        isPadded: true,
      },
      {
        key: 'column7',
        name: 'Modified Date',
        fieldName: 'modifiedDate',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        // onRender: (item1: IDocument) => {
        //   return <span>{item1.modifiedBy}</span>;
        // },
        isPadded: true,
      },
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        let element: HTMLElement = document.getElementById("btnDownloadDistributions") as HTMLElement;
        if (this._selection.getSelection().length > 0)
        {
          element.textContent= (this._selection.getSelection()[0] as IDocument).key;
          this.setState({
            selectionDetails: this._getSelectionDetails(),
          });
        }
      },
    });
    
    this.state = {
      status: 'Ready',
      items: this._allItems,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: true,
      isCompactMode: false,
      announcedMessage: undefined,
      targetListRefresh:false,
      downloading:false,
      isBusy:false,
      distributions:[],
      distributionNo: distributionNo,
    };
    this.keyChanged = this.keyChanged.bind(this);
  }

  private renderOptions () : IDropdownOption[] {
    
    var results : IDropdownOption[] = [];
    // if (this.state.currentProject == null)
    // {
    //   return results;
    // }
    if(this.state.distributionNo?.length !== 0){
      for(let i=0; i<this.state.distributions!.length; i++){
        let result:IDropdownOption = {
          key:this.state.distributions![i].distributionNo!,
          text:this.state.distributions![i].distributionNo!
        };
        results.push(result);
      }
    }
    return results;
  }

  public render() {
    const { columns, isCompactMode, items, selectionDetails, isModalSelection, announcedMessage } = this.state;
    return (
      <div className={tabTableWrapper}>
        <table className={detailsListWrapper}>
          <tbody>
            <tr>
              <td>
                <div>
                  <CommandBar
                    items={_items}
                    overflowItems={_overflowItems}
                    //overflowButtonProps={overflowProps}
                    farItems={_farItems}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                  />
                </div>
              </td>          
            <td>
              <div className={searchWrapperClass}>
                <SearchBox
                  styles={searchBoxStyles}
                  placeholder="Search"
                  onEscape={ev => { console.log('Custom onEscape Called'); }}
                  onClear={ev => { this.setState({items:docsNonContorlBeforeSeach}); }}
                  onSearch={_sKey => this._searchApply(_sKey)}
                /> 
              </div>
            </td>
          </tr>
          <tr>
            <td>
            <Stack>
              <Dropdown defaultSelectedKey={this.state.distributionNo}
                placeholder={this.state.isBusy ? "Please wait ..." : "Please choose your Distribution No"}
                options={this.renderOptions()}
                onChanged={this.keyChanged} 
                styles={dropdownStyles}
              />
            { this.state.isBusy ? <Spinner styles={spinnerStyles}/> : null }
            </Stack>
            </td>
          </tr>
          <tr>
            <td><Announced message={`Number of items after filter applied: ${items.length} in ${this.props.currentProject}.`} /></td>
          </tr>
          {/* </div> */}
          <tr>
            <td colSpan={2}>
              <div className={classNames.selectionDetails}>{selectionDetails}
                <Announced message={selectionDetails} />
                {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
              </div>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>{ (this.state.isBusy) ? <Overlay><Spinner styles={spinnerStyles}></Spinner ></Overlay> : null }</td>
          </tr>
          <tr>
            <td colSpan={2}>
              {isModalSelection ? (
              <MarqueeSelection selection={this._selection}>
            <DetailsList
              items={items}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
            />
          </MarqueeSelection>) : (
          <DetailsList
            items={items}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={this._getKey}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        )}
        </td>
        </tr>
        {/* </Fabric> */}
        </tbody>
        </table>
      </div>
    );
  }

  private loadDistributions(){
    if (this.props.currentProject === null || this.props.currentProject === undefined || this.props.currentUserInProject === undefined)
      return;
    axios.get(`/api/v1/distributions/numbers?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject.key}`).then((res) => {
      const response = res.data;
      let _distributions = response.body.map((xx:{Title: string,Id:number})=>{
      let dist : IDistribution = {
      };
      dist.distributionNo = xx.Title;
      dist.Id = xx.Id;
      return dist;
    });
    this.setState({ 
      distributions:_distributions,
      isBusy:false,
      distributionNo: distributionNo,
      //currentProject:projectNoVal, 
      //tratabId: tratabId,
    });
    }).catch(err => {
      console.log("err:" + JSON.stringify(err))
    }); 
  }

  private loadDataWithCallback(mode:string = ""){
    if (this.props.currentProject === null || this.props.currentProject === undefined || this.props.currentUserInProject === undefined)
      return;

    if (this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      });
    } 
    
    axios.get(`/api/v1/distributions/forUser?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject?.key}&distributionNo=${this.state.distributionNo}`).then(
      (response) => { 
        let _allItems: any[] = [];         
        //if(response1.data.results.length>0){
        //let item1 = response1.data.results[0];   
        
        response.data.results.forEach((item1: {DistriId:string; DocumentTitle:string; RevisionNo:string; CustomerVersionNo: string | null | undefined; DocumentType: any; NumberOfPages: any; ModifiedDate: any; IsControlDoc:boolean; ServerRelativeUrl:string;File: { Name: any; Length:any } }) =>{
        //response1.data.results((item1: { RevisionNo:string; CustomerVersionNo: string | null | undefined; DocumentType: any; NumberOfPages: any; ModifiedDate: any;}) =>{
      _allItems.push({
        //key: item1.DistriId,
        name: item1.DocumentTitle === null ? "" : item1.DocumentTitle,
        //iconName : item.Document_x0020_Type === null ? "" : _randomFileIcon(item.Document_x0020_Type).url,
        //value: item.ID,
        documentTitle: item1.DocumentTitle,
        revisionNo:item1.RevisionNo,
        customerVersionNo:item1.CustomerVersionNo,
        documentType:item1.DocumentType,
        numberOfPages:item1.NumberOfPages,
        modifiedDate: item1.ModifiedDate === undefined ? "" : DateHelper.FormatDateToLongDate(item1.ModifiedDate),
        IsControlDoc:item1.IsControlDoc,
        ServerRelativeUrl:item1.ServerRelativeUrl,
        DistriId:item1.DistriId,
        fileSize: item1 == null ? "0 kb" : `${Math.round(item1.File?.Length/1024)} kb`,
        fileSizeValue: (item1.File?.Length/10240).valueOf(),
        //iconName : item.Document_x0020_Type === null ? "" : _randomFileIcon(item.Document_x0020_Type).url,
        //dateModified: DateHelper.FormatDateToLongDate(item.Modified),
        //dateModifiedValue: item.Modified.valueOf(),
      });
    //}
    });
    this.setState({
      items:_allItems,
      isBusy:false,
    });
      docsNonContorlBeforeSeach = _allItems;
    }); 
    
    //api/v1/distributions/forUser?projectNo=TestDIV3&userId=95&distributionNo=TestDIV3/DST-017
    // axios.get(`/api/v1/distributions/numbers?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject?.key}`)
    // .then(
    //   (response) => {
    //     let _allItems: any[] = [];
    //       response.data.body.forEach((item: { ID: { toString: () => any; }; File: { Name: any; } | null; Title:string; Document_x0020_Type: string | null | undefined; ModifiedBy: any; Modified: any; }) =>{
    //         // _allItems.push({
    //         //   key: item.ID.toString(),
    //         //   name: item.File === null ? "" : item.Title,
    //         //   value: item.ID,
    //         //   documentTitle: item.Title,
    //         // });
    //     //response.data.body.forEach((item: {Title:string;}) =>{
    //       //let _allItems: any[] = [];
    //       axios.get(`/api/v1/distributions/forUser?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject?.key}&distributionNo=${this.state.distributionNo}`).then(
    //         (response1) => {              
    //           if(response1.data.results.length>0){
    //           let item1 = response1.data.results[0];              
    //           //response1.data.results((item1: { RevisionNo:string; CustomerVersionNo: string | null | undefined; DocumentType: any; NumberOfPages: any; ModifiedDate: any;}) =>{
    //         _allItems.push({
    //           key: item.ID.toString(),
    //           name: item.File === null ? "" : item.Title,
    //           value: item.ID,
    //           documentTitle: item.Title,
    //           revisionNo:item1.RevisionNo,
    //           customerVersionNo:item1.CustomerVersionNo,
    //           documentType:item1.DocumentType,
    //           numberOfPages:item1.NumberOfPages,
    //           modifiedDate:item1.ModifiedDate,
    //           //iconName : item.Document_x0020_Type === null ? "" : _randomFileIcon(item.Document_x0020_Type).url,
    //           //fileType: item.Document_x0020_Type,              
    //           //modifiedBy: item.ModifiedBy === undefined ? "" : item.ModifiedBy.Title,
    //           //dateModified: DateHelper.FormatDateToLongDate(item.Modified),
    //           //dateModifiedValue: item.Modified.valueOf(),
    //         });
    //       }
    //       //});
    //       this.setState({
    //         items:_allItems,
    //         isBusy:false,
    //       });
    //       docsNonContorlBeforeSeach = _allItems;
    //       });          
    //     });
    //     this.setState({
    //       items:_allItems,
    //       isBusy:false,
    //     });
    //     docsNonContorlBeforeSeach = _allItems;
    //   }
    // ) 
  }

  public componentDidUpdate(previousProps: IDetailsListDistributionsProps, previousState: IDetailsListDistributionsState) {
    if (this.props.currentUserInProject !== undefined && previousState.distributionNo !== this.state.distributionNo) {
      this.loadDataWithCallback();
    }
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
    //refresh when change project
    if (previousProps.currentProject !== this.props.currentProject && this.state.distributionNo !== undefined) {
        //alert(previousProps.currentProject);
        this.loadDataWithCallback();
        this.loadDistributions();
    }
    if (previousProps.currentUserInProject !== this.props.currentUserInProject) {
        //alert(previousProps.currentProject);
        this.loadDataWithCallback();
        this.loadDistributions();
    }               
        
    if (previousProps.downloaded === false && this.props.downloaded === true)
    {
        if (this._selection.getSelection().length === 0)
        {
        alert('Please select your items');
        }
        else if (this._selection.getSelection().length === 1)
        {
        this._downloadSelectedItems();
        }
        else if (this._selection.getSelection().length > 1)
        {
        this._downloadAndZipSelectedItems();
        }
    }
}

  private _downloadSelectedItems() : void
  {
    if (this._selection.getSelection().length > 0)
    {
      if (this.state.isBusy === false)
      {
        this.setState({
          isBusy:true,
        });
      } 
       //let isControl = (this._selection.getSelection()[0] as IDocument).IsControlDoc;
      //if(isControl == true){
        //let srUrl = this.state.selectedDocs[0].serverRelativeUrl;
        //let fileType = srUrl.substring(srUrl.lastIndexOf("."));

        let fileName = (this._selection.getSelection()[0] as IDocument).name;
        let serverRelativeUrl = (this._selection.getSelection()[0] as IDocument).ServerRelativeUrl;
        let docType = serverRelativeUrl.substring(serverRelativeUrl.lastIndexOf("."));
        let DistriId = (this._selection.getSelection()[0] as any).DistriId;

        let userId = this.props.currentUserInProject?.key;
        //let docType = (this._selection.getSelection()[0] as IDocument).documentType
       //axios.get(`/api/v1/singleFileDownload?libraryName=NonProject%20DOcuments&fileName=${fileName}&projectNo=${this.props.currentProject}`,
       axios.get(`/api/v1/singleFileDownload?projectNo=${this.props.currentProject}&serverReltiveURL=${serverRelativeUrl}&userId=${userId}&DistriId=${DistriId}`,
       {responseType: 'blob',})
       .then((res)=>{
         //Buffer.from(res.data, 'binary').toString('base64');
         //this.props.handlerDownload();
         const url = window.URL.createObjectURL(new Blob([res.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `${fileName}${docType}`); //or any other extension
         document.body.appendChild(link);
         link.click();
         this.setState({
          isBusy:false,
        });
         this.props.handlerDownload();
        })};
      //}
    }

  private _downloadAndZipSelectedItems() : void
  {

    var zipFilename = `${this.props.currentProject}_DistributionsDocs.zip`;		
    var zip = new JSZip();
    var allPromise = [];
    if (this._selection.getSelection().length > 0)
    {
      if (this.state.isBusy === false)
      {
        this.setState({
          isBusy:true,
        });
      } 
      let countFile = 0;
      for (var i = 0; i < this._selection.getSelection().length; i++)
      {
        let Name = (this._selection.getSelection()[i] as IDocument).name;
        let DistriId = (this._selection.getSelection()[i] as any).DistriId;
        let userId = this.props.currentUserInProject?.key;
        let serverRelativeUrl = (this._selection.getSelection()[i] as IDocument).ServerRelativeUrl;
        //let docType = (this._selection.getSelection()[i] as IDocument).documentType
        let fileType = serverRelativeUrl.substring(serverRelativeUrl.lastIndexOf("."));

        let fileName = `${Name}${fileType}`;
        let c_promise = new Promise((resolve, reject)=>{}); 
        allPromise.push(c_promise);
        //axios.get(`/api/v1/singleFileDownload?libraryName=NonProject%20DOcuments&fileName=${fileName}&projectNo=${this.props.currentProject}`,
        axios.get(`/api/v1/singleFileDownload?fileName=${fileName}&projectNo=${this.props.currentProject}&serverReltiveURL=${serverRelativeUrl}&userId=${userId}&DistriId=${DistriId}`,
        {responseType: 'blob',})
        .then((res)=>{
          countFile ++;
         zip.file(fileName, res.data, {binary:true});
         if  (countFile === this._selection.getSelection().length)
         {
          zip.generateAsync({type:'blob'}).then(function(content) {
            saveAs(content, zipFilename);  
          }).then( ()=>
          {
            this.setState({
              isBusy:false,
            });
             this.props.handlerDownload();
          });
         }
        });
      }
    }
  }

  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _searchApply(keystring:string) : void {
    //let oItems = this.state.items;
    this.setState({
      items:keystring ? this.state.items.filter(i => i.name.toLowerCase().indexOf(keystring.toLowerCase()) > -1) : this.state.items,
    });
  }

  private _onItemInvoked(item: any): void {
    alert(`Item invoked: ${item.name}`);
  }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IDocument).name;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}