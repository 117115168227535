export class DateHelper
{
    public static Deserialize(data: string): any
    {
        return JSON.parse(data, DateHelper.ReviveDateTime);
    }

    private static ReviveDateTime(key: any, value: any): any 
    {
        if (typeof value === 'string')
        {
            let a = /\/Date\((\d*)\)\//.exec(value);
            if (a)
            {
                return new Date(+a[1]);
            }
        }

        return value;
    }

    public static FormatDateToShortDate(date: string | number | Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    public static FormatDateToLongDate(date: string | number | Date) {
        //please implement this function
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hour = d.getHours(),
            min = d.getMinutes(),
            sec = d.getSeconds();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        let timePart = [hour,min,sec].join(':');    
        let datePart = [year, month, day].join('-');
        
        return [datePart,timePart].join(' ');
    }
}