import * as React from 'react';
import './CrudReact.css';
import {ICrudReactProps} from './ICrudReactProps';
import {ICrudReactState} from  './ICrudReactState';
import * as rt from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
//import * as pnp from 'sp-pnp-js';
import axios from 'axios';


import { Dropdown, IDropdownStyles, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { IStackTokens, Stack } from 'office-ui-fabric-react/lib/Stack';
import styles from './CrudReact.module.scss';
import {IProject} from './IProject';

import { CommandBar,ICommandBarItemProps} from 'office-ui-fabric-react/lib/CommandBar';
import {DetailsListDocumentsExample} from './DetailsListDocumentsExample';
import CustomPanel from './CustomPanel'
//import {IPdmsUser} from './IPdmsUser'
import { BaseButton, Button, DefaultButton,IButtonProps} from 'office-ui-fabric-react/lib/Button';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import DetailsListTransmittal from './DetailsListTransmittal';
import DetailsListNonCtrTransmittal from './NonControlTransmittal/DetailsListNonCtrTransmittal';
import CustomPanelComments from './CustomPanelComments';
import { IPdmsUser } from './IPdmsUser';
// import {OverlayDarkExample} from './Overlay';
import Header from './Header';


import { classNamesFunction, IStyle, Overlay } from 'office-ui-fabric-react';
import { DetailsListDistributions } from './DetailsListDistributions';
import PopSubmitTransmittal from './PopSubmitTransmittal';
import DetailsListTCR from './DetailsListTCR';
import TCRPanel from './TCRPanel';


import {DetailsListMDLAssignment} from './MDLAssignment/DetailsListMDLAssignment';
import CustomPanelMDLComments from './MDLAssignment/CustomPanelMDLComments';
import TCRPanelEdit from './TCRPanelEdit';

// import { IGroup } from 'office-ui-fabric-react';
// import { ITransmittalItem } from './ITransmittalItem';
// export interface ICrudReactState {  
//   status: string;
//   currentProject?:string;
//   projects?: IProject[];

//   deletingNonCtrDocs:boolean;
//   uploadedNonCtrDocs:boolean;
//   downloadNonCtrDocs:boolean;
//   currentUserInProject?:IPdmsUser;
//   currentUserInPDMS?:IPdmsUser;
//   showPanel:boolean;
//   showDailog:boolean;
//   isBusy?:boolean;

//   transmittalitems?:ITransmittalItem[];
//   groups?:IGroup[];
//   transmittalItemId4Comments?:string;
//   mdlAssignmentId4Comments?:string;
//   mdlId4Comments?:string;
//   revisionNo?:string;
//   commentCountingForPanel?:string;

//   downloadDistDocs:boolean;
//   tratabId?:number;

//   transmittalitemsNonCtr?:ITransmittalItem[];
//   groupsNonCtr?:IGroup[];
// }


//#region Overlay
interface IOverlayExampleStyles {
  root: IStyle;
}
const exampleStyles: IOverlayExampleStyles = {
  root: [
    'OverlayExample-content',
    {
      verticalAlign:'middle',
      background: 'blue',
      bottom: '0',
      color: 'white',
      //left: '0',
      padding: '10px',
      margin:'300px',
      //position: 'absolute',
      //right: '0',
    },
  ],
};
const getClassNames = classNamesFunction<{}, IOverlayExampleStyles>();
const classNames = getClassNames(exampleStyles, {});
const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
  };
//#endregion

  //#region command bar
const stackTokens: IStackTokens = { childrenGap: 20 };
const _items: ICommandBarItemProps[] = [
  {
    key: 'upload',
    text: 'Upload',
    iconProps: { iconName: 'Upload' },
    onClick: () => {
      let element: HTMLElement = document.getElementById("btnPanelNonControl") as HTMLElement;
      element.click();
    },
  },
  {
    key: 'delete',
    text: 'Delete',
    iconProps: { iconName: 'Delete' },
    onClick:() =>{
      let element: HTMLElement = document.getElementById("btnDeleteNonControl") as HTMLElement;
      element.click();
    },
  },
  {
    key: 'download',
    text: 'Download',
    iconProps: { iconName: 'Download' },
    onClick: () => {
      let element: HTMLElement = document.getElementById("btnDownloadNonControl") as HTMLElement;
      element.click();
    },
  },
];
const _overflowItems: ICommandBarItemProps[] = [
  // { key: 'move', text: 'Move to...', onClick: () => console.log('Move to'), iconProps: { iconName: 'MoveToFolder' } },
  // { key: 'copy', text: 'Copy to...', onClick: () => console.log('Copy to'), iconProps: { iconName: 'Copy' } },
  // { key: 'rename', text: 'Rename...', onClick: () => console.log('Rename'), iconProps: { iconName: 'Edit' } },
];
// const _farItems: ICommandBarItemProps[] = [
//   {
//     key: 'tile',
//     text: 'Grid view',
//     // This needs an ariaLabel since it's icon-only
//     ariaLabel: 'Grid view',
//     iconOnly: true,
//     iconProps: { iconName: 'Tiles' },
//     onClick: () => console.log('Tiles'),
//   },
//   {
//     key: 'info',
//     text: 'Info',
//     // This needs an ariaLabel since it's icon-only
//     ariaLabel: 'Info',
//     iconOnly: true,
//     iconProps: { iconName: 'Info' },
//     onClick: () => console.log('Info'),
//   },
// ];
const overflowProps: IButtonProps = { ariaLabel: 'More commands' };
const hideStyle = {display : 'none'};
const unhideStyle = {display : 'block'};
//#endregion

const url = require('url');
const querystring = require('querystring');
 let rawUrl = window.location.href;
 let parsedUrl = url.parse(rawUrl);
 let parsedQs = querystring.parse(parsedUrl.query);
 
console.log(parsedQs.ProjectNo); // Gives "ProjectNo"
console.log(parsedQs.tabId); // Gives "ProjectNo"
console.log(parsedQs.summaryId);

let projectNoVal = parsedQs.ProjectNo;
let tratabId:any;

export default class CrudReact extends React.Component<ICrudReactProps, ICrudReactState,{} > {
    //private _options : IDropdownOption[];

    constructor(props: ICrudReactProps) {  
        super(props); 
        if(parsedQs.tabId === "Transmittaldocs"){
          tratabId = 0
        }
        else if (parsedQs.tabId === 'NonCtrlTransmittaldocs')
        {
          tratabId = 1
        } 
        else if (parsedQs.tabId === 'Distributiondocs')
        {
          tratabId = 2
        }
        else if (parsedQs.tabId === 'MDL')
        {
          tratabId = 3
        }
        else if (parsedQs.tabId === 'TCR' || parsedQs.tabId === "TCRdocs")
        {
          tratabId = 4
        }
        else 
        {
          tratabId = 0
        }  
        this.state = { 
          status: 'Ready',
          projects:[],
          showPanel:false,
          tcrshowPanel:false,
          tcrshowEditPanel:false,
          deletingNonCtrDocs:false,
          uploadedNonCtrDocs:false,
          downloadNonCtrDocs:false,
          downloadDistDocs:false,
          showDailog:false,
          addedTCRItems:false,
          currentUserInPDMS:{
            email:this.props.loginEmail!,
            name:'',
            key:'',
            companyShrotName:'',
          },
          isBusy:true,
          tratabId:tratabId,
          dcCompany:'',
          dcEmails:'',
          tcrPanelEditMode:false,
          currentRole:'CUS', //default consider as CUS
        };
        axios.defaults.headers.common['x-access-token'] = this.props.token;
        this.keyChanged = this.keyChanged.bind(this);

        this.nonCtrDeleteHandler = this.nonCtrDeleteHandler.bind(this);
        this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this);
        this.nonCtrAddHandlerDone = this.nonCtrAddHandlerDone.bind(this);
        this.nonCtrDownloadHandler = this.nonCtrDownloadHandler.bind(this);
        this.setPanelNonControlState = this.setPanelNonControlState.bind(this); // used for panel open for non control documents upload
        this.distDownloadHandler = this.distDownloadHandler.bind(this);
        this.traAddHandler = this.traAddHandler.bind(this);
        this.tcrAddHandler = this.tcrAddHandler.bind(this); 
        this.tcrEditHandler = this.tcrEditHandler.bind(this);       
        this.tcrAddHandlerDone = this.tcrAddHandlerDone.bind(this);        
      } 

      setPanelCommentsState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        //alert((e.target as HTMLElement).title);
        this.setState({
          showPanel:!this.state.showPanel,
          transmittalItemId4Comments:(e.target as HTMLElement).textContent!.toString(),
          commentCountingForPanel:(e.target as HTMLElement).title.toString(),
        });
      }
      setPanelMDLAssignCommentsState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        let element: HTMLElement = document.getElementById("btnMDLId") as HTMLElement;
        let element1: HTMLElement = document.getElementById("btnRevision") as HTMLElement;
        let element2: HTMLElement = document.getElementById("btnDocumentNo") as HTMLElement;
        this.setState({
          showPanel:!this.state.showPanel,
          mdlAssignmentId4Comments:(e.target as HTMLElement).textContent!.toString(),
          commentCountingForPanel:(e.target as HTMLElement).title.toString(),
          mdlId4Comments:element.textContent!,
          revisionNo:element1.textContent!,
          documentNo4Comments:element2.textContent!,
        });
      }
      setDailogState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        //alert((e.target as HTMLElement).title);
        this.setState({
          showDailog:!this.state.showDailog,
        });
      }
      
      setPanelNonCtrCommentsState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        //alert((e.target as HTMLElement).title);
        this.setState({
          showPanel:!this.state.showPanel,
          transmittalItemId4Comments:(e.target as HTMLElement).textContent!.toString(),
          commentCountingForPanel:(e.target as HTMLElement).title.toString(),
        });
      }
      setPanelTCRCommentsState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        //alert((e.target as HTMLElement).title);
        this.setState({
          showPanel:!this.state.showPanel,
          transmittalItemId4Comments:(e.target as HTMLElement).textContent!.toString(),
          commentCountingForPanel:(e.target as HTMLElement).title.toString(),
        });
      }
      setPanelTCRCommentsEditState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>)
      {
        //alert((e.target as HTMLElement).title);
        this.setState({
          showPanel:!this.state.showPanel,
          transmittalItemId4Comments:(e.target as HTMLElement).textContent!.toString(),
          commentCountingForPanel:(e.target as HTMLElement).title.toString(),
        });
      }
      setTCRPanelState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void {
        this.setState({
          tcrshowPanel:!this.state.tcrshowPanel,
        });
      }
      setTCRPanelEditState(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | BaseButton | Button | HTMLSpanElement, MouseEvent>): void {
        //alert(parseInt((e.target as HTMLElement).textContent!));
        if((e.target as HTMLElement).textContent !== "0")
        {
          this.setState({
            tcrshowEditPanel:!this.state.tcrshowEditPanel,
            tcrPanelEditMode:true,
            tcrIdSelectedEdit:parseInt((e.target as HTMLElement).textContent!),
          });
        }else{
          alert("Please select one item");
        }
      }
      private keyChanged(option: { key: any; }):void{
        // project level for current user should be init at here: 
        axios.get(`/api/v1/common/dcUsers?projectNo=${option.key}`).then((dcUsers)=>{
          let company = '';
          let emails = '';
          for (var i = 0; i < dcUsers.data.body.length; i ++)
          {
            company = dcUsers.data.body[i].Company? dcUsers.data.body[i].Company.Short_x0020_Name : company;
            emails += dcUsers.data.body[i].Email + ";";
          }
          this.setState({
            dcCompany : company,
            dcEmails: emails,
            status:"current project changed ...",
            currentProject:option.key,
            isBusy:true,
          });
        });             
      }

      private nonCtrDeleteHandler():void{
        this.setState({
          deletingNonCtrDocs:false,
        });
      }
    
      private nonCtrAddHandlerDone():void{
        this.setState({
          uploadedNonCtrDocs:false,
        });
      }
    
      private nonCtrAddHandler():void{
        this.setState({
          uploadedNonCtrDocs:true,
          showPanel:false
        });
      }
      private tcrAddHandlerDone():void{
        this.setState({
          addedTCRItems:false,
        });
      }
      private tcrAddHandler():void{
        this.setState({
          addedTCRItems:true,
          tcrshowPanel:false,
          showPanel:false
        });
      } 
      private tcrEditHandler():void{
        (document.getElementById("btnPanelTCREdit") as HTMLElement).textContent = "0";
        this.setState({
          addedTCRItems:true,
          tcrshowEditPanel:false,
          showPanel:false
        });
      }      

      private traAddHandler():void{
        this.setState({
          showDailog:false
        });
      }
      
  private nonCtrDownloadHandler():void
  {
    this.setState({
      downloadNonCtrDocs:false,
    })
  }

  private distDownloadHandler():void
  {
    this.setState({
      downloadDistDocs:false,
    })
  }  
      
  private setPanelNonControlState():void{
    if (this.state.currentProject != null)
    {
      this.setState({
        showPanel:!this.state.showPanel,
      });
    }else{
      alert('Please choose your project.');
    }
  }
  private setPanelTcrState():void{
    if (this.state.currentProject != null)
    {
      this.setState({
        showPanel:!this.state.showPanel,
      });
    }else{
      alert('Please choose your project.');
    }
  }

  private setPanelTCRState():void{
    if (this.state.currentProject != null)
    {
      this.setState({
        tcrshowPanel:!this.state.tcrshowPanel,
      });
    }
    else{
      alert('Please choose your project.');
    }
  }

  private setDeleteNonControlState():void{
    if (this.state.currentProject == null)
    {
        alert('Please choose your project.');
        return;
    }
    if(!window.confirm('Are you sure you want to delete the latest item?'))
    {
      return;
    }
    else
    {
    this.setState({
      deletingNonCtrDocs:true,
      showPanel:false,
    });}
  }

  private setDownloadNonControlState():void
  {
    this.setState({
      downloadNonCtrDocs:true,
    });
  }

  private setDownloadDistState():void
  {
    this.setState({
      downloadDistDocs:true,
    });
  }  

  componentDidMount()
  {  
    // if(parsedQs.tabId === "Transmittaldocs"){
    //   tratabId = 0
    // }
    // else if (parsedQs.tabId === 'NonCtrlTransmittaldocs')
    // {
    //   tratabId = 1
    // } 
    // else if (parsedQs.tabId === 'Distributiondocs')
    // {
    //   tratabId = 2
    // }
    // else 
    // {
    //   tratabId = 0
    // }     
    axios.get('/api/v1/availableProjects').then((res) => {
      const response = res.data;
        let _projects = response.body.map((xx: string | undefined)=>{
        let prj : IProject = {
        };
        prj.ProjectNo = xx;
        return prj;
      });
      if (projectNoVal)
      {
        console.log(`projectNoVal=${projectNoVal}`);
        axios.get(`/api/v1/common/dcUsers?projectNo=${projectNoVal}`).then((dcUsers)=>{
          let company = '';
          let emails = '';
          for (var i = 0; i < dcUsers.data.body.length; i ++)
          {
            company = dcUsers.data.body[i].Company? dcUsers.data.body[i].Company.Short_x0020_Name : company;
            emails += dcUsers.data.body[i].Email + ";";
          }
          this.setState({
            dcCompany : company,
            dcEmails: emails,
          });
        });  
      }
      this.setState({ 
        projects:_projects,
        isBusy:false,
        currentProject:projectNoVal, 
        //tratabId: tratabId,
      });
    }).catch(err => {
      console.log("err:" + JSON.stringify(err))
    });        
  }

  componentDidUpdate(previousProps:ICrudReactProps, previousState: ICrudReactState)
  {
    if (previousState.currentProject !== this.state.currentProject)
    {
      axios.get(`/api/v1/auth/me?projectNo=${this.state.currentProject}`).then((res) => {
        let user : IPdmsUser = {
          key: res.data.userId.toString(),
          name: res.data.userName,
          email:res.data.email,
          role:res.data.role,
          companyShrotName:res.data.company,
        }
        this.setState({
          currentUserInProject : user,
          currentRole:res.data.role,
          isBusy:false,
        })
      });
    }
  }

  //   componentDidUpdate(previousProps:ICrudReactProps, previousState: ICrudReactState)
  //   {
  //     if (this.state.projects != previousState.projects)
  //     {
  //       console.log("componentDidUpdate");
  //       axios.get('/api/v1/do-something').then((res) => {
  //   const response = res.data;

  //       let _projects = response.body.map((xx: { Title: any; })=>{
  //       let prj : IProject = {
  //       };
  //       prj.ProjectNo = xx.Title;
  //       return prj;
  //     });
  //  this.setState({ 
  //         projects:_projects,
  //     });
  // }).catch(err => 
  //   {
  //     console.log("err:" + JSON.stringify(err))
  //   });
  //     }

  //   }

    private renderOptions () : IDropdownOption[] {
    
        var results : IDropdownOption[] = [];
        // if (this.state.currentProject == null)
        // {
        //   return results;
        // }
        for(let i=0; i<this.state.projects!.length; i++){
          let result:IDropdownOption = {
            key:this.state.projects![i].ProjectNo!,
            text:this.state.projects![i].ProjectNo!
          };
          results.push(result);
        }
        return results;
    }
    
  
    public render(): React.ReactElement<ICrudReactProps> {
      
        return (
          <div className={ styles.crudReact }>
            <div className={ styles.container }>
              {/* <h1>Welcome to PDMS portal!</h1> */}
              <Header currentUser={this.state.currentUserInPDMS} currentUserInProject={this.state.currentUserInProject}></Header>
            <Stack tokens={stackTokens}>
              <Dropdown defaultSelectedKey={this.state.currentProject}
              placeholder={this.state.isBusy ? "Please wait ..." : "Please choose your project"}
              //label="All available projects -- "
              options={this.renderOptions()}
              onChanged={this.keyChanged} 
              styles={dropdownStyles}
              />
            { this.state.isBusy ? <Spinner size={SpinnerSize.small} /> : null }
            </Stack>
    <br></br>
    <div className={styles.reactTabs}>
      {/* <rt.Tabs defaultIndex = {this.state.tratabId}> */}
      <rt.Tabs defaultIndex= {this.state.tratabId!}>
        <rt.TabList>
          {/* <rt.Tab>Title 1--Overlay</rt.Tab> */}
          {/* <rt.Tab>Title 2</rt.Tab> */}
          <div className={styles.reactTab}>
          <rt.Tab>Transmittals for Control Documents</rt.Tab>
          <rt.Tab>Non-Control Documents</rt.Tab>
          <rt.Tab>Distributions</rt.Tab>
          
          { this.state.currentRole === 'VEN' ? <rt.Tab> Master Document List </rt.Tab>:null}
          {/* <rt.Tab> Master Document List </rt.Tab> */}
          <rt.Tab>TCR</rt.Tab>
          {/* <rt.Tab>Non-Control Transmittals</rt.Tab> */}
          {/* <rt.Tab>Test Multer</rt.Tab> */}
          {/* <rt.Tab style={this.state.currentUserInProject? hideStyle : unhideStyle}>Master Document List</rt.Tab> */}
          </div>
          {/* <rt.Tab>PnpSampleDemo</rt.Tab>
          <rt.Tab>Kids Calculate</rt.Tab> */}
        </rt.TabList>
        {/* <rt.TabPanel>
          <OverlayDarkExample></OverlayDarkExample>
        </rt.TabPanel> */}
    
        {/* <rt.TabPanel>
          <div className={ styles.row }>
                <div className={ styles.column }>
        <span className={ styles.title }>Welcome to {escape(this.props.siteUrl)} with project is {this.state.currentProject}</span>
                  <p className={ styles.subTitle }>Customize SharePoint experiences using Web Parts.</p>
        <p className={ styles.description }>{escape(this.props.siteUrl)} {escape(this.state.status)}</p>  
                </div>
              </div>
        </rt.TabPanel> */}
        <rt.TabPanel>        
        <DetailsListTransmittal openPanel = {this.state.showPanel} showDailog={this.state.showDailog} setPanelNonControlState = {this.setPanelNonControlState} description='' siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!} currentUserInProject={this.state.currentUserInProject}></DetailsListTransmittal>
        <CustomPanelComments dcEmails = {this.state.dcEmails} dcCompany={this.state.dcEmails} commentMode='Control' commentCounting = {this.state.commentCountingForPanel} currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.nonCtrAddHandler} isOpen={this.state.showPanel} transmittalItemId = {this.state.transmittalItemId4Comments!} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></CustomPanelComments>
        {/* <PopSubmitTransmittal isOpen={this.state.showDailog} onDissmiss={this.traAddHandler} siteUrl={this.props.siteUrl} currentUserInProject = {this.state.currentUserInProject!} projectNo={this.state.currentProject!}></PopSubmitTransmittal> */}
        {/* <DefaultButton id="btnDailogTrans" secondaryText="Opens the Sample Dialog" onClick={e=> this.setDailogState(e)} text="Open Dialog" /> */}
        
        <DefaultButton style={hideStyle} id='btnPanelTransmittal' onClick={e=> this.setPanelCommentsState(e)} text='panelHide' ></DefaultButton>
        {/* <div className={ styles.row }>
          <div className={ styles.column }>
          <span className={ styles.title }>Panel 3</span>
          </div>
        </div> */}
        
        </rt.TabPanel>
        <rt.TabPanel>
    <CommandBar
      items={_items}
      overflowItems={_overflowItems}
      overflowButtonProps={overflowProps}
      //farItems={_farItems}
      ariaLabel="Use left and right arrow keys to navigate between commands"
    />
    <DetailsListDocumentsExample key='abcde' currentProject={this.state.currentProject!} currentUserInProject = {this.state.currentUserInProject} currentSite={this.props.siteUrl} 
        deleted={this.state.deletingNonCtrDocs} handlerDeleted = {this.nonCtrDeleteHandler}
        added={this.state.uploadedNonCtrDocs} handlerAdded={this.nonCtrAddHandlerDone}
        downloaded={this.state.downloadNonCtrDocs} handlerDownload={this.nonCtrDownloadHandler}></DetailsListDocumentsExample>
    <DefaultButton style={hideStyle} id='btnPanelNonControl' onClick={()=> this.setPanelNonControlState()} text='panelHide' ></DefaultButton>
    <DefaultButton style={hideStyle} id='btnDeleteNonControl' onClick={()=> this.setDeleteNonControlState()} text='deleteHide' ></DefaultButton>
    <DefaultButton style={hideStyle} id='btnDownloadNonControl' onClick={()=> this.setDownloadNonControlState()} text='downloadHide' ></DefaultButton>
    <CustomPanel token={this.props.token} currentUserInProject = {this.state.currentUserInProject} onDissmiss={this.nonCtrAddHandler} isOpen={this.state.showPanel} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></CustomPanel>
    </rt.TabPanel>
    <rt.TabPanel>     
          
      <DetailsListDistributions key='abcde' currentProject={this.state.currentProject!} currentUserInProject = {this.state.currentUserInProject} currentSite={this.props.siteUrl} 
        downloaded={this.state.downloadDistDocs} handlerDownload={this.distDownloadHandler}></DetailsListDistributions>
      <DefaultButton style={hideStyle} id='btnDownloadDistributions' onClick={()=> this.setDownloadDistState()} text='downloadHide' ></DefaultButton>
        
    </rt.TabPanel>



    { this.state.currentRole === 'VEN' ? 
      <rt.TabPanel>    
      <DefaultButton style={hideStyle}  id='btnPanelMDLAssignment' onClick={e=> this.setPanelMDLAssignCommentsState(e)} text='1'></DefaultButton>
          <DetailsListMDLAssignment key='abcdef' currentProject={this.state.currentProject!} currentUserInProject = {this.state.currentUserInProject} currentSite={this.props.siteUrl} 
            downloaded={this.state.downloadDistDocs} handlerDownload={this.distDownloadHandler}></DetailsListMDLAssignment>
          <CustomPanelMDLComments commentMode='' commentCounting = {this.state.commentCountingForPanel} documentNo = {this.state.documentNo4Comments!}
          currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.nonCtrAddHandler} isOpen={this.state.showPanel} 
          mdlAssignmentId = {this.state.mdlAssignmentId4Comments!} mdlId={this.state.mdlId4Comments!} revNo = {this.state.revisionNo!} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></CustomPanelMDLComments>
          
          <DefaultButton style={hideStyle} id ='btnMDLId' text='btnMDLId'></DefaultButton>
          <DefaultButton style={hideStyle} id ='btnRevision' text='btnRevision'></DefaultButton>
          <DefaultButton style={hideStyle} id ='btnDocumentNo' text='btnDocumentNo'></DefaultButton>
          
        </rt.TabPanel>  :null}
      <rt.TabPanel> 
      <DetailsListTCR dcCompany={this.state.dcCompany} dcEmails={this.state.dcEmails} description='' added={this.state.addedTCRItems} handlerAdded={this.tcrAddHandlerDone} openPanel = {this.state.showPanel} openTcrPanel = {this.state.tcrshowPanel} setAddPanelTCRState = {this.setPanelTCRState} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!} currentUserInProject={this.state.currentUserInProject}></DetailsListTCR>
      <TCRPanel dcCompany={this.state.dcCompany} dcEmails={this.state.dcEmails} commentMode='Tcr'  currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.tcrAddHandler} isOpen={this.state.tcrshowPanel} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></TCRPanel>
      <TCRPanelEdit refTcrId={this.state.tcrIdSelectedEdit}  dcCompany={this.state.dcCompany} dcEmails={this.state.dcEmails} commentMode='Tcr'  currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.tcrEditHandler} isOpen={this.state.tcrshowEditPanel} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></TCRPanelEdit>
      <CustomPanelComments dcCompany={this.state.dcCompany} dcEmails={this.state.dcEmails} commentMode='TcrComment' commentCounting = {this.state.commentCountingForPanel} currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.tcrAddHandler} isOpen={this.state.showPanel} transmittalItemId = {this.state.transmittalItemId4Comments!} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></CustomPanelComments>
      <DefaultButton style={hideStyle} id='btnPanelTCR' onClick={e=> this.setTCRPanelState(e)} text='panelHide' ></DefaultButton>
      <DefaultButton style={hideStyle} id='btnPanelTcrComment' onClick={e=> this.setPanelTCRCommentsState(e)} text='panelHide' ></DefaultButton>
      <DefaultButton style={hideStyle} id='btnPanelTCREdit' onClick={e=> this.setTCRPanelEditState(e)} text='0' ></DefaultButton>
    </rt.TabPanel>
         {/* <rt.TabPanel> 
     <DetailsListNonCtrTransmittal openPanel = {this.state.showPanel} setPanelNonControlState = {this.setPanelNonControlState} description='' siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!} currentUserInProject={this.state.currentUserInProject}></DetailsListNonCtrTransmittal>
        <CustomPanelComments  dcCompany={this.state.dcCompany} dcEmails={this.state.dcEmails} commentMode='NonCtr' commentCounting = {this.state.commentCountingForPanel} currentProjectUser = {this.state.currentUserInProject!} onDissmiss={this.nonCtrAddHandler} isOpen={this.state.showPanel} transmittalItemId = {this.state.transmittalItemId4Comments!} siteUrl={this.props.siteUrl} projectNo={this.state.currentProject!}></CustomPanelComments>
        <DefaultButton style={hideStyle} id='btnPanelNonCtrTransmittal' onClick={e=> this.setPanelNonCtrCommentsState(e)} text='panelHide' ></DefaultButton>
      </rt.TabPanel>       */}
      {/* <rt.TabPanel> 
        <label>test Multer</label>
        <form action="/profile" method="post" encType="multipart/form-data">
          <input type="file" name="avatar" />
        </form>
      </rt.TabPanel> */}
      </rt.Tabs>
    </div>
  </div>
</div>
        );
}
}