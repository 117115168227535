import * as React from 'react';
import { ActivityItem, Icon, Link, mergeStyleSets, Panel } from 'office-ui-fabric-react';
//import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
//import {ITransmittalItem} from './ITransmittalItem';
//import { Web } from "@pnp/sp/webs"; 
//import { IComment } from './IComment';
import axios from 'axios';


export interface IAttachment
{
    Id:string;
    Category:string;
    Item_x0020_Bind_x0020_ID:string;
    FileName:string;
    FileUrl:string;

}

export interface IAttachmentForCommentProps {
    siteUrl:string;
    projectNo:string;
    CommentId:string;
    Category:string;
  }

  export interface IAttachmentForCommentState
  {
      loaded:string;
      attachments?:IAttachment[];
  }

//export const ActivityItemBasicExample: React.FunctionComponent = () => {
    export default class AttachmentForComment extends React.Component<IAttachmentForCommentProps, IAttachmentForCommentState> {

        constructor(props: IAttachmentForCommentProps | Readonly<IAttachmentForCommentProps>) {  
            super(props); 
            this.state={
                loaded:"Loading ...",
                attachments:[],
            };
          } 

          public render(): React.ReactElement<IAttachmentForCommentProps> {
              if (this.state.attachments!.length > 0)
              {
                   return (
                       <p><span onClick={()=>this._downloadAttachment()} style={{color: "blue",cursor:"pointer"}}><Icon iconName={'Attach'} />{this.state.loaded}</span></p>
                       );
              }
              else
              {
                  return(<p>{this.state.loaded}</p>);
              }
          }

          componentDidMount()
          {
              this._loadingAttachment();
          }

          private _downloadAttachment() : void
          {
            let fileName = this.state.attachments![0].FileName;
            let category = this.props.Category;
            console.log(this.props.Category);
            let libraryName = category == "MDL Assignment" ? "Vendor%20Documents" : "Attachments";
            console.log(`dynamic library name: ${libraryName}`);
            axios.get(`/api/v1/singleFileDownload?libraryName=${libraryName}&fileName=${fileName}&projectNo=${this.props.projectNo}`,
            {responseType: 'blob',})
            .then((res)=>{
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${fileName}`); //or any other extension
              document.body.appendChild(link);
              link.click();
             });
          }

          private async _getCommentAttachment() : Promise<any>
          {
            let result = await axios.get(`/api/v1/mdls/commentAttachment?projectNo=${this.props.projectNo}&commentId=${this.props.CommentId}&category=${this.props.Category}`);
            return result.data.body;
         }

          private async _loadingAttachment()
          {
            const promise1=  this._getCommentAttachment();
           let values = await Promise.all([promise1]);
           let response1 = values[0];
           let _allAtts : IAttachment[]  = [];
           response1.map((item: { ID: any; Category: any; Item_x0020_Bind_x0020_ID: any; File: { Name: string; }; }) =>{
            _allAtts.push({
                Id: item.ID,
                Category: item.Category,
                Item_x0020_Bind_x0020_ID:item.Item_x0020_Bind_x0020_ID,
                FileName:item.File.Name,
                FileUrl:this.props.siteUrl + "/" + this.props.projectNo + "/Vendor Documents/"+ item.File.Name,
             });
            });

        if (_allAtts.length > 0)
        {
            this.setState({
                loaded:_allAtts[0].FileName,
                attachments:_allAtts,
            });
        }
        else(
            this.setState({
                loaded:"",
            })
        )
           
        
          }
};
