import * as React from 'react';
import { Icon,} from 'office-ui-fabric-react';
//import { ActivityItem, Link, mergeStyleSets, Panel } from 'office-ui-fabric-react';
//import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import {ITransmittalItem} from './ITransmittalItem';
//import { Web } from "@pnp/sp/webs"; 
import { IComment } from './IComment';
// const classNames = mergeStyleSets({
//   exampleRoot: {
//     marginTop: '20px',
//   },
//   nameText: {
//     fontWeight: 'bold',
//   },
// });
import axios from 'axios';

export interface ICommentsCountingProps {
    count?:number;
    refItem:ITransmittalItem;
    //setPanelState?:any;
  }

  export interface ICommentsCountingState
  {
      counted:string;
      refItem:ITransmittalItem;
      //openPanel?:boolean;
  }

  const hideStyle = {display : 'none',maxWidth:'1px'};
//export const ActivityItemBasicExample: React.FunctionComponent = () => {
    export default class CommentsCounting extends React.Component<ICommentsCountingProps, ICommentsCountingState> {

        constructor(props: ICommentsCountingProps | Readonly<ICommentsCountingProps>) {  
            super(props); 
            this.state={
                counted:this.props.count? this.props.count.toString() : "0",
                refItem:this.props.refItem
            };
          } 

          public render(): React.ReactElement<ICommentsCountingProps> {
            return (
              <div>
                <span onClick={()=>this._openCommentsListPanel()} style={{color: "red",cursor:"pointer"}}><Icon iconName={'FileComment'} />{this.state.counted}</span>
                <button style={hideStyle} id={`btnCC${this.props.refItem.Id}`} onClick={()=>{this._countingComments()}}></button>
              </div>
            );
          }

          componentDidUpdate(previousProps:ICommentsCountingProps, previousState:ICommentsCountingState)
          {
              //this._countingComments();
          }

        //   componentDidUpdate(previousProps:ICommentsCountingProps, previousState:ICommentsCountingState) {
            
        //     // if (previousState.openPanel!= this.state.openPanel) {
        //     //   this.props.setPanelState();
        //     // }
        // }

          private _openCommentsListPanel() : void
          {
              //alert("Open the comments list panel here");
              // this.setState({
              //   openPanel:true,
              // });
              let element: HTMLElement = document.getElementById("btnPanelTransmittal") as HTMLElement;
              element.textContent= this.state.refItem.Id.toString();
              //try get couting number and pass to panel here:
              //alert(this.props.refItem.comment);
              element.title =  this.state.refItem.comment === undefined ? '0' : this.props.refItem.comment!.toString();
              //alert(element.title);
              element.click();
          }

          private async _getTransmittalComments() : Promise<any>
          {
            let result = await axios.get(`/api/v1/transmittalItems/commentsCounting?projectNo=${this.props.refItem.projectNo!}&refItemId=${this.props.refItem.Id}`);
            return result.data.body;
            //  if (this.props.refItem.projectNo != null && this.props.refItem.siteUrl != null)
            //  {
            //      //replace the hardcode value for current projectuser Id with 
                 
            //    let web = Web(this.props.refItem.siteUrl + "/" + this.props.refItem.projectNo);
            //  let response = await  web.lists.getByTitle("Comments").items.select("Id","Title","Item_x0020_Bind_x0020_Id","Category","Comments","CommentBy/Id","CommentBy/Title","Comments_x0020_Date")
            //    .expand("CommentBy/Id").filter("Category eq 'Transmittal Item Return' and Item_x0020_Bind_x0020_Id eq '" + this.props.refItem.Id + "'").get();
            //    return response;
            //  }
            //  else
            //  {
            //    return Promise.resolve(1);
            //  }
         }

         private async _countingComments()
         {
            const promise1=  this._getTransmittalComments();
           let values = await Promise.all([promise1]);
           let response1 = values[0];
           //let _allTransComments : IComment[]  = [];
           let count:number = response1.length;
           var nRefItem = this.state.refItem;
           nRefItem.comment = response1.length.toString();
           this.setState({
               counted:"  " + count.toString(),
               refItem: nRefItem
           });
        
         }  

          private async _buildDetailsListItems()
          {
            const promise1=  this._getTransmittalComments();
            // const p3 = new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       resolve("foo");
            //     }, 3000);
            //   });
           let values = await Promise.all([promise1]);
           let response1 = values[0];
           let _allTransComments : IComment[]  = [];
           response1.map((item: { ID: any; Title: any; Category: any; Item_x0020_Bind_x0020_Id: any; CommentBy: { Id: number; Title: string; } | null; CommentDate: any; CommentByTitle: any; }) =>{
            _allTransComments.push({
                Id: item.ID,
                Title: item.Title,
                Category: item.Category,
                Item_x0020_Bind_x0020_Id:item.Item_x0020_Bind_x0020_Id,
                CommentById:item.CommentBy == null ? 0 : item.CommentBy.Id,
                CommentDate:item.CommentDate,
                CommentByTitle:item.CommentByTitle,
                Comments:item.CommentBy == null ? "" : item.CommentBy.Title,
             });
            });

           //console.log(values);
           
           console.log("==========allItems==========:");
           console.log(_allTransComments.length.toString());
        
           //this._options = this._renderStatusOptions();
        
           this.setState({
               counted:_allTransComments.length.toString(),
           });
        
          }
};
