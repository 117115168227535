import * as React from 'react';
import { ActivityItem, Icon, Link, mergeStyleSets } from 'office-ui-fabric-react';
import {DateHelper} from '../DateHelper'
//import { Item } from '@pnp/sp/items';
//import { Web } from "@pnp/sp/webs";
import AttachmentForMDLAssignment from './AttachmentForMDLAssignment';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import { ICommentMDLAssignment } from './ICommentMDLAssignment';


const classNames = mergeStyleSets({
  exampleRoot: {
    marginTop: '20px',
  },
  nameText: {
    fontWeight: 'bold',
  },
});

export interface ICommentMDLAssignmentActivityProps
{
    siteUrl:string;
    projectNo:string;
    mdlAssignmentId:string;
    commentMode:string;
}
export interface ICommentMDLAssignmentActivityState
{
loaded?:string;
CommentItems?:ICommentMDLAssignment[];
isBusy?:boolean;
}


export default class CommentsMDLAssignment extends React.Component<ICommentMDLAssignmentActivityProps,ICommentMDLAssignmentActivityState> {
  /* eslint-disable react/jsx-no-bind */
  constructor(props: ICommentMDLAssignmentActivityProps | Readonly<ICommentMDLAssignmentActivityProps>) {  
    super(props); 
    this.state={
        CommentItems:[],
        loaded:"Loading ...",
        isBusy:true,
    };
  } 

  componentDidMount() 
  {
    this._buildDetailsListItems();
  }

  private async _getMDLAssignmentComments() : Promise<any>
  {
    let result = await axios.get(`/api/v1/mdls/commentsCounting?projectNo=${this.props.projectNo}&refItemId=${this.props.mdlAssignmentId}`);
              return result.data.body;
  }

private async _buildDetailsListItems()
      {
        const promise1=  this._getMDLAssignmentComments();
       let values = await Promise.all([promise1]);
       let response1 = values[0];
       let _allTransComments : ICommentMDLAssignment[]  = [];
       response1.map((item: { ID: any; Title: any; Category: any; Item_x0020_Bind_x0020_Id: any; CommentBy: { Id: number; Title: string; } | null; Comments_x0020_Date: any; Comments: any; }) =>{
        _allTransComments.push({
            Id: item.ID,
            Title: item.Title,
            Category: item.Category,
            Item_x0020_Bind_x0020_Id:item.Item_x0020_Bind_x0020_Id,
            CommentById:item.CommentBy == null ? 0 : item.CommentBy.Id,
            CommentDate:item.Comments_x0020_Date,
            CommentByTitle:item.CommentBy == null ? "" : item.CommentBy.Title,
            Comments:item.Comments,
         });
        });

    
       this.setState({
           CommentItems:_allTransComments,
           isBusy:false,
       });
    
      }

  public render(): React.ReactElement<ICommentMDLAssignmentActivityProps> {
      let activityItemExamples: { key: string | number; activityDescription?: JSX.Element[]; activityIcon?: JSX.Element; comments?: JSX.Element[]; timeStamp?: string; }[] = [];
      this.state.CommentItems!.map((item) =>{
          activityItemExamples.push({
              key: item.Id.toString(),
              activityDescription: [
                <Link
                  key={1}
                  className={classNames.nameText}
                  onClick={() => {
                    //alert('A name was clicked.');
                  }}
                >
                  {item.CommentByTitle}
                </Link>,
                <span key={2}> commented</span>,
              ],
              activityIcon: <Icon iconName={'Message'} />,
              comments: [
                  <span key={1}>{item.Title}</span>,
                  <p><span key={3}> {item.Comments}</span></p>,
                  <AttachmentForMDLAssignment siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} CommentId={item.Id.toString()} Category={item.Category}/>
                ],
            timeStamp: DateHelper.FormatDateToLongDate(item.CommentDate),
          });
      });
        return (
            <div>
              { this.state.isBusy ? <Spinner size={SpinnerSize.small} /> : null }
            {activityItemExamples.map((item: { key: string | number }) => (
                <ActivityItem {...item} key={item.key} className={classNames.exampleRoot} />
            ))}
            </div>
        );
      }
};
