import * as React from 'react';
import { ActivityItem, Icon, Link, mergeStyleSets, Panel } from 'office-ui-fabric-react';
//import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
//import {ITransmittalItem} from './ITransmittalItem';
//import { Web } from "@pnp/sp/webs"; 
//import { IComment } from './IComment';
import axios from 'axios';


export interface IAttachment
{
    Id:string;
    Category:string;
    Item_x0020_Bind_x0020_ID:string;
    FileName:string;
    FileUrl:string;

}

export interface IAttachmentForCommentProps {
    siteUrl:string;
    projectNo:string;
    CommentId:string;
  }

  export interface IAttachmentForCommentState
  {
      loaded:string;
      attachments?:IAttachment[];
  }

//export const ActivityItemBasicExample: React.FunctionComponent = () => {
    export default class AttachmentForComment extends React.Component<IAttachmentForCommentProps, IAttachmentForCommentState> {

        constructor(props: IAttachmentForCommentProps | Readonly<IAttachmentForCommentProps>) {  
            super(props); 
            this.state={
                loaded:"",
                attachments:[],
            };
          } 

          public render(): React.ReactElement<IAttachmentForCommentProps> {
              if (this.state.attachments!.length > 0)
              {
                   return (
                       <p><span onClick={()=>this._downloadAttachment()} style={{color: "blue",cursor:"pointer"}}><Icon iconName={'Attach'} />{this.state.loaded}</span></p>
                       );
              }
              else
              {
                  return(<p></p>);
              }
          }

          componentDidMount()
          {
              this._loadingAttachment();
          }

        //   componentDidUpdate(previousProps:ICommentsCountingProps, previousState:ICommentsCountingState) {
            
        //     // if (previousState.openPanel!= this.state.openPanel) {
        //     //   this.props.setPanelState();
        //     // }
        // }

          private _downloadAttachment() : void
          {
            let fileName = this.state.attachments![0].FileName;
            axios.get(`/api/v1/singleFileDownload?libraryName=Attachments&fileName=${fileName}&projectNo=${this.props.projectNo}`,
            {responseType: 'blob',})
            .then((res)=>{
              //Buffer.from(res.data, 'binary').toString('base64');
              //this.props.handlerDownload();
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `${fileName}`); //or any other extension
              document.body.appendChild(link);
              link.click();
             });
                // console.log("Download attachment here!");
                // if (this.state.attachments!.length > 0)
                // {
                //     const anchor = document.createElement('a');
                //     anchor.href = this.state.attachments![0].FileUrl;
                //     anchor.target = '_blank';
                //     anchor.download = this.state.attachments![0].FileName;               
                //     document.body.appendChild(anchor);
                //     anchor.click();
                //     document.body.removeChild(anchor);
                // }
          }

          private async _getCommentAttachment() : Promise<any>
          {
            let result = await axios.get(`/api/v1/transmittalItems/commentAttachment?projectNo=${this.props.projectNo}&commentId=${this.props.CommentId}`);
            return result.data.body;
            //  if (this.props.projectNo != null && this.props.siteUrl != null)
            //  {
            //      //replace the hardcode value for current projectuser Id with 
                 
            //    let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
            //  let response = await  web.lists.getByTitle("Attachments").items.select("Id","Category","Item_x0020_Bind_x0020_ID","File")
            //  .expand("File").filter("Item_x0020_Bind_x0020_ID eq '" + this.props.CommentId + "'").get();
            //    return response;
            //  }
            //  else
            //  {
            //    return Promise.resolve(1);
            //  }
         }

          private async _loadingAttachment()
          {
            const promise1=  this._getCommentAttachment();
            // const p3 = new Promise((resolve, reject) => {
            //     setTimeout(() => {
            //       resolve("foo");
            //     }, 3000);
            //   });
           let values = await Promise.all([promise1]);
           let response1 = values[0];
           let _allAtts : IAttachment[]  = [];
           response1.map((item: { ID: any; Category: any; Item_x0020_Bind_x0020_ID: any; File: { Name: string; }; }) =>{
            _allAtts.push({
                Id: item.ID,
                Category: item.Category,
                Item_x0020_Bind_x0020_ID:item.Item_x0020_Bind_x0020_ID,
                FileName:item.File.Name,
                FileUrl:this.props.siteUrl + "/" + this.props.projectNo + "/Attachments/"+ item.File.Name,
             });
            });

        
           //this._options = this._renderStatusOptions();
        if (_allAtts.length > 0)
        {
            this.setState({
                loaded:_allAtts[0].FileName,
                attachments:_allAtts,
            });
        }
           
        
          }
};
