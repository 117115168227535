import * as React from 'react';
import './Login2.css';
import axios from 'axios';
import logo from './logo.png';
import {MessageBar,MessageBarType,initializeIcons} from 'office-ui-fabric-react';
//import {MessageBarBasicExample} from './MessageBar/MessageBar';
//import { initializeIcons } from '@uifabric/icons';
initializeIcons();

export interface ILogin2Props
{
  triggerParent?:any,
}

export interface ILogin2State
{
  userName?:string,
  pwd?:string,
  pwdNew?:string,
  pwdCon?:string,
  token? : string,
  errMessageBarHidden:boolean,
  infMessageBarHidden:boolean,
  loginMessage? : string,
  siteUrl:string,
  changePassWordFlag:boolean,
}



export default class  Login2 extends React.Component<ILogin2Props, ILogin2State,{} > {
  constructor(props: ILogin2Props, state: ILogin2State) {  
    super(props);  
    this.state = {
      userName : '',//'baojiang.wang@sg.yokogawa.com',
      errMessageBarHidden:true,
      infMessageBarHidden:true,
      loginMessage: 'Invalid UserName or Password, please try again.',
      siteUrl:'',
      changePassWordFlag: false,
    }
  } 
  public render(): React.ReactElement<ILogin2Props> {
  return(
    <div className="login-wrapper">
        {/* <h2>Modal Login Form</h2>
        <button onClick={this.myclick} style={{width:'auto'}}>Login</button> */}
        <div id="id01" className="modal">
        <form className="modal-content animate">
        <div className="imgcontainer">
        <span onClick={this.hideclick} className="close" title="Close Modal">&times;</span>
        <img src={logo} alt="Avatar" className="avatar"></img>
        </div>

        <div className="container">
      <label htmlFor="uname"><b>Email</b></label>
      <input type="text" placeholder="Enter email registred in YOKOGAWA PDMS" name="uname" value={this.state.userName} onChange={this.setUserName} required/>

      <label htmlFor="psw"><b>{this.state.changePassWordFlag ? 'Current Password' : 'Password'}</b></label>
      <input type="password" placeholder="Enter Password" name="psw" value={this.state.pwd} onChange={this.setPassword}/>

      <div hidden={!this.state.changePassWordFlag}  id='infChangePwdWraper'>
      <label htmlFor="pswN"><b>Input new password</b></label>
      <input type="password" placeholder="Enter New Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="pswNew" value={this.state.pwdNew} onChange={this.setPasswordNew}/>
      <label htmlFor="pswC"><b>Confirm new password</b></label>
      <input type="password" placeholder="Confirm New Password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" name="pswCon" value={this.state.pwdCon} onChange={this.setPasswordConfirm}/>
      </div>

      <div hidden={this.state.infMessageBarHidden}  id='infMessageWraper'>
      <MessageBar >{this.state.loginMessage}</MessageBar>
      </div>
      <div hidden={this.state.errMessageBarHidden}  id='errorMessageWraper'>
      <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">{this.state.loginMessage}</MessageBar>
      </div>
      {/* <MessageBarBasicExample></MessageBarBasicExample> */}
      <input type="button" className="button" onClick={()=>this.loginUser()} value={this.state.changePassWordFlag ? 'Confirm Change':'login'}/>
      {/* <label>
        <input type="checkbox" checked={true} name="remember"/> Remember me
      </label> */}
    </div>


    <div className="container" style={{backgroundColor:'#f1f1f1'}}>
      {/* <button type="button" onClick={this.hideclick} className="cancelbtn">Cancel</button>
    </div>
    <div className="container" style={{backgroundColor:'#f1f1f1'}}> */}
      <span hidden={this.state.changePassWordFlag}>Note: First time login or forget password please click<input type='button' value='Find my password?' onClick={() => this.sendEmailForUserAccount()}/></span>
      <p hidden={this.state.changePassWordFlag}><span>Change your password, please click<input type='button' value='Change my password' onClick={() => this.changeToChangePasswordState()}/></span></p>
      <p hidden={!this.state.changePassWordFlag}><span>Back to login screen, please click<input type='button' value='Back to login' onClick={() => this.changeBacktoLoginState()}/></span></p>
    </div>


        </form>
        </div>
    </div>
    
  )
  }

  myclick = (event:any) =>
  {
    document.getElementById('id01')!.style.display='block';
  }
  hideclick = (event:any) =>
  {
    document.getElementById('id01')!.style.display='none';
  }

  setUserName = (event:any) =>
  {
    this.setState({
      userName: event.target.value,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
    });
  }

  setPassword  = (event:any) =>
  {
    this.setState({
      pwd: event.target.value,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
    });
  }

  setPasswordNew= (event:any) =>
  {
    this.setState({
      pwdNew: event.target.value,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
    });
  }

  setPasswordConfirm= (event:any) =>
  {
    if (event.target.value == this.state.pwdNew)
    {
      this.setState({
      pwdCon: event.target.value,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
    });

    }
    else
    {
    this.setState({
      pwdCon: event.target.value,
      errMessageBarHidden:true,
      infMessageBarHidden:false,
      loginMessage:'Mismatch detected when confirm the new password, please correct it',
    });
  }
  }

  componentDidUpdate(previousProps:ILogin2Props, previousState:ILogin2State)
  {
  }
  componentDidMount()
  {
    document.getElementById('id01')!.style.display='block';
  }

  private loginUser()
  {

    if (!this.state.changePassWordFlag)
    {
    console.log('...start loginUser');
    let loginEmail :string = this.state.userName!;
    let request = {
      // body: {
        email:loginEmail,
        password:this.state.pwd,
      // }
    }
    axios.post('/api/v1/auth/genToken',request).then((res) => {
      //console.log(res);

      if (res.data.auth.toString().toUpperCase() === "TRUE" )
      {
        this.props.triggerParent(res.data.token,loginEmail,res.data.siteUrl);
      }
      else
      {
        this.setState({
          infMessageBarHidden:true,
          errMessageBarHidden:false,
          loginMessage:res.data.info,
        });
      }

      // if (res.data.body.length > 0)
      // {
      //   this.props.triggerParent(loginEmail);
      // }
      // else
      // {
      //   this.setState({
      //     errMessageBarHidden:false,
      //   });
      // }
    }).catch(err => 
      {
        console.log("err:" + JSON.stringify(err))
      });
    }
    else
    {
      if (this.state.pwdNew == undefined || this.state.pwdNew == '')
      {
        this.setState({
          infMessageBarHidden:true,
          errMessageBarHidden:false,
          loginMessage:'Please input your new password',
        });
        return;
      }
      else if (this.state.pwdNew !== this.state.pwdCon)
      {
        this.setState({
          infMessageBarHidden:true,
          errMessageBarHidden:false,
          loginMessage:'Please confirm your new password',
        });
        return;
      }
      else
      {
      //add change password logic here
      let loginEmail :string = this.state.userName!;
      let request = {
        // body: {
          email:loginEmail,
          password:this.state.pwd,
          passwordNew: this.state.pwdNew,
        // }
      }
      var regularExpression  = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    if(!regularExpression.test(this.state.pwdNew)) {
      this.setState({
        infMessageBarHidden:true,
        errMessageBarHidden:false,
        loginMessage:'Minimum eight, only letters and numbers, at least one letter and one number.',
      });
      return;
    }
      axios.post('/api/v1/auth/updatePassword',request).then((res) => {
        //console.log(res);
  
        if (res.data.authChanged !== undefined )
        {
          this.setState({
            infMessageBarHidden:false,
            errMessageBarHidden:true,
            loginMessage:'Password changed successfuly.',
            pwd:'',
            pwdNew:'',
            pwdCon:'',
          });
          
        }
        else
        {
          this.setState({
            infMessageBarHidden:true,
            errMessageBarHidden:false,
            loginMessage:res.data.info,
          });
        }

      }).catch(err => 
        {
          console.log("err:" + JSON.stringify(err))
        });
      }
    }
  }

  private sendEmailForUserAccount()
  {
    console.log('...start send email');
    let loginEmail :string = this.state.userName!;
    let request = {
      email: loginEmail,
    }
    axios.post('/api/v1/auth/findAccount',request).then((res) => {
      this.setState({
        errMessageBarHidden:true,
        infMessageBarHidden:false,
        loginMessage:res.data.info,
      });
    }).catch(err => 
      {
        //console.log("err:" + JSON.stringify(err)),
        this.setState({
          errMessageBarHidden:false,
          loginMessage:JSON.stringify(err),
        })
      });
  }

  private changeToChangePasswordState()
  {
    this.setState({
      changePassWordFlag:true,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
      loginMessage:'',
    })
  }

  private changeBacktoLoginState()
  {
    this.setState({
      changePassWordFlag:false,
      errMessageBarHidden:true,
      infMessageBarHidden:true,
      loginMessage:'',
    })
  }

}