import * as React from 'react';
import { ActivityItem, Icon, Link, mergeStyleSets } from 'office-ui-fabric-react';
import {IComment} from './IComment';
import {DateHelper} from './DateHelper'
//import { Item } from '@pnp/sp/items';
//import { Web } from "@pnp/sp/webs";
import AttachmentForComment from './AttachmentForComment';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';


const classNames = mergeStyleSets({
  exampleRoot: {
    marginTop: '20px',
  },
  nameText: {
    fontWeight: 'bold',
  },
});

export interface ICommentsActivityProps
{
    siteUrl:string;
    projectNo:string;
    transmittalItemId:string;
    commentMode:string;
}
export interface ICommentsActivityState
{
loaded?:string;
CommentItems?:IComment[];
isBusy?:boolean;
}

// const activityItemExamples = [
//     {
//       key: 1,
//       activityDescription: [
//         <Link
//           key={1}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('A name was clicked.');
//           }}
//         >
//           Philippe Lampros
//         </Link>,
//         <span key={2}> commented</span>,
//       ],
//       activityIcon: <Icon iconName={'Message'} />,
//       comments: [
//         <span key={1}>Hello! I am making a comment and mentioning </span>,
//         <Link
//           key={2}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('An @mentioned name was clicked.');
//           }}
//         >
//           @Anđela Debeljak
//         </Link>,
//         <span key={3}> in the text of the comment.</span>,
//       ],
//       timeStamp: 'Just now',
//     },
//     {
//       key: 2,
//       activityDescription: [
//         <Link
//           key={1}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('A name was clicked.');
//           }}
//         >
//           Lisha Refai
//         </Link>,
//         <span key={2}> deleted </span>,
//         <span key={3} className={classNames.nameText}>
//           DocumentTitle.docx
//         </span>,
//       ],
//       activityIcon: <Icon iconName={'Trash'} />,
//       timeStamp: '2 hours ago',
//     },
//     {
//       key: 3,
//       activityDescription: [
//         <Link
//           key={1}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('A name was clicked.');
//           }}
//         >
//           Julian Arvidsson
//         </Link>,
//         <span key={2}> moved </span>,
//         <Link
//           key={3}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('A document was clicked.');
//           }}
//         >
//           PresentationTitle.pptx
//         </Link>,
//         <span key={4}> to </span>,
//         <Link
//           key={5}
//           className={classNames.nameText}
//           onClick={() => {
//             alert('A folder was clicked.');
//           }}
//         >
//           Destination Folder
//         </Link>,
//       ],
//       activityIcon: <Icon iconName={'FabricMovetoFolder'} />,
//       timeStamp: 'Yesterday',
//     },
//   ];

export default class Comments extends React.Component<ICommentsActivityProps,ICommentsActivityState> {
  /* eslint-disable react/jsx-no-bind */
  constructor(props: ICommentsActivityProps | Readonly<ICommentsActivityProps>) {  
    super(props); 
    this.state={
        CommentItems:[],
        loaded:"Loading ...",
        isBusy:true,
    };
  } 

  componentDidMount() 
  {
    this._buildDetailsListItems();
  }


// componentDidUpdate(previousProps:ICustomPanelCommentsProps, previousState: ICustomPanelCommentsState) {
//     if (previousState.uploaded == false && this.state.uploaded == true) {
//       //this.props.handlerNonCrlPanel();
//     }
// }

private async _getTransmittalComments() : Promise<any>
{
  let particalUrl;
  if(this.props.commentMode == "TcrComment"){
    particalUrl = "tcr";
  }else{
    particalUrl = this.props.commentMode === "Control" ? "transmittalItems" : "nonControlDocTransmittalItems";    
  }
  //let particalUrl = this.props.commentMode === "Control" ? "transmittalItems" : "nonControlDocTransmittalItems";
  let result = await axios.get(`/api/v1/${particalUrl}/commentsCounting?projectNo=${this.props.projectNo}&refItemId=${this.props.transmittalItemId}`);
  return result.data.body;
//  if (this.props.projectNo != null && this.props.siteUrl != null)
//  {
//      //replace the hardcode value for current projectuser Id with 
     
//    let web = Web(this.props.siteUrl + "/" + this.props.projectNo);
//  let response = await  web.lists.getByTitle("Comments").items.select("Id","Title","Item_x0020_Bind_x0020_Id","Category","Comments","CommentBy/Id","CommentBy/Title","Comments_x0020_Date")
//    .expand("CommentBy/Id").filter("Category eq 'Transmittal Item Return' and Item_x0020_Bind_x0020_Id eq '" + this.props.transmittalItemId + "'").get();
//    return response;
//  }
//  else
//  {
//    return Promise.resolve(1);
//  }
}

private async _buildDetailsListItems()
      {
        const promise1=  this._getTransmittalComments();
        // const p3 = new Promise((resolve, reject) => {
        //     setTimeout(() => {
        //       resolve("foo");
        //     }, 3000);
        //   });
       let values = await Promise.all([promise1]);
       let response1 = values[0];
       let _allTransComments : IComment[]  = [];
       response1.map((item: { ID: any; Title: any; Category: any; Item_x0020_Bind_x0020_Id: any; CommentBy: { Id: number; Title: string; } | null; Comments_x0020_Date: any; Comments: any; }) =>{
        _allTransComments.push({
            Id: item.ID,
            Title: item.Title,
            Category: item.Category,
            Item_x0020_Bind_x0020_Id:item.Item_x0020_Bind_x0020_Id,
            CommentById:item.CommentBy == null ? 0 : item.CommentBy.Id,
            CommentDate:item.Comments_x0020_Date,
            CommentByTitle:item.CommentBy == null ? "" : item.CommentBy.Title,
            Comments:item.Comments,
         });
        });

       //console.log(values);
       
       //console.log("==========allItems==========:");
       //console.log(_allTransComments.length.toString());
    
       //this._options = this._renderStatusOptions();
    
       this.setState({
           CommentItems:_allTransComments,
           isBusy:false,
       });
    
      }

  public render(): React.ReactElement<ICommentsActivityProps> {
      let activityItemExamples: { key: string | number; activityDescription?: JSX.Element[]; activityIcon?: JSX.Element; comments?: JSX.Element[]; timeStamp?: string; }[] = [];
      this.state.CommentItems!.map((item) =>{
          activityItemExamples.push({
              key: item.Id.toString(),
              activityDescription: [
                <Link
                  key={1}
                  className={classNames.nameText}
                  onClick={() => {
                    //alert('A name was clicked.');
                  }}
                >
                  {item.CommentByTitle}
                </Link>,
                <span key={2}> commented</span>,
              ],
              activityIcon: <Icon iconName={'Message'} />,
              comments: [
                  <span key={1}>{item.Title}</span>,
                  <p><span key={3}> {item.Comments}</span></p>,
                  <AttachmentForComment siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} CommentId={item.Id.toString()}/>
                ],
            timeStamp: DateHelper.FormatDateToLongDate(item.CommentDate),
          });
      });
        return (
            <div>
              { this.state.isBusy ? <Spinner size={SpinnerSize.small} /> : null }
            {activityItemExamples.map((item: { key: string | number }) => (
                <ActivityItem {...item} key={item.key} className={classNames.exampleRoot} />
            ))}
            </div>
        );
      }
};
