import * as React from 'react';
import { Panel} from 'office-ui-fabric-react/lib/Panel';
import PnpFileUpload from './FileUploadReact';
import { ICustomPanelProps } from './ICustomPanelProps';
import {ICustomPanelState} from './ICustomPanelState'
//import { sp } from "@pnp/sp/presets/all";
//import { useBoolean } from '@uifabric/react-hooks';
//import { DefaultButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';

//const buttonStyles = { root: { marginRight: 8 } };

export default class CustomPanel extends React.Component<ICustomPanelProps, ICustomPanelState> {

    // This panel doesn't actually save anything; the buttons are just an example of what
    // someone might want to render in a panel footer.
// public changeOpen = () =>{
//     this.setState
//     {
//         isOpen : true;
//     }

// }

    constructor(props: ICustomPanelProps | Readonly<ICustomPanelProps>) {  
        super(props);  
        // sp.setup({
        //   spfxContext: this.props.context
        // });
        this.state={
          targetListRefresh:false,
          uploaded:false,
        };
        this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this);  
      } 

      private nonCtrAddHandler():void{
        this.setState({
          targetListRefresh:true,
          uploaded:true,
        });
      }

  // componentDidUpdate(previousProps:ICustomPanelProps, previousState: ICustomPanelState) {
  //     if (previousState.uploaded == false && this.state.uploaded == true) {
  //       //this.props.handlerNonCrlPanel();
  //     }
  // }

  public render(): React.ReactElement<ICustomPanelProps> {
    // const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] = useBoolean(false);
    // const onRenderFooterContent = React.useCallback(
    //     () => (
    //       <div>
    //         <PrimaryButton onClick={dismissPanel} styles={buttonStyles}>
    //           Save
    //         </PrimaryButton>
    //         <DefaultButton onClick={dismissPanel}>Cancel</DefaultButton>
    //       </div>
    //     ),
    //     [dismissPanel],
    //   );
    return (
      <div>
        <Panel
          //isOpen={isopen}
          //type={ PanelType.smallFixedFar }
          //headerText='Upload document'
          //closeButtonAriaLabel='Close'

          isOpen={this.props.isOpen}
          onDismiss={this.props.onDissmiss}
          headerText="Upload document"
          closeButtonAriaLabel="Close"
          //onRenderFooterContent={onRenderFooterContent}
          // Stretch panel content to fill the available height so the footer is positioned
          // at the bottom of the page
          //isFooterAtBottom={true}
        >
        <PnpFileUpload token={this.props.token} userInfo={this.props.currentUserInProject} description='' siteUrl={this.props.siteUrl} projectNo={this.props.projectNo}/>
        </Panel>
      </div>
    );
  }
}