import * as React from 'react';
import { Panel, } from 'office-ui-fabric-react/lib/Panel';
import { PrimaryButton } from 'office-ui-fabric-react/lib/Button';
//import { PanelType } from 'office-ui-fabric-react/lib/Panel';
import Comments from './Comments'
import { IComment } from './IComment';
import PanelFooterExample from './PanelComments';
import {IPdmsUser} from './IPdmsUser';
import { addListener } from 'process';
import axios from 'axios';

//import { Web } from "@pnp/sp/webs"; 

const buttonStyles = { root: { marginRight: 8 } };

export interface ICustomPanelCommentsProps {
    //context?: any | null;
    isOpen: boolean;
    siteUrl:string;
    projectNo:string;
    onDissmiss:any;
    transmittalItemId:string;
    currentProjectUser:IPdmsUser;
    commentCounting?:string;
    commentMode:string;
    //handlerNonCrlPanel?:any
    //isRefreshTarget?:boolean
    dcCompany:string;
    dcEmails:string;
  }

  export interface ICustomPanelCommentsState {
    //isOpen: boolean;
    // targetListRefresh:boolean;
    // uploaded:boolean;
    isAddingMode:boolean;
    commentItems:IComment[];
    isHistory?:boolean;
  }

export default class CustomPanelComments extends React.Component<ICustomPanelCommentsProps, ICustomPanelCommentsState> {


    constructor(props: ICustomPanelCommentsProps | Readonly<ICustomPanelCommentsProps>) {  
        super(props);  
        // sp.setup({
        //   spfxContext: this.props.context
        // });
        this.state={
          isAddingMode : false,
          commentItems:[],
        };
        //this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this);  
      } 

      componentDidMount() 
      {
        //alert(this.state.isAddingMode.toString() + this.props.commentCounting );
        console.log(this.state.isAddingMode);
        console.log(this.props.commentCounting === "0");
        console.log(`this.props.commentMode === ${this.props.commentMode}`);
        if (this.props.commentMode === "Control")
        {
        this._buildTransmittalItem();
        }
      }


  componentDidUpdate(previousProps:ICustomPanelCommentsProps, previousState: ICustomPanelCommentsState) {
      
      if (this.props.commentMode === "Control")
      {
        if (this.props.transmittalItemId != previousProps.transmittalItemId)
        {
          console.log(`Trigger the calculate to display "Add" button or not.`)
          this._buildTransmittalItem();
        }
      }
  }



  public render()
  {
    return (
      <div>
        <Panel
          //isOpen={isopen}
          //type={ PanelType.smallFixedFar }
          //headerText='Upload document'
          //closeButtonAriaLabel='Close'

          isOpen={this.props.isOpen}
          onDismiss={() => this.triggerItemCommentsCounting()}
          headerText = {this.props.commentMode === "TcrComment" ? "TCR Comments" : "Transmittal Comments"}
          closeButtonAriaLabel="Close"
          //onRenderFooterContent={onRenderFooterContent}
          // Stretch panel content to fill the available height so the footer is positioned
          // at the bottom of the page
          //isFooterAtBottom={true}
        >
      { 
        (() => {
          if(this.state.isAddingMode || this.props.commentCounting === "0") {
            if (this.state.isHistory === false || this.state.isHistory === undefined)
            {
            return (<div><PanelFooterExample dcCompany={this.props.dcCompany} dcEmails={this.props.dcEmails}  commentMode={this.props.commentMode} handleAdd={() => this.handleCommentAdd()} currentProjectUser={this.props.currentProjectUser}  transmittalItemId = {this.props.transmittalItemId!} projectNo={this.props.projectNo} siteUrl={this.props.siteUrl}></PanelFooterExample>         
            <div><PrimaryButton onClick={()=>this.restoreMode()} id="btnAllComment" styles={buttonStyles}>All Comments</PrimaryButton></div></div>);
            }
            else
            {
              return (<div><PrimaryButton onClick={()=>this.restoreMode()} id="btnAllComment" styles={buttonStyles}>All Comments</PrimaryButton></div>);
            }
          }
          else 
          {
            if (this.state.isHistory === false || this.state.isHistory === undefined)
            {
            return (<div><Comments commentMode={this.props.commentMode} siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} transmittalItemId={this.props.transmittalItemId}/>
              <PrimaryButton onClick={()=>this._addComment()} id="btnNewComment" styles={buttonStyles}>Add</PrimaryButton></div>);
            }
            else
            {
            return (<div><Comments commentMode={this.props.commentMode} siteUrl={this.props.siteUrl} projectNo={this.props.projectNo} transmittalItemId={this.props.transmittalItemId}/></div>);
            }
          }
        })()
      }  
        </Panel>
      </div>
    );
  }

  
private async _buildTransmittalItem()
{
  const promise1=  this._getTransmittalItem();
 let values = await Promise.all([promise1]);
 let response1 = values[0];
 let displayFlag = response1[0]["Return_x0020_Date"] === null ? false : true;
 console.log(`displayFlag = ${displayFlag}`)
 this.setState({
  isHistory: displayFlag
 });


//  this.setState({
    
//      isBusy:false,
//  });

}

private async _getTransmittalItem(): Promise<any>{
  let result = await axios.get(`/api/v1/transmittalSumItems/getTraItem?projectNo=${this.props.projectNo}&id=${this.props.transmittalItemId}`);
  return result.data.body;
}

  private _addComment(){
    this.setState({
      isAddingMode:true,
    })   
  }
  private handleCommentAdd(){
    this.setState({
      isAddingMode:false,
    })
  }
  private restoreMode(){
    this.setState({
      isAddingMode:false,
    })
  }
  private triggerItemCommentsCounting() {
    //find the comment counting item hidden button and click
    let element: HTMLElement = document.getElementById(`btnCC${this.props.transmittalItemId!}`) as HTMLElement;
    element.click();
    this.props.onDissmiss();
  }
}