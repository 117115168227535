import "@pnp/polyfill-ie11";
//import {sp} from '@pnp/sp';  
//import { sp } from "@pnp/sp/presets/all";
//import { sizeBoolean } from "office-ui-fabric-react";
import * as React from 'react';
import {IFileUploadReactProps} from './IFileUploadReactProps';
import {IFileUploadReactState} from './IFileUploadReactState';
import SendEmail from './SendEmail';
//import { Web } from "@pnp/sp/webs"; 
import axios from 'axios';
import { Overlay, ProgressIndicator, Spinner, SpinnerSize,Label } from "office-ui-fabric-react";
import { OverlayDarkExample } from "./Overlay";
//import { file } from "jszip";

export default class PnpFileUpload extends React.Component<IFileUploadReactProps, IFileUploadReactState> {
    constructor(props: IFileUploadReactProps | Readonly<IFileUploadReactProps>) {
        super(props);
        // sp.setup({
        //   spfxContext: this.props.context
        // });
        // this.setState({
        //   callSendEmail:false
        // })
        this.state =
            {
                fileNameUploaded: "",
                callSendEmail: false,
                uploaded: false,
                isBusy: false,
                showProgress: false,
                progressPercent: 0,
                progressLabel: "File upload progress",
                progressDescription: "",
                isBigSizeDetected:false,
                bigSizeFileNote:"",
                fileSizeLimit:0,
            }
        this.filesave = this.filesave.bind(this);
        this.emailHandle = this.emailHandle.bind(this);
    }
    public render(): React.ReactElement<IFileUploadReactProps> {
        return (
            <div>
                <div>
                    <form encType="multipart/form-data" id="myForm">
                        <input type="file" name="myFile" id="newfile"></input>
                    </form>
                    <Label id="txtNote">{this.state.bigSizeFileNote}</Label>
                    <button onClick={this.filesave}>UploadFile</button>
                    <ProgressIndicator
                        label={this.state.progressLabel}
                        description={this.state.progressDescription}
                        percentComplete={this.state.progressPercent}
                        barHeight={5} />

                    { this.state.isBusy ? <Overlay><Spinner  size={SpinnerSize.large} ></Spinner ></Overlay> : null }
                    <SendEmail isBigSizeDetected={this.state.isBigSizeDetected} userInfo={this.props.userInfo} token = {this.props.token} description='' callMethod={this.state.callSendEmail} emailHandle = {this.emailHandle} projectNo = {this.props.projectNo} siteUrl = {this.props.siteUrl} uploadedFileName={this.state.fileNameUploaded} ></SendEmail>
                </div>
            </div>
        );
    }

    componentDidMount() {

          axios.get('/api/v1/BigSizeFileNote').then((res) => {
            this.setState({
                bigSizeFileNote:res.data.body,
            });
          }).catch(err => 
            {
              console.log("err:" + JSON.stringify(err))
            });

            axios.get('/api/v1/FileSizeLimit').then((res) => {
                this.setState({
                    fileSizeLimit:res.data.body,
                });
              }).catch(err => 
                {
                  console.log("err:" + JSON.stringify(err))
                });
      }

    // componentDidUpdate(previousProps: IFileUploadReactProps, previousState: IFileUploadReactState) {

    //       if (this.state.uploaded == true) {
    //         //this.props.refreshUploadedHandler();
    //       }

    // }
    private emailHandle(): void {
        this.setState
            ({
                fileNameUploaded: "",
                callSendEmail: false,
                //isBusy:false
            })
    }

    private filesave() {
        if ((document.querySelector("#newfile") as HTMLInputElement).files ?.length !== 1)
        {
            alert("please select only one file to upload!");
            return;
        }
        if (this.state.isBusy === false) {
           this.setState({
               isBusy: true,
           })
        }
        let myfile = (document.querySelector("#newfile") as HTMLInputElement).files![0];
        let myForm = document.getElementById('myForm') as HTMLFormElement;
        let formData = new FormData(myForm);

        this.setState({
            showProgress: true,
        })
        var fileType = myfile.name.substring(myfile.name.lastIndexOf('.') + 1);
        if (myfile.size > this.state.fileSizeLimit)
        {
            alert("File size limit to : " + (this.state.fileSizeLimit/(1024*1024*1024)).toString() + " GB" );
            this.setState({
                isBusy: false,
            })
            return false;
        }
        //var fileType = myfile.name.split('.')[1];// this one got bug
        //  var formData = new FormData();
        //  formData.append("file", myfile);

        // axios.post(`/api/v1/fileUpload?filename=${myfile.name}&projectno=${this.props.projectNo}&libraryname=NonProject%20Documents`,formData,{
        //    headers: {
        //   'Content-Type': 'multipart/form-data',
        //'filename':`${myfile.name}`,
        //'projectNo':`${this.props.projectNo}`,
        //'libraryName':`NonProject%20Documents`

        const config = {
            onUploadProgress: (progressEvent: { loaded: number; total: number; }) => {
                let { progressPercent } = this.state;

                progressPercent = (progressEvent.loaded / progressEvent.total); //* 100;
                this.setState({
                    progressPercent,
                    progressDescription: `${Math.round(progressPercent*100)} %`
                });
            }
        }
        axios.post(`/api/v1/fileUpload?filename=${encodeURIComponent(myfile.name)}&projectno=${this.props.projectNo}&libraryname=NonProject%20Documents&fileType=${fileType}&userId=${this.props.userInfo.key}`,formData, config).then((res) => {
            res.status.toString() === '200' ? alert(`${myfile.name} upload succeed.`) : alert('Upload failed.');
            this.setState(
                {
                    fileNameUploaded: myfile.name,
                    callSendEmail: true,
                    uploaded: true,
                    isBusy: false,
                    progressLabel: res.data.fileId == 0 ? `Note: Big size is dectected`:`File upload progress`,
                    progressDescription:`File upload complete, start email sending...`,
                    isBigSizeDetected:  res.data.fileId == 0 ? true:false,
                })
        }).catch((error) => {
            if (error.response) { // if there is response, it means its not a 50x, but 4xx
                alert(`Upload failed: ${JSON.stringify(error.response)}`);
            } else {   // gets activated on 50x errors, since no response from server
                // do whatever you want here :)
                alert(`Upload failed (500?): ${JSON.stringify(error)}`);
            }
        });
    }

}  