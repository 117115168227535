import * as React from 'react';
//import { DropdownMenuItemType, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Dropdown, IDropdownStyles } from 'office-ui-fabric-react/lib/Dropdown';
import {ITransmittalItem} from './ITransmittalItem'
import {INonCtrTransmittalItem} from '../Component/NonControlTransmittal/INonCtrTransmittalItem'
import "@pnp/sp/webs";
import "@pnp/sp/lists";
import "@pnp/sp/items";

import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { ICSSPixelUnitRule } from '@uifabric/merge-styles/lib/IRawStyleBase';
import axios from 'axios';

const dropdownStyles: Partial<IDropdownStyles> = {   
    dropdown: { width: '100%',height: '100%',fontSize:10, },
  };

  // const dropDownMergeStytles = mergeStyles({
  //   fontSize: 12,
  //   height: 14,
  //   width: 'auto',
  // });


export interface IDocStatusDropdownProps
{  
    RefItem?: ITransmittalItem;
    RefItemNonCtr?:INonCtrTransmittalItem;
    Disabled?:boolean;
}

export interface IDocStatusDropdownState
{  
    IdSelected: number; 
}

export default class DocStatusDropdown extends React.Component<IDocStatusDropdownProps, IDocStatusDropdownState> {


    constructor(props: IDocStatusDropdownProps | Readonly<IDocStatusDropdownProps>) {  
        super(props);  
        this.state={
          IdSelected : -1,
        };
        //this.nonCtrAddHandler = this.nonCtrAddHandler.bind(this);  
        this.keyChanged = this.keyChanged.bind(this);
      } 


  public render(): React.ReactElement<IDocStatusDropdownProps> {
    return (
        <Dropdown placeholder="Select an status" disabled={this.props.Disabled} styles={dropdownStyles} onChanged={this.keyChanged} 
        defaultSelectedKey={this.props.RefItem === undefined ? this.props.RefItemNonCtr!.CurrentStatusId : this.props.RefItem!.CurrentStatusId}
        //disabled={this.props.RefItem === undefined ? (this.props.RefItemNonCtr!.ReturnDate === undefined ? false : true ): (this.props.RefItem.ReturnDate === undefined ? false : true ) } 
        options={this.props.RefItem === undefined ?  this.props.RefItemNonCtr!.DocStatusOptions!: this.props.RefItem!.DocStatusOptions!}/>
    );
  }

  private keyChanged(option: { key: ICSSPixelUnitRule; }):void{
    // this.setState(
    // {
    //     IdSelected:option.key,
    // });
    // alert('Id changed');

    var idOption: number = +option.key;

    this.setState(
      { 
          IdSelected: idOption, 
      });
      this.props.RefItem!.CurrentStatusId = idOption;
      console.log(this.props.RefItem!.CurrentStatusId);
    //this._updateDocSatusOfTransmittalItem(idOption).then(()=>{console.log('updated')});
  }


  private async _updateDocSatusOfTransmittalItem(docStatusId:number): Promise<any>
  {
     let request = {
      projectNo:this.props.RefItem === undefined ? this.props.RefItemNonCtr!.projectNo : this.props.RefItem!.projectNo,
      itemId:this.props.RefItem === undefined ? this.props.RefItemNonCtr!.Id : this.props.RefItem!.Id,
      statusId:docStatusId,
     };
     this.props.RefItem === undefined ? 
     axios.post('/api/v1/nonControlDocTransmittalItems/updateStatus',request).then((res) => {
      //alert(JSON.stringify(res.data.body));
      //alert('Item status updated!');
      //this.props.RefItem!.CurrentStatusId = docStatusId;
     }) :
     axios.post('/api/v1/transmittalItems/updateStatus',request).then((res) => {
      //alert(JSON.stringify(res.data.body));
      //alert('Status updated!');
      this.props.RefItem!.CurrentStatusId = docStatusId;
     }) 

    // let web = Web(this.props.RefItem.siteUrl + "/" + this.props.RefItem.projectNo);//"20191002EDMS116");
    // //const randomFileType = _randomFileIcon();
    // const updatedItem = await web.lists.getByTitle("Transmittal Items").items.getById(this.props.RefItem.Id).update({
    //     Document_x0020_StatusId:docStatusId,
    // }); 
  }
}