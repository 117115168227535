import * as React from 'react';
import { Announced } from 'office-ui-fabric-react/lib/Announced';
import { DetailsList, DetailsListLayoutMode, Selection, SelectionMode, IColumn } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { mergeStyles, mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';

import "@pnp/sp/files";
import "@pnp/sp/folders";

import { SearchBox, ISearchBoxStyles } from 'office-ui-fabric-react/lib/SearchBox';
import axios from 'axios';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { IPdmsUser } from '../IPdmsUser';
import { DateHelper } from '../DateHelper';
import { CommandBar, Dropdown, ICommandBarItemProps, IDropdownOption, IDropdownStyles, Stack } from 'office-ui-fabric-react';
import CommentsCountingMDLAssignment from './CommentsCountingMDLAssignment';
import { IAssignment } from './IAssignmentItem';
import { ICommentMDLAssignment } from './ICommentMDLAssignment';



const controlWrapperClass = mergeStyles({
  display: 'flex',
  flexWrap: 'wrap',
});

const searchWrapperClass = mergeStyles({
  float:'right',
  margin: '10px',
  display:'block',
});

const tabTableWrapper = mergeStyles({
  width:'100%',
  overflowY:'scroll',
  overflowX:'scroll',
});

const detailsListWrapper = mergeStyles({
  width: '100% !important',
  margin:'0px',
  overflowY:'auto',
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
});

const spinnerStyles= {
  circle: {
    height: 25,
    width: 25,
    borderWidth: 4
  }
}

const dropdownStyles: Partial<IDropdownStyles> = {
  dropdown: { width: 300 },
};

const _items: ICommandBarItemProps[] = [
    {
      key: 'download',
      text: 'Download',
      iconProps: { iconName: 'Download' },
      onClick: () => {
        let element: HTMLElement = document.getElementById("btnDownloadAssignments") as HTMLElement;
        element.click();
      },
    },
  ];

const _overflowItems: ICommandBarItemProps[] = [];
const _farItems: ICommandBarItemProps[] = [];
const searchBoxStyles: Partial<ISearchBoxStyles> = { 
  root: { width: 200,float:'right' } 
};

const url = require('url');
const querystring = require('querystring');
 let rawUrl = window.location.href;
 let parsedUrl = url.parse(rawUrl);
 let parsedQs = querystring.parse(parsedUrl.query);

// console.log(parsedQs.distributionNo);
//DistributionNo={4}&pageId=project-list&websiteId=2&tabId=Distributiondocs
// let distributionNo = parsedQs.distributionNo;


export interface IDetailsListMDLAssignmentState {
  status: string;
  columns: IColumn[];
  items: IAssignment[];
  selectionDetails: string;
  isModalSelection: boolean;
  isCompactMode: boolean;
  announcedMessage?: string;
  jsonResponse?:any; //json object to hold response from pnp js methods 
  downloading:boolean;
  targetListRefresh:boolean; 
  isBusy?:boolean;
  distributionNo?:string;
  //distributions?: IDistribution[];
  assignments?:IAssignment[];
  search?:string;
}

export interface IDetailsListMDLAssignmentProps {
  currentSite:string,
  currentProject:string,
  downloaded:boolean,
  handlerDownload:any,
  currentUserInProject?:IPdmsUser;
}

// export interface IAssignment {
//   key: string;
//   name: string;
//   value: string;
//   iconName: string;
//   fileType: string;
//   modifiedBy: string;
//   dateModified: string;
//   dateModifiedValue: number;
//   documentType: string;
//   //IsControlDoc:boolean;
//   ServerRelativeUrl:string;
// }


const hideStyle = {display : 'none'};
var mdlAssignmentsBeforeSearch: any[] = [];
export class DetailsListMDLAssignment extends React.Component<IDetailsListMDLAssignmentProps, IDetailsListMDLAssignmentState> {
  // private _selection: Selection;
  private _allItems: IAssignment[];
  private _selection: any;

  componentDidMount() {
    if (this.props.currentUserInProject !== undefined)
    {
      //this.loadDataWithCallback();
      //this.loadAssignments();
      //this.loadAssignments();
      this._buildDetailsListItems();
    } 
  }

  private keyChanged(option: { key: any; }):void{
    // distributionNo level for current user should be init at here:
    this.setState(
    {
      status:"current distribution changed ...",
      distributionNo: option.key,
      isBusy:true,
    });              
  }
  
  constructor(props: IDetailsListMDLAssignmentProps) {
    super(props);
    this._allItems = [];
    const columns: IColumn[] = [
      // {
      //   key: 'column0',
      //   name: 'ServerRelativeUrl',
      //   iconName: 'Page',
      //   isIconOnly: true,
      //   fieldName: 'name',
      //   minWidth: 16,
      //   maxWidth: 16,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item1: IDocument) => {
      //     return <span>{item1.ServerRelativeUrl}</span>;
      //     //return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
      //   },
      // },
      // {
      //   key: 'column1',
      //   name: 'File Type',
      //   className: classNames.fileIconCell,
      //   iconClassName: classNames.fileIconHeaderIcon,
      //   ariaLabel: 'Column operations for File type, Press to sort on File type',
      //   iconName: 'Page',
      //   isIconOnly: true,
      //   fieldName: 'name',
      //   minWidth: 16,
      //   maxWidth: 16,
      //   onColumnClick: this._onColumnClick,
      //   onRender: (item: IAssignment) => {
      //     return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
      //   },
      // },
      {
        key: 'column1',
        name: 'Document No',
        fieldName: 'documentNo',
        minWidth: 190,
        maxWidth: 320,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column2',
        name: 'Document Title',
        fieldName: 'DocumentTitle',
        minWidth: 190,
        maxWidth: 320,
        isRowHeader: true,
        isResizable: true,
        isSorted: true,
        isSortedDescending: false,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        onColumnClick: this._onColumnClick,
        data: 'string',
        isPadded: true,
      },
      {
        key: 'column3',
        name: 'Revision No',
        fieldName: 'DocRevisionNo',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        onColumnClick: this._onColumnClick,
        data: 'number',
        isPadded: true,
      },
      {
        key: 'column4',
        name: 'Planned Submission Date',
        fieldName: 'RequestReturnDate',
        minWidth: 100,
        maxWidth: 120,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        isPadded: true,
      },
      {
        key: 'column5',
        name: 'Actual Submission Date',
        fieldName: 'ReturnDate',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        isPadded: true,
      },
      {
        key: 'column6',
        name: 'Remarks',
        fieldName: 'Remarks',
        minWidth: 70,
        maxWidth: 90,
        isResizable: true,
        isCollapsible: true,
        data: 'string',
        onColumnClick: this._onColumnClick,
        isPadded: true,
      },
      { key:'comment', name:'Comments',fieldName:'comment',minWidth:100,maxWidth:120,isResizable:true,onColumnClick: this._onColumnClick,
      onRender: (item: IAssignment) => {
        //return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
        return <CommentsCountingMDLAssignment refItem={item!} count={item.comment! as number} ></CommentsCountingMDLAssignment>
      },
      isPadded: true,},
    ];

    this._selection = new Selection({
      onSelectionChanged: () => {
        //let element: HTMLElement = document.getElementById("btnDownloadAssignments") as HTMLElement;
        if (this._selection.getSelection().length > 0)
        {
          //element.textContent= (this._selection.getSelection()[0] as IAssignment).Id.toString();
          this.setState({
            selectionDetails: this._getSelectionDetails(),
          });
        }
      },
    });
    
    this.state = {
      status: 'Ready',
      items: this._allItems,
      columns: columns,
      selectionDetails: this._getSelectionDetails(),
      isModalSelection: false,
      isCompactMode: false,
      announcedMessage: undefined,
      targetListRefresh:false,
      downloading:false,
      isBusy:false,
      assignments:[],
      //distributionNo: distributionNo,
    };
    this.keyChanged = this.keyChanged.bind(this);
  }

  // private renderOptions () : IDropdownOption[] {
    
  //   var results : IDropdownOption[] = [];
  //   // if (this.state.currentProject == null)
  //   // {
  //   //   return results;
  //   // }
  //   if(this.state.distributionNo?.length !== 0){
  //     for(let i=0; i<this.state.distributions!.length; i++){
  //       let result:IDropdownOption = {
  //         key:this.state.distributions![i].distributionNo!,
  //         text:this.state.distributions![i].distributionNo!
  //       };
  //       results.push(result);
  //     }
  //   }
  //   return results;
  // }

  public render() {
    const { columns, isCompactMode, items, selectionDetails, isModalSelection, announcedMessage } = this.state;
    return (
      <div className={tabTableWrapper}>
        <table className={detailsListWrapper}>
          <tbody>
            <tr>
              <td>
                {/* <div>
                  <CommandBar
                    items={_items}
                    overflowItems={_overflowItems}
                    //overflowButtonProps={overflowProps}
                    farItems={_farItems}
                    ariaLabel="Use left and right arrow keys to navigate between commands"
                  />
                </div> */}
              </td>          
            <td>
              <div className={searchWrapperClass}>
                <SearchBox
                  styles={searchBoxStyles}
                  placeholder="Search"
                  onEscape={ev => { console.log('Custom onEscape Called'); }}
                  onClear={ev => { this.setState({items:mdlAssignmentsBeforeSearch}); }}
                  onSearch={_sKey => this._searchApply(_sKey)}
                /> 
              </div>
            </td>
          </tr>
          {/* <tr>
            <td>
            <Stack>
              <Dropdown defaultSelectedKey={this.state.distributionNo}
                placeholder={this.state.isBusy ? "Please wait ..." : "Please choose your Distribution No"}
                options={this.renderOptions()}
                onChanged={this.keyChanged} 
                styles={dropdownStyles}
              />
            { this.state.isBusy ? <Spinner size={SpinnerSize.small} /> : null }
            </Stack>
            </td>
          </tr> */}
          <tr>
            {/* <td><Announced message={`Number of items after filter applied: ${items.length} in ${this.props.currentProject}.`} /></td> */}
          </tr>
          {/* </div> */}
          <tr>
            {/* <td colSpan={2}>
              <div className={classNames.selectionDetails}>{selectionDetails}
                <Announced message={selectionDetails} />
                {announcedMessage ? <Announced message={announcedMessage} /> : undefined}
              </div>
            </td> */}
          </tr>
          <tr>
            <td colSpan={2}>  { (this.state.isBusy) ? <Spinner styles={spinnerStyles}/> : null } </td>
          </tr>
          <tr>
            <td colSpan={2}>
              {isModalSelection ? (
              <MarqueeSelection selection={this._selection}>
            <DetailsList
              items={items}
              compact={isCompactMode}
              columns={columns}
              selectionMode={SelectionMode.multiple}
              getKey={this._getKey}
              setKey="multiple"
              layoutMode={DetailsListLayoutMode.justified}
              isHeaderVisible={true}
              selection={this._selection}
              selectionPreservedOnEmptyClick={true}
              onItemInvoked={this._onItemInvoked}
              //onRenderItemColumn={this._onRenderColumn}
              enterModalSelectionOnTouch={true}
              ariaLabelForSelectionColumn="Toggle selection"
              ariaLabelForSelectAllCheckbox="Toggle selection for all items"
              checkButtonAriaLabel="Row checkbox"
            />
          </MarqueeSelection>) : (
          <DetailsList
            items={items}
            compact={isCompactMode}
            columns={columns}
            selectionMode={SelectionMode.none}
            getKey={this._getKey}
            setKey="none"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
            onItemInvoked={this._onItemInvoked}
          />
        )}
        </td>
        </tr>
        {/* </Fabric> */}
        </tbody>
        </table>
      </div>
    );
  }

  // private loadAssignments(){
  //   if (this.props.currentProject === null || this.props.currentProject === undefined || this.props.currentUserInProject === undefined)
  //     return;
  //   axios.get(`/api/v1/distributions/numbers?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject.key}`).then((res) => {
  //     const response = res.data;
  //     let _assignments = response.body.map((xx:{Title: string})=>{
  //     let assignment : IAssignment = {
  //       Id:0,
  //       ServerRelativeUrl:"",
  //       documentNo:xx.Title
  //     };
  //     return assignment;
  //   });
  //   this.setState({ 
  //     assignments:_assignments,
  //     isBusy:false,
  //   });
  //   }).catch(err => {
  //     console.log("err:" + JSON.stringify(err))
  //   }); 
  // }

  private async _buildDetailsListItems()
  { 
    if (this.state.isBusy === false)
    {
      this.setState({
        isBusy:true,
      });
    }

    //add this one and remove promise1 and promise3  to optimize the performance
    const promiseItems = this._getAssignItemsForUser();
    const PromiseComments= this._getItemComments();

   let values = await Promise.all([promiseItems, PromiseComments]);
   let responseItems = values[0];
   let responseComments = values[1];

  //  let _allmdlComments : ICommentMDLAssignment[]  = [];
   let _allTItems : IAssignment[] = [];

    responseItems.map((item: { Id: any; Title: any; MDLId: { Id:any, DocumentNo: any; }; Revision_x0020_No: any; Remarks:any;ScheduleReturnDate:any;ActualReturnDate:any; }) =>{
      let commentCounting : number = responseComments.filter((obj: { Item_x0020_Bind_x0020_Id: any; }) => {return obj.Item_x0020_Bind_x0020_Id === item.Id}).length;
          _allTItems.push({
              Id:item.Id,
              DocumentTitle:item.Title,
              documentNo:item.MDLId.DocumentNo,
              DocRevisionNo:item.Revision_x0020_No,
              Remarks:item.Remarks === null ? "" : item.Remarks,
              comment:commentCounting,
              RequestReturnDate:item.ScheduleReturnDate === null ? "" : DateHelper.FormatDateToShortDate(item.ScheduleReturnDate),
              ReturnDate:item.ActualReturnDate === null ? "" : DateHelper.FormatDateToLongDate(item.ActualReturnDate),
              mdlId : item.MDLId.Id,
              ServerRelativeUrl:"",
              projectNo:this.props.currentProject,
          });
    });


  //  if (this.state.search !== null && this.state.search !== undefined)
  //  {
  //    let keystring = this.state.search.toLowerCase();
  //    _allTItems = _allTItems.filter((oitem:any) =>
  //      {
  //        return oitem.ItemNo.toLowerCase().includes(keystring) 
  //      || oitem.TransmittalNo.toLowerCase().includes(keystring) 
  //      || oitem.RequestForDescription.toLowerCase().includes(keystring) 
  //      || oitem.DocumentTitle.toLowerCase().includes(keystring) 
  //      || (oitem.DocumentNo !== null && oitem.DocumentNo.toLowerCase().includes(keystring) )
  //      || (oitem.DocRevisionNo  !== null && oitem.DocRevisionNo.toLowerCase().includes(keystring) )
  //    })
  //  };



   this.setState({
     items:_allTItems,
     isBusy:false,
   });

  }

  private async _getAssignItemsForUser(): Promise<any>
  {
    let result = axios.get(`/api/v1/mdls/userMDLAssignment?projectNo=${this.props.currentProject}&userId=${this.props.currentUserInProject!.key}`);     
    return (await result).data.body;
  }

  private async _getItemComments(): Promise<any>{
    let result = await axios.get(`/api/v1/mdls/allComments?projectNo=${this.props.currentProject}`);
    return result.data.body;
  }


  public componentDidUpdate(previousProps: IDetailsListMDLAssignmentProps, previousState: IDetailsListMDLAssignmentState) {
    // if (this.props.currentUserInProject !== undefined && previousState.distributionNo !== this.state.distributionNo) {
    //   this.loadDataWithCallback();
    // }
    if (this.props.currentUserInProject !== undefined)
    {
    if (previousState.isModalSelection !== this.state.isModalSelection && !this.state.isModalSelection) {
      this._selection.setAllSelected(false);
    }
    //refresh when change project
    if (previousProps.currentProject !== this.props.currentProject) {
        //alert(previousProps.currentProject);
        //this.loadDataWithCallback();
        //this.loadAssignments();
        this._buildDetailsListItems();
    }
    if (previousProps.currentUserInProject !== this.props.currentUserInProject) {
        //alert(previousProps.currentProject);
        this._buildDetailsListItems();
    }
  }               
}



  private _getKey(item: any, index?: number): string {
    return item.key;
  }

  private _searchApply(keystring:string) : void {
    //let oItems = this.state.items;
    this.setState({
      items:keystring ? this.state.items.filter(i => i.DocumentTitle!.toLowerCase().indexOf(keystring.toLowerCase()) > -1 
      || i.documentNo.toLowerCase().indexOf(keystring.toLowerCase()) > -1) : this.state.items,
    });
  }

  private _onItemInvoked(item: any): void {
    alert(`Item invoked: ${item.name}`);
  }

//   private _onRenderColumn(item?: IAssignment, index?: number, column?: IColumn) {
    
//     const value =
//     item && column && column.fieldName ? item[column.fieldName as keyof IAssignment] || '' : '';
//     if (column!.fieldName === "comment")
//     {
//       return <CommentsCountingMDLAssignment refItem={item!} count={value as number} ></CommentsCountingMDLAssignment>
//     }
// else if (column!.fieldName === "RequestReturnDate")
//     {
//       if (value !== null && value !== '')
//       {
//       let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
//       return <div data-is-focusable={true}>{formatedDateValue}</div>
//       }
//     }
//     else if (column!.fieldName === "ReturnDate")
//     {
//       if (value !== null && value !== '')
//       {
//       let formatedDateValue = DateHelper.FormatDateToShortDate(value as string);
//       //let dateValue1 = DateHelper.Deserialize(value.toString());
//       return <div data-is-focusable={true}>{formatedDateValue}</div>
//       }
//     }
//     else
//     {
//        return <div data-is-focusable={true}>{value}</div>; 
//     }
//   }

  private _getSelectionDetails(): string {
    const selectionCount = this._selection.getSelectedCount();
    switch (selectionCount) {
      case 0:
        return 'No items selected';
      case 1:
        return '1 item selected: ' + (this._selection.getSelection()[0] as IAssignment).documentNo;
      default:
        return `${selectionCount} items selected`;
    }
  }

  private _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
    const { columns, items } = this.state;
    const newColumns: IColumn[] = columns.slice();
    const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol: IColumn) => {
      if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        this.setState({
          announcedMessage: `${currColumn.name} is sorted ${
            currColumn.isSortedDescending ? 'descending' : 'ascending'
          }`,
        });
      } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
      }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName!, currColumn.isSortedDescending);
    this.setState({
      columns: newColumns,
      items: newItems,
    });
  }
}

function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
  const key = columnKey as keyof T;
  return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}