
import './App.css';
import CrudReact from './Component/CrudReact';
import React from 'react';
import axios from 'axios';
import Login2 from './Component/Login2'

export interface IAppProps
{

}

export interface IAppState
{
  response?:string,
  otherthingResponse?:string,
  token?:string,
  siteUrl?:string,
  loginEmail?:string,
}

const hideStyle = {display : 'none'};

export default class App extends React.Component<IAppProps, IAppState,{} > {


  constructor(props: IAppProps, state: IAppState) {  
    super(props);  
    this.state = {
      response: 'Ready',
      otherthingResponse:'',
    };
    this.loginOk = this.loginOk.bind(this);
    this.distroyToken = this.distroyToken.bind(this);
  } 
  
  componentDidMount() {
    if (this.state.token != null)
    {
      axios.get('/api/v1/siteUrl').then((res) => {
        console.log(`siteurl : ${res}`);
        this.setState({
          siteUrl:res.data.body,
        });
      }).catch(err => 
        {
          console.log("err:" + JSON.stringify(err))
        });

    }
  }
 

  render() {
    if (!this.state.token)
    {
      //return <Login triggerParent={this.loginOk} ></Login>
      return <Login2 triggerParent = {this.loginOk}></Login2>
    }
    return (
      <div className="App">
        <button style={hideStyle} id='btnDistroyToken' onClick={()=> this.distroyToken()} ></button>
        {/* <h1>{this.state.response}</h1>
        <h1>{this.state.otherthingResponse}</h1>     */}
        <CrudReact siteUrl={this.state.siteUrl!} token = {this.state.token} loginEmail = {this.state.loginEmail!}></CrudReact>
        <footer className="slogan-footer"></footer>
      </div>
    );
  }

  private distroyToken()
  {
    //alert('Distory Token here');
    this.setState({
      token:'',
    })
  }

  private loginOk(passToken?:string,passEmail?:string,passSiteUrl?:string)
  {
    // console.log('loginOk');
    // console.log(passToken);
    this.setState({
      token: passToken,
      loginEmail:passEmail,
      siteUrl:passSiteUrl,
    });
  }
}
